import React from 'react'
import guide from './userguide.pdf'
import Box from '@mui/material/Box';

const UserManual = () => {
    return (
        <Box sx={{ height: '80vh', padding: '25px' }}>
            <object
                data={guide}
                type="application/pdf"
                width="100%"
                height="100%"
            >

                <iframe
                    src={guide}
                    width="100%"
                    height="100%"
                >
                    <p>
                        Your browser does not support PDFs.
                        <a href="https://example.com/test.pdf">Download the PDF</a>
                        .
                    </p >
                </iframe >
            </object>
        </Box >
    )
}
export default UserManual