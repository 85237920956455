import moment from 'moment';
export function ageCalculator( dateOfBirth:any) {
    const dobFormat = 'YYYY-MM-DD';
    const startDate = moment();
    const currentDate : any = moment(startDate, dobFormat).format('YYYY');
    const bornYear : any = moment(dateOfBirth, dobFormat).format('YYYY');
    const policyStartDateMonth: any = moment(startDate, dobFormat).format('MM');
    const bornMonth :any = moment(dateOfBirth, dobFormat).format('MM');
    const policyStartDateDay: any = moment(startDate, dobFormat).format('DD');
    const bornDay : any = moment(dateOfBirth, dobFormat).format('DD');
  
    var monthDayPoint = 0;
    if (policyStartDateMonth == bornMonth) {
      if (policyStartDateDay >= bornDay) monthDayPoint = 0;
      else monthDayPoint = 1;
    } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;
  
    const anb : any = currentDate - bornYear - monthDayPoint + 1;
    return anb;
  }
  