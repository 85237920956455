const CLIENT_ID = process.env.CLIENT_ID || '{clientId}';
const ISSUER = process.env.ISSUER || 'https://zurich-uat.oktapreview.com/oauth2/aus1f5ivrpuTgUINd0h8';
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = `${window.location.origin}/oAuth/callback`;

// eslint-disable-next-line
export default {
  oidc: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_AUTHORIZATION_SERVER}`,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `${window.location.origin}/login`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    features: {
      smsRecovery: true,
      smsVerification: true,
      multiOptionalFactorEnroll: true
    }
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};
