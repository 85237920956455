import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import axios from 'axios'
import LoopIcon from '@mui/icons-material/Loop';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import { ErrorMessage } from "@hookform/error-message";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import dayjs, { Dayjs } from 'dayjs';
import FormHelperText from '@mui/material/FormHelperText';
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
type FormValues = {
  sessionId: string,
  customerId: string,
  title: string,
  firstName: string,
  lastName: string,
  dob: any,
  gender: string,
  isSmoker: boolean,
  maritalStatus: boolean,
  email: string,
  nationality: string,
  residentialCountry: string,
  familyInUae: boolean,
  noOfEarningMembers: number | undefined,
  contactDetails: {
    id?: string,
    countryCode: string,
    phoneNo: string
  }[],
  dependents: {
    id?: string,
    firstName: string,
    lastName: string,
    dob: any,
    gender: string,
    nationality: string,
    residentialCountry: string,
    relationId: number | null,
  }[],
  fatcaDeclaration: {
    id?: string,
    isUSTaxPayer: boolean,
    isUSCitizen: boolean,
    isTelephoneUS: boolean
  },
  coApplicant: {
    id?: string,
    title: string,
    firstName: string,
    lastName: string,
    dob: any,
    nationality: string,
    residentialCountry: string,
    gender: string,
    maritalStatus: boolean,
    isSmoker: boolean,
    email: string,
    contactDetails: {
      id: string,
      countryCode: string,
      phoneNo: string
    }[],
    fatcaDeclaration: {
      id?: string,
      isUSTaxPayer: boolean,
      isUSCitizen: boolean,
      isTelephoneUS: boolean
    },
  }
}
export const PersonalDetails = ({ setIsFormSubmited, isClosedSession, isQuoteGenerated, _setPageID, submitRef, _navigateToDashboard }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const countryDialCodeList = useSelector((state: any) => state.genericDetails.countryDialCodeList);
  const [isJointApplicant, setIsJointApplicant] = React.useState(false)
  const [details, setDetails] = React.useState<any>()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [shouldNavigate, setShouldNavigate] = React.useState<boolean>(false)
  const [openConfirmation, setOpenConfirmation] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [consentDetails, setConsentDetails] = React.useState()
  const [CoAppid, setCoAppid] = React.useState<string>()
  const [aboutYouErrors, setAboutYouErrors] = React.useState<boolean>(false)
  const [contactDetailsError, setContactDetailsError] = React.useState<boolean>(false)
  const [fatcaError, setFatcaError] = React.useState<boolean>(false)
  const [dependentError, setDependentError] = React.useState<boolean>(false)
  const [coApplicantError, setCoApplicantError] = React.useState<boolean>(false)
  const [panel1, setPanel1] = React.useState<boolean>(false)
  const [panel2, setPanel2] = React.useState<boolean>(false)
  const [panel3, setPanel3] = React.useState<boolean>(false)
  const [panel4, setPanel4] = React.useState<boolean>(false)
  const [panel5, setPanel5] = React.useState<boolean>(false)
  const [panel6, setPanel6] = React.useState<boolean>(false)
  const [cildCount, setcildCount] = React.useState<number>(0);
  const [dependentArray, setDependentArray] = React.useState([]);


  useEffect(() => {
    if (consentDetails === false || consentDetails === null) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [consentDetails])

  const formatedDate = (value: any) => {
    if (value?.$d != 'Invalid Date') {
      const date = (value === '' || value === undefined) ? new Date() : new Date(value);
      const newDate = date.setFullYear(date.getFullYear() - 30);
      var formatedDate = new Date(newDate)
      return dayjs(formatedDate);
    }
  }
  const formatedDateDob = (value: any) => {
    if (value?.$d != 'Invalid Date') {
      const date = (value === '' || value === undefined) ? new Date() : new Date(value);
      let year = date.toLocaleString("default", { year: "numeric" });
      let month = date.toLocaleString("default", { month: "2-digit" });
      let day = date.toLocaleString("default", { day: "2-digit" });
      let formattedDate = year + "-" + month + "-" + day;
      return dayjs(formattedDate)
    }
  }
  const deleteContactDetails = (index: number, isCoApplicant: boolean) => {
    let recordId;
    let url = baseUrl + '/customers/contact-detail?id='
    if (isCoApplicant === false) {
      let objArray = watch(`contactDetails`);
      recordId = objArray[index]?.id;
    } else {
      let objArray = watch(`coApplicant.contactDetails`);
      recordId = objArray[index]?.id;
      url = baseUrl + '/co-applicant-contact-details?id='
    }
    if (recordId) {
      try {
        axios.delete(url + recordId, requestOptions)
          .then((response) => {
            if (isCoApplicant === false) {
              remove(index)
            } else {
              remove2(index)
            }
          })
      } catch (error) {
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        })
      }
    } else {
      if (isCoApplicant === false) {
        remove(index)
      } else {
        remove2(index)
      }
    }
  }
  const valuetext = (value: number) => {
    switch (value) {
      case 1:
        return 'Not Important to me'
      case 2:
        return 'Somewhat Important'
      case 3:
        return 'Important'
      case 4:
        return 'Very Important'
      case 5:
        return 'I must Achieve this'
      default:
        return ''
    }
  }

  useEffect(() => {
    /* axios.get(baseUrl+'/customers?id='+sessionStorage.getItem('sessiondetail'))
       .then((response) =>  {
           let data1 = response.data
           if(data1?.coApplicants?.length > 0){
             setIsJointApplicant(true)
           }else{
             setIsJointApplicant(false)
           }
          
           setData(data1)
       })
      
      */
  }, [])
  const getDateInYearFormat = (inputDate: string) => {
    let date = new Date(inputDate);
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate
  }
  const countryCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const responseDetails = await response.json();
      setConsentDetails(responseDetails?.clientConsent)
      sessionStorage.setItem('customerdetail', responseDetails?.customerId)
      const data = responseDetails?.personalDetails;
      setCoAppid(responseDetails?.personalDetails?.coApplicant?.id ? responseDetails?.personalDetails?.coApplicant?.id : '')
      setDetails(responseDetails);
      let childDetailsArray = data?.dependents?.filter((element: any) => element?.relationId === 4)
      setcildCount(childDetailsArray?.length);
      (data?.coApplicant !== null) ? setIsJointApplicant(true) : setIsJointApplicant(false)
      setShowSpinner(false)
      let spouceDetails;
      let coApplicantObject;
      if (data?.coApplicant?.id) {
        coApplicantObject = {
          id: data?.coApplicant?.id || '',
          title: data?.coApplicant?.title || '',
          firstName: data?.coApplicant?.firstName || '',
          lastName: data?.coApplicant?.lastName || '',
          dob: data?.coApplicant?.dob ? dayjs(new Date(data?.coApplicant?.dob)) : formatedDate(''),
          nationality: data?.coApplicant?.nationality || '',
          residentialCountry: data?.coApplicant?.residentialCountry || '',
          gender: data?.coApplicant?.gender || '',
          maritalStatus: data?.coApplicant?.maritalStatus,
          isSmoker: data?.coApplicant?.isSmoker,
          email: data?.coApplicant?.email || '',
          contactDetails: data?.coApplicant?.contactDetails || [{
            id: '',
            countryCode: '',
            phoneNo: ''
          }],
          fatcaDeclaration: {
            id: data?.coApplicant?.id || '',
            isUSTaxPayer: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isUSTaxPayer : false,
            isUSCitizen: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isUSCitizen : false,
            isTelephoneUS: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isTelephoneUS : false
          }
        }
      } else if (data?.dependents?.length > 0) {
        let spouceDetailsArray = data?.dependents?.filter((element: any) => element?.relationId === 3)
        let spouceDetails = spouceDetailsArray?.length > 0 ? spouceDetailsArray[0] : {};
        coApplicantObject = {
          id: '',
          title: spouceDetails?.title || '',
          firstName: spouceDetails?.firstName || '',
          lastName: spouceDetails?.lastName || '',
          dob: spouceDetails?.dob ? dayjs(new Date(spouceDetails?.dob)) : formatedDate(''),
          nationality: spouceDetails?.nationality || '',
          residentialCountry: spouceDetails?.residentialCountry || '',
          gender: spouceDetails?.gender || '',
          maritalStatus: spouceDetails?.maritalStatus,
          isSmoker: spouceDetails?.isSmoker,
          email: spouceDetails?.email || '',
          contactDetails: spouceDetails?.contactDetails || [{
            id: '',
            countryCode: '',
            phoneNo: ''
          }],
          fatcaDeclaration: {
            id: spouceDetails?.id || '',
            isUSTaxPayer: spouceDetails.fatcaDeclaration ? spouceDetails?.fatcaDeclaration?.isUSTaxPayer : false,
            isUSCitizen: spouceDetails?.fatcaDeclaration ? spouceDetails?.fatcaDeclaration?.isUSCitizen : false,
            isTelephoneUS: spouceDetails?.fatcaDeclaration ? spouceDetails?.fatcaDeclaration?.isTelephoneUS : false
          }
        }
      } else {
        coApplicantObject = {
          id: data?.coApplicant?.id || '',
          title: data?.coApplicant?.title || '',
          firstName: data?.coApplicant?.firstName || '',
          lastName: data?.coApplicant?.lastName || '',
          dob: data?.coApplicant?.dob ? dayjs(new Date(data?.coApplicant?.dob)) : formatedDate(''),
          nationality: data?.coApplicant?.nationality || '',
          residentialCountry: data?.coApplicant?.residentialCountry || '',
          gender: data?.coApplicant?.gender || '',
          maritalStatus: data?.coApplicant?.maritalStatus,
          isSmoker: data?.coApplicant?.isSmoker,
          email: data?.coApplicant?.email || '',
          contactDetails: data?.coApplicant?.contactDetails || [{
            id: '',
            countryCode: '',
            phoneNo: ''
          }],
          fatcaDeclaration: {
            id: data?.coApplicant?.id || '',
            isUSTaxPayer: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isUSTaxPayer : false,
            isUSCitizen: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isUSCitizen : false,
            isTelephoneUS: data?.coApplicant?.fatcaDeclaration ? data?.coApplicant?.fatcaDeclaration?.isTelephoneUS : false
          }
        }
      }
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        customerId: sessionStorage.getItem('customerdetail') || '',
        title: data?.title || '',
        firstName: data?.firstName || '',
        lastName: data?.lastName || '',
        dob: data?.dob ? dayjs(new Date(data?.dob)) : formatedDate(''),
        gender: data?.gender || '',
        noOfEarningMembers: data?.noOfEarningMembers || undefined,
        isSmoker: data?.isSmoker || false,
        maritalStatus: data?.maritalStatus,
        email: data?.email || '',
        nationality: data?.nationality || '',
        residentialCountry: data?.residentialCountry || '',
        familyInUae: data?.familyInUae || false,
        contactDetails: data?.contactDetails?.length > 0 ? data?.contactDetails : [{
          id: '',
          countryCode: '',
          phoneNo: ''
        }],
        dependents: data?.dependents?.length > 0 && data?.dependents,
        fatcaDeclaration: {
          id: data?.fatcaDeclaration?.id || '',
          isUSTaxPayer: data?.fatcaDeclaration ? data?.fatcaDeclaration?.isUSTaxPayer : false,
          isUSCitizen: data?.fatcaDeclaration ? data?.fatcaDeclaration?.isUSCitizen : false,
          isTelephoneUS: data?.fatcaDeclaration ? data?.fatcaDeclaration?.isTelephoneUS : false,
        },
        coApplicant: coApplicantObject
      }
    },
    mode: 'all'
  });
  const { register, control, handleSubmit, formState: { errors }, watch, getValues, unregister, setValue } = form
  const { fields, append, remove } = useFieldArray({
    name: 'contactDetails',
    control
  })
  const useFieldArrayDetails1 = useFieldArray({
    name: 'dependents',
    control
  })
  const fields1 = useFieldArrayDetails1.fields
  const append1 = useFieldArrayDetails1.append
  const remove1 = useFieldArrayDetails1.remove

  const useFieldArrayDetails2 = useFieldArray({
    name: 'coApplicant.contactDetails',
    control
  })
  const fields2 = useFieldArrayDetails2.fields
  const append2 = useFieldArrayDetails2.append
  const remove2 = useFieldArrayDetails2.remove

  const addJointLifeApplicant = () => {
    let dependent = watch(`dependents`);
    let firstname = (`coApplicant.firstName`)
    if (dependent) {
      let dependentArray = dependent?.filter(element => element?.relationId === 3)
      if (firstname === '') {
        if (dependentArray?.length > 0) {
          setValue(`coApplicant.firstName`, dependentArray[0].firstName);
          setValue(`coApplicant.lastName`, dependentArray[0].lastName);
          setValue(`coApplicant.residentialCountry`, dependentArray[0].residentialCountry);
          setValue(`coApplicant.nationality`, dependentArray[0].nationality);
          setValue(`coApplicant.gender`, dependentArray[0].gender);
          setValue(`coApplicant.dob`, dependentArray[0].dob);
        }
      }
    }
    setIsJointApplicant(true)
  }
  const _onSubmit = (data: FormValues, action: string) => {
    let reponse = { ...data };

    let dependentArray: any = [];
    if (reponse?.dependents?.length > 0) {
      dependentArray = reponse?.dependents?.map((element: any) => {
        element.id === '' && delete element.id
        element.dob = getDateInYearFormat(element?.dob)
        delete element.sessionId
        delete element.customerId
        return element
      });
      setDependentArray(dependentArray)
    }
    let mobileNo = []
    if (reponse?.contactDetails?.length > 0) {
      mobileNo = reponse?.contactDetails?.map((element: any) => {
        element.id === '' && delete element.id
        delete element.customerId
        return element
      })
    }
    let fatca = { ...reponse?.fatcaDeclaration };
    if (reponse?.fatcaDeclaration?.id === '') {
      delete fatca?.id
    }
    let payload = {
      ...reponse,
      dob: getDateInYearFormat(reponse?.dob),
      noOfEarningMembers: reponse?.noOfEarningMembers ? Number(reponse?.noOfEarningMembers) : 0, dependents: dependentArray, contactDetails: mobileNo, fatcaDeclaration: fatca
    }
    if (isJointApplicant) {
      payload?.coApplicant?.id === '' && delete payload?.coApplicant?.id
      payload.coApplicant.dob = getDateInYearFormat(payload?.coApplicant?.dob)
      setCustomersDetails(payload, action)
    } else {
      let { coApplicant, ...otherDetails } = payload
      setCustomersDetails(otherDetails, action)
    }

  }

  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [isCoApplicantError, setIsCoApplicantError] = React.useState<string>('#b8b8b8')
  useEffect(() => {
    let status = details?.progress?.personalDetails?.status
    let apAppstatus = details?.progress?.personalDetails?.coApplicant
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }

    if (apAppstatus === 'COMPLETE' && isJointApplicant === true) {
      setIsCoApplicantError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {

    (errors.hasOwnProperty('title') || errors.hasOwnProperty('firstName') || errors.hasOwnProperty('lastName') || errors.hasOwnProperty('nationality') || errors.hasOwnProperty('residentialCountry') || errors.hasOwnProperty('dob') || errors.hasOwnProperty('gender') || errors.hasOwnProperty('maritalStatus') || errors.hasOwnProperty('isSmoker')) ? setAboutYouErrors(true) : setAboutYouErrors(false);
    (errors.hasOwnProperty('contactDetails') || errors.hasOwnProperty('email')) ? setContactDetailsError(true) : setContactDetailsError(false)
    errors.hasOwnProperty('fatcaDeclaration') ? setFatcaError(true) : setFatcaError(false)
    errors.hasOwnProperty('coApplicant') ? setIsCoApplicantError('#FF7B6F') : setIsCoApplicantError('#b8b8b8')
    errors.hasOwnProperty('dependents') ? setDependentError(true) : setDependentError(false)
    // setIsError('#FF7B6F')
  }
  const setCustomersDetails = async (userDetails: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/personal-details', userDetails, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        if (action === 'next') {
          _setPageID(1)
        }
        else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
      }).catch(error => {
        //_setPageID(2)
        if (error.response.status === 401) {
          navigate('/login')
        } else {
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }
  const _getFlag = (value: any) => {
    if (value === undefined || value === '') {
      return false
    } else {
      return true
    }
  }
  const BooleanTrue = true;
  const BooleanFalse = false;
  const _deleteDependent = (index: number, id?: string) => {
    if (watch(`dependents.${index}.relationId`) == 4) {
      setcildCount(cildCount - 1)
    }

    if (id) {
      axios.delete(baseUrl + '/customers/dependent?id=' + id, requestOptions)
        .then((response) => {
          remove1(index)

        }).catch(error => {
          //_setPageID(2)
          if (error.response.status === 401) {
            navigate('/login')
          } else {
            toast.error('Something went wrong please try later!', {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        })

      // if(watch(`dependents.${ index }.relationId`) == 3){
      //   setValue(`coApplicant.firstName`, '');
      //   setValue(`coApplicant.lastName`, '');
      //   setValue(`coApplicant.residentialCountry`, '');
      //   setValue(`coApplicant.nationality`, '');
      //   setValue(`coApplicant.gender`, '');
      //   setValue(`coApplicant.dob`, '');
      // }

    } else {
      if (watch(`dependents.${index}.relationId`) == 4) {
        setcildCount(cildCount - 1)
      }


      remove1(index)

    }
  }
  const _deleteCoApplicant = () => {
    //let {id} = e?.target
    if (CoAppid) {
      axios.delete(baseUrl + '/customers/co-applicant?id=' + CoAppid, requestOptions)
        .then((response) => {
          setIsJointApplicant(false)
          let obj = {
            id: '',
            title: '',
            firstName: '',
            lastName: '',
            dob: formatedDate(''),
            nationality: '',
            residentialCountry: '',
            gender: '',
            maritalStatus: false,
            isSmoker: false,
            email: '',
            contactDetails: [{
              id: '',
              countryCode: '',
              phoneNo: ''
            }],
            fatcaDeclaration: {
              id: '',
              isUSTaxPayer: false,
              isUSCitizen: false,
              isTelephoneUS: false
            }
          }
          setValue('coApplicant', obj)
          toast.success('Joint life applicant details deleted successfully !', {
            position: toast.POSITION.BOTTOM_CENTER
          })
        }).catch(error => {
          //_setPageID(2)
          if (error.response.status === 401) {
            navigate('/login')
          } else {
            toast.error('Something went wrong please try later!', {
              position: toast.POSITION.BOTTOM_CENTER
            });
          }
        })
    } else {
      setIsJointApplicant(false)
    }
  }


  const handleClosePopUp = (flag: boolean) => {
    setConsent({
      'sessionId': sessionStorage.getItem('sessiondetail'),
      'clientConsent': flag
    })
    setOpen(false);
    if (flag) {
      setShowSpinner(true)

    } else {
      setOpen(false)
      handleConfirmationClosePopUp(true)
    }

  };

  const handleConfirmationClosePopUp = (flag: boolean) => {
    if (!flag) {
      navigate('/dashboard', { replace: true })
    }
    setOpenConfirmation(flag)
  }
  const setConsent = async (details: {}) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
    };
    const requestOptions = {
      method: 'POST',
      headers: headers,
    };
    const response = await axios.post(baseUrl + '/customers/consent', details, requestOptions)
      .then((response) => {
        setShowSpinner(false);
        setConsentDetails(response?.data?.clientConsent)
      }).catch(error => {
        //_setPageID(2)
        if (error.response.status === 401) {
          navigate('/login')
        } else {
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response;
  }
  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Grid container>
        <Grid item xs={6} sx={{ marginTop: '15px', textAlign: 'left' }}>  <h3 style={{ color: '#012169', textAlign: 'left', marginBottom: '40px' }}>For {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3></Grid>
        <Grid item xs={6} sx={{ marginTop: '15px', textAlign: 'right' }}>
          <Button disabled={isClosedSession === true || isQuoteGenerated} variant="outlined" onClick={() => addJointLifeApplicant()} startIcon={<AddCircleOutlineOutlinedIcon />}>
            {t("PersonalDetailsAddJointLifeApplicant")}
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          <Grid item xs={12}>
            {/* accordion 1 */}
            <Grid item xs={12} sx={{ marginTop: '0px' }}>
              <Accordion className="accordion" sx={{ border: '2px solid', borderColor: aboutYouErrors ? '#FF7B6F' : isErrorColor }} defaultExpanded={(isClosedSession === true || isQuoteGenerated) ? true : panel1}
                expanded={isClosedSession === true || isQuoteGenerated ? true : panel1}
                onChange={() => setPanel1(!panel1)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ padding: '0 16px' }}>{t("PersonalDetailsAboutYou")}</Typography>
                </AccordionSummary>
                <AccordionDetails className='accordionPadding'>
                  <Grid container className="padding-top">
                    <Grid item xs={6}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="Title-label" className='formLabel'>{t("PersonalDetailsTitle")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`title`, {
                            required: "Please select option"
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              sx={{ textAlign: "left" }}
                              variant="standard"
                              id="title"
                              label="Please select a option"
                              error={!!errors?.title}
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value='Mr.'>Mr.</MenuItem>
                              <MenuItem value='Ms.'>Ms.</MenuItem>
                              <MenuItem value='Mrs.'>Mrs.</MenuItem>
                              <MenuItem value='Dr.'>Dr.</MenuItem>
                              <MenuItem value='Prof.'>Prof.</MenuItem>
                              <MenuItem value='Dame'>Dame</MenuItem>
                              <MenuItem value='Earl'>Earl</MenuItem>
                              <MenuItem value='Er'>Er</MenuItem>
                              <MenuItem value='Lady'>Lady</MenuItem>
                              <MenuItem value='Master'>Master</MenuItem>
                              <MenuItem value='Mdm'>Mdm</MenuItem>
                              <MenuItem value='Miss'>Miss</MenuItem>
                              <MenuItem value='Mr. & Mrs.'>Mr. & Mrs.</MenuItem>
                              <MenuItem value='Reverend'>Reverend</MenuItem>
                              <MenuItem value='Rt Hon'>Rt Hon</MenuItem>
                              <MenuItem value='Sir'>Sir</MenuItem>
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.title?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        className="formElement"
                        defaultValue=""
                        InputLabelProps={{ shrink: _getFlag(details?.personalDetails?.firstName) }}
                        label={t("firstNameText")}
                        type="text"
                        {...register("firstName", {
                          required: "Please enter a value",
                          maxLength: {
                            value: 20,
                            message: "You have exceeded the character limit."
                          }
                        }
                        )}
                        error={!!errors?.firstName}
                        helperText={errors?.firstName?.message}
                      />

                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <TextField
                        variant="standard"
                        className="formElement"
                        InputLabelProps={{ shrink: _getFlag(details?.personalDetails?.lastName) }}
                        label={t("lastNameText")}
                        type="text"
                        id="lastName"
                        {...register(`lastName`, {
                          required: "Please enter a value",
                          maxLength: {
                            value: 20,
                            message: "You have exceeded the character limit."
                          }
                        })}
                        error={!!errors?.lastName}
                        helperText={errors?.lastName?.message}
                      />

                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="demo-simple-select-filled-label">{t("PersonalDetailsNationality")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`nationality` as const, {
                            required: "Please select a option"
                          })}


                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="Nationality-label"
                              label="Nationality"
                              id="Nationality"
                              error={!!errors?.nationality}
                              {...field}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.nationality?.message}</FormHelperText>
                      </FormControl>

                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="demo-simple-select-filled-label">{t("PersonalDetailsCountryOfResident")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`residentialCountry` as const, {
                            required: "Please select a option",
                            validate: {
                              noUAE: (fieldValue) => {
                                return (
                                  (fieldValue === 'AE') || 'Our products are designed to serve residents of the UAE'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="ResidentCountry-label"
                              label={t("Residentcountry")}
                              id="residentCountry"
                              error={!!errors?.residentialCountry}
                              {...field}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.residentialCountry?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <Controller
                          defaultValue={"" as any}
                          control={control}
                          {...register(`dob`, {
                            required: "Please enter a value",
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue?.$d != 'Invalid Date' || 'Please enter a valid date'
                                )
                              },
                              noFutureDate: (fieldValue) => {
                                let psDate = new Date().toDateString();

                                let entererDate = new Date(fieldValue?.$d).toDateString();

                                let diff = Number(Math.floor((Date.parse(psDate) - Date.parse(entererDate)) / 86400000));

                                return (

                                  // (enteredDate < currentDate && diff  <= 27027 && enteredDate > pastDate )  || 'Error: minimum age is 17 and maximum age is 74'



                                  (diff >= 6209 && diff <= 27027) || 'Error: minimum age is 17 and maximum age is 74'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                onChange={(e) => field.onChange(e)}
                                value={formatedDateDob(field.value)}
                                format="DD/MM/YYYY"
                                minDate={formatedDateDob('01/01/' + (new Date().getFullYear() - 74))}
                                maxDate={formatedDateDob('12/31/2005')}
                                label={t("dateOfBirthText")} slotProps={{ textField: { variant: 'standard', inputRef: ref } }} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dob?.message?.toString()}</FormHelperText>
                            </LocalizationProvider>)
                          }} />

                      </FormControl>

                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="demo-simple-select-filled-label">{t("customiseTableGender")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`gender` as const, {
                            required: "Please select a option"
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="Gender-label"
                              label="Gender"
                              error={!!errors?.gender}
                              id="gender"
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value='male'>Male</MenuItem>
                              <MenuItem value='female'>Female</MenuItem>
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.gender?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="MaritialStatus-label">{t("PersonalDetailsMaritalStatus")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={'' as any}
                          {...register(`maritalStatus`, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== undefined || 'Please select a option'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="MaritialStatus-label"
                              label="Maritial Status"
                              error={!!errors?.maritalStatus}
                              id="maritalStatus"
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value={true as any}>Married</MenuItem>
                              <MenuItem value={false as any}>Single</MenuItem>
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.maritalStatus?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="smokerStatus-label">{t("PersonalDetailsSmoker")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={'' as any}
                          {...register(`isSmoker`, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== undefined || 'Please select a option'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="isSmoker-label"
                              label="Are you a smoker or use tobacco products?"
                              error={!!errors?.isSmoker}
                              id="maritalStatus"
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value={true as any}>Smoker</MenuItem>
                              <MenuItem value={false as any}>Non Smoker</MenuItem>
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.isSmoker?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* end accodion 1*/}
            {/* start accordion 2*/}
            <Grid item xs={12} sx={{ marginTop: '25px' }}>
              <Accordion className="accordion" sx={{ border: '2px solid', borderColor: contactDetailsError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : panel2}
                expanded={isClosedSession === true || isQuoteGenerated ? true : panel2}
                onChange={() => setPanel2(!panel2)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordion-summary"
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: '0' }}
                >
                  <Typography sx={{ padding: '0 16px' }}>{t("PersonalDetailsContact")}</Typography>
                </AccordionSummary>
                <AccordionDetails className='accordionPadding'>
                  {
                    fields.map((field, index) => {
                      return <Grid key={field.id} container sx={{ textAlign: 'left', padding: '0 16px 12px 16px' }}>
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement">
                            <Controller
                              control={control}
                              defaultValue={"" as any}
                              {...register(`contactDetails.${index}.countryCode` as const, {
                                required: "Please select a value"
                              })}
                              render={({ field: { ref, onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event: any, item: any) => {
                                    onChange(item ? item?.dialCode : null);
                                  }}
                                  value={
                                    value
                                      ? countryDialCodeList?.find((country: any) => {
                                        return value === country?.dialCode

                                      }) ?? null
                                      : null
                                  }
                                  options={countryDialCodeList}
                                  getOptionLabel={(item: any) => (item.dialCode ? item.dialCode + " " + item.name : "")}

                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      value={params.value}
                                      label="Country Code"
                                      name="countryCode"
                                      margin="normal"
                                      variant="standard"
                                      sx={{ marginTop: '3px' }}
                                      inputRef={ref}
                                      error={!!errors?.contactDetails?.[index]?.countryCode}
                                      helperText={errors?.contactDetails?.[index]?.countryCode?.message}
                                    />
                                  )}
                                  selectOnFocus={false}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            label={t("PersonalDetailsPhoneNumber")}
                            id="phoneNo"
                            {...register(`contactDetails.${index}.phoneNo`, {
                              required: "Mobile Number is required",
                              maxLength: {
                                value: 10,
                                message: "Please enter a valid mobile number."
                              }
                            })}
                            error={!!errors?.contactDetails?.[index]?.phoneNo}
                            helperText={errors?.contactDetails?.[index]?.phoneNo?.message}
                          />
                          {index > 0 &&
                            <IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => deleteContactDetails(index, false)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        </Grid>
                      </Grid>
                    })}
                  <Grid container>
                    {fields?.length < 2 &&
                      <Grid item xs={6} sx={{ marginTop: '15px', padding: '12px', textAlign: 'left' }}>
                        <Button variant="outlined" onClick={() => append({
                          id: '',
                          countryCode: '',
                          phoneNo: ''
                        })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                          {t("PersonalDetailsAddPhoneNmber")}
                        </Button>
                      </Grid>
                    }
                  </Grid>
                  <Grid container>
                    <Grid item xs={6} sx={{ marginTop: '15px', padding: '0 12px', textAlign: 'left' }}>
                      <TextField
                        variant="standard"
                        className="formElement"
                        label={t("PersonalDetailsEmailID")}
                        type="email"
                        id="email"
                        InputLabelProps={{ shrink: _getFlag(details?.personalDetails?.email) }}
                        {...register(`email`, {
                          required: "Please enter valid email address",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })}
                        error={!!errors?.email}
                        helperText={errors?.email?.message}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* end accordion 2*/}
            {/* accordion 3 */}
            <Grid item xs={12} sx={{ marginTop: '25px' }}>
              <Accordion className="accordion" sx={{ border: '2px solid', borderColor: fatcaError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : panel3}
                expanded={isClosedSession === true || isQuoteGenerated ? true : panel3}
                onChange={() => setPanel3(!panel3)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ padding: '0 16px' }}>{t("PersonalDetailsFATCADeclaration")}</Typography>
                </AccordionSummary>
                <AccordionDetails className='accordionPadding'>
                  <Grid container className="padding-top">
                    <Grid item xs={6}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="isUSTaxPayer-label" className='formLabel'>{t("PersonalDetailsUStaxPayer")}</InputLabel>

                        <Controller
                          control={control}
                          defaultValue={'' as any}
                          {...register(`fatcaDeclaration.isUSTaxPayer` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== null || 'Please select a option'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="isUSTaxPayer-label"
                                label="Are you a US* tax payer?"
                                type="string"
                                error={!!errors?.fatcaDeclaration?.isUSTaxPayer}
                                id="isUSTaxPayer"
                                {...field}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.fatcaDeclaration?.isUSTaxPayer?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="isUSCitizen-label" className='formLabel'>{t("PersonalDetailsUSCitizen")}</InputLabel>
                        <Controller
                          defaultValue={'' as any}
                          control={control}
                          {...register(`fatcaDeclaration.isUSCitizen` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== null || 'Please select a option'
                                )
                              }
                            }

                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="isUSCitizen-label"
                                label="Are you a US* citizen ?"
                                error={!!errors?.fatcaDeclaration?.isUSCitizen}
                                type="string"
                                id="isUSCitizen"
                                {...field}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.fatcaDeclaration?.isUSCitizen?.message}</FormHelperText>
                      </FormControl>
                      <ErrorMessage errors={errors} className="error" name="isUSCitizen" as="p" />
                    </Grid>
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="isTelephoneUS-label" className='formLabel'>{t("PersonalDetailsPhoneUSBased")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={'' as any}
                          {...register(`fatcaDeclaration.isTelephoneUS` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== null || 'Please select a option'
                                )
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="isTelephoneUS-label"
                                label="Are you a US* citizen ?"
                                error={!!errors?.fatcaDeclaration?.isTelephoneUS}
                                type="string"
                                id="title"
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.fatcaDeclaration?.isTelephoneUS?.message}</FormHelperText>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} className="marginTop" sx={{ textAlign: 'left', paddingLeft: '15px' }}>
                      <Typography variant="h6" color="textPrimary"
                        style={{ color: '#A89F96', marginBottom: '0.75em', fontSize: '12px' }}>
                        {t("PersonalDetailsDefinationMessage")}
                      </Typography>
                      <Typography variant="h6" color="textPrimary"
                        style={{ color: '#A89F96', marginBottom: '0.75em', fontSize: '12px' }}>
                        {t("PersonalDetailsClause")}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* end accodion 3*/}
            {/* other details accordion */}
            <Grid item xs={12} sx={{ marginTop: '25px' }}>
              <Accordion className="accordion" sx={{ border: '2px solid', borderColor: isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : panel4}
                expanded={isClosedSession === true || isQuoteGenerated ? true : panel4}
                onChange={() => setPanel4(!panel4)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{ padding: '0 16px' }}>Other details</Typography>
                </AccordionSummary>
                <AccordionDetails className='accordionPadding'>
                  <Grid container className="padding-top">
                    <Grid item xs={6}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="familyInUae-label" className='formLabel'>{t("PersonalDetailsFamilyInUAE")}</InputLabel>
                        <Controller
                          defaultValue={'' as any}
                          control={control}
                          {...register(`familyInUae` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue !== null || 'Please select a option'
                                )
                              }
                            }

                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="familyInUAE-label"
                                label="Do you have any family in UAE?"
                                error={!!errors?.familyInUae}
                                type="string"
                                id="isUSCitizen"
                                {...field}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.familyInUae?.message}</FormHelperText>
                      </FormControl>
                      <ErrorMessage errors={errors} className="error" name="familyInUae" as="p" />
                    </Grid>
                    {watch(`familyInUae`) === true &&
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">

                          <TextField
                            variant="standard"
                            className="formElement"
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            label={t("PersonalDetailsEarningMember")}
                            placeholder=""
                            defaultValue=""
                            sx={{ color: "#005EB8" }}
                            id="noOfEarningMembers"
                            {...register("noOfEarningMembers", {
                              validate: {
                                noValue: (fieldValue) => {

                                  return (
                                    (fieldValue !== undefined && fieldValue !== null) || 'Please provide a member'
                                  )

                                }
                              },
                              maxLength: {
                                value: 2,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 99,
                                message: "Please enter valid number of member"
                              },
                              min: {
                                value: 0,
                                message: "Please enter valid number of member"
                              }
                            }
                            )}

                          />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.noOfEarningMembers?.message}</FormHelperText>

                        </FormControl>
                      </Grid>
                    }
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            {/* end other details accordion */}
          </Grid>
          {/* accordion 4 */}

          {/* dependent applicant */}
          {
            fields1?.map((field, index) => {
              return (<Accordion key={field.id} className="accordion" sx={{ marginTop: '25px', border: '2px solid', borderColor: (errors?.dependents?.[index] !== undefined) ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : panel5}
                expanded={isClosedSession === true || isQuoteGenerated ? true : panel5}
                onChange={() => setPanel5(!panel5)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Grid container>
                    <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("PersonalDetailsDependent")}</Typography></Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }} >
                      <IconButton aria-label="delete" sx={{ color: '#FF7B6F', padding: '0' }} onClick={() => _deleteDependent(index, watch(`dependents.${index}.id`))}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className='accordionPadding'>
                  <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        className="formElement"
                        label={t("dependentFirstName")}
                        type="text"
                        id="dependentName"
                        {...register(`dependents.${index}.firstName`, {
                          validate: {
                            noValue: (fieldValue) => {
                              return (
                                (fieldValue !== undefined && fieldValue !== '') || 'Please enter value'
                              )
                            },
                            sameValue: (fieldValue: any) => {
                              let dependents = watch(`dependents`)
                              let lastName = dependents[index]['lastName']
                              let relationshipId = dependents[index]['relationId']
                              let dependentsName = dependents?.filter((element: any) => element?.firstName === fieldValue && element?.lastName === lastName && relationshipId === 4)
                              return (
                                (dependentsName?.length <= 1) || 'Child details with same name already added.'
                              )
                            },
                            maximumLength: (fieldValue) => {
                              return (
                                (fieldValue?.length < 21) || 'You have exceeded the character limit.'
                              )
                            },
                          },
                          onChange: (e) => {
                            if (watch(`dependents.${index}.relationId`) === 3) {
                              setValue(`coApplicant.firstName`, e.target.value);
                            }
                          }

                        })}

                        error={!!errors?.dependents?.[index]?.firstName}
                        helperText={errors?.dependents?.[index]?.firstName?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        className="formElement"
                        label={t("dependentLastName")}
                        type="text"
                        id="dependentName1"
                        {...register(`dependents.${index}.lastName`, {
                          validate: {
                            noValue: (fieldValue: any) => {
                              return (
                                (fieldValue !== undefined && fieldValue !== '') || 'Please enter value'
                              )
                            },
                            sameValue: (fieldValue: any) => {
                              let dependents = watch(`dependents`)
                              let firstName = dependents[index]['firstName']
                              let relationshipId = dependents[index]['relationId']
                              let dependentsName = dependents?.filter((element: any) => element?.lastName === fieldValue && element?.firstName === firstName && relationshipId === 4)
                              return (
                                (dependentsName?.length <= 1) || 'Child details with same name already added.'
                              )
                            },
                            maximumLength: (fieldValue: any) => {
                              return (
                                (fieldValue?.length < 21) || 'You have exceeded the character limit.'
                              )
                            },
                          },
                          onChange: (e) => {
                            if (watch(`dependents.${index}.relationId`) === 3) {
                              setValue(`coApplicant.lastName`, e.target.value);
                            }
                          }

                        })}
                        error={!!errors?.dependents?.[index]?.lastName}
                        helperText={errors?.dependents?.[index]?.lastName?.message}
                      />
                    </Grid>

                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <Controller
                          defaultValue={""}
                          control={control}
                          {...register(`dependents.${index}.dob`, {
                            required: "Please enter a value",
                            validate: {
                              noValue: (fieldValue) => {
                                return (
                                  fieldValue?.$d != 'Invalid Date' || 'Please enter a valid date'
                                )
                              },

                              noFutureDate: (fieldValue) => {
                                let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                let currentDate = new Date();
                                let enterDay = new Date(dt)
                                let pastDate = new Date().getFullYear() - 100;
                                let currentMonth = new Date().getMonth() + 1
                                let pastDay = pastDate + "-" + currentMonth + "-" + new Date().getDate()
                                let minDate = new Date(pastDay)
                                return (
                                  (enterDay <= currentDate && enterDay >= minDate) || 'Please enter a valid date'
                                )
                              }
                            },
                            onChange: (e) => {
                              if (watch(`dependents.${index}.relationId`) === 3) {
                                setValue(`coApplicant.dob`, e.target.value);
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                onChange={(e) => formatedDateDob(field.onChange(e))}
                                value={field.value ? formatedDateDob(field.value) : null}
                                minDate={formatedDateDob('01/01/1923')}
                                maxDate={formatedDateDob('')}
                                openTo="year"
                                format="DD/MM/YYYY"
                                label={t("dateOfBirthText")} slotProps={{ textField: { variant: 'standard', inputRef: ref } }} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dependents?.[index]?.dob?.message?.toString()}</FormHelperText>
                            </LocalizationProvider>)
                          }} />
                      </FormControl>

                    </Grid>

                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="Relationshiplabel">Select Relationship</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={'' as any}
                          {...register(`dependents.${index}.relationId` as const, {
                            required: "Please select a option"
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="relationId-label"
                                label="Relationship"
                                error={!!errors?.dependents?.[index]?.relationId}
                                id="relationId"
                                {...field}
                                inputRef={ref}
                                onChange={(e) => {
                                  field.onChange(e);
                                  if (e.target.value === 3) {
                                    let data: any = e.target.name;
                                    setValue(`coApplicant.firstName`, watch(`dependents.${data.split('.')[1]}.firstName`));
                                    setValue(`coApplicant.lastName`, watch(`dependents.${data.split('.')[1]}.lastName`));
                                    setValue(`coApplicant.residentialCountry`, watch(`dependents.${data.split('.')[1]}.residentialCountry`));
                                    setValue(`coApplicant.nationality`, watch(`dependents.${data.split('.')[1]}.nationality`));
                                    setValue(`coApplicant.gender`, watch(`dependents.${data.split('.')[1]}.gender`));
                                    setValue(`coApplicant.dob`, watch(`dependents.${data.split('.')[1]}.dob`));
                                  }
                                  if (e.target.value === 4 && cildCount < 4) {
                                    setcildCount(cildCount + 1);
                                  }
                                }}
                              >
                                <MenuItem value={1}>Parent</MenuItem>
                                <MenuItem value={2}>Sibling</MenuItem>
                                <MenuItem value={3} disabled={!watch('maritalStatus')}>Spouse</MenuItem>
                                <MenuItem value={4} disabled={cildCount >= 4}> Child</MenuItem>
                                <MenuItem value={5}>Aunt/Uncle</MenuItem>
                                <MenuItem value={6}>Cousin</MenuItem>
                                <MenuItem value={7}>Others</MenuItem>
                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dependents?.[index]?.relationId?.message}</FormHelperText>
                      </FormControl>
                    </Grid>



                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="demo-simple-select-filled-label">{t("PersonalDetailsNationality")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`dependents.${index}.nationality` as const, {
                            required: "Please select a option",
                            onChange: (e) => {
                              if (watch(`dependents.${index}.relationId`) === 3) {
                                setValue(`coApplicant.nationality`, e.target.value);
                              }
                            }
                          })}


                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="Nationality-label"
                              label="Nationality"
                              id="Nationality"
                              error={!!errors?.dependents?.[index]?.nationality}
                              {...field}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dependents?.[index]?.nationality?.message}</FormHelperText>
                      </FormControl>

                    </Grid>


                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="demo-simple-select-filled-label">{t("PersonalDetailsCountryOfResident")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`dependents.${index}.residentialCountry` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                if (isJointApplicant === true) {
                                  return (
                                    (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                  )
                                }
                              },

                            },
                            onChange: (e) => {
                              if (watch(`dependents.${index}.relationId`) === 3) {
                                setValue(`coApplicant.residentialCountry`, e.target.value);
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (<Select
                              labelId="ResidentCountry-label"
                              label={t("Residentcountry")}
                              id="residentCountry"
                              error={!!errors?.dependents?.[index]?.residentialCountry}
                              {...field}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>)
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dependents?.[index]?.residentialCountry?.message}</FormHelperText>
                      </FormControl>
                    </Grid>

                    {/* <FourRisksComponent control={control} setValue={setValue} dropdowndata={dependentArray}/> */}
                    <Grid item xs={6} className="marginTop">
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="Genderlabel">{t("customiseTableGender")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue=""
                          {...register(`dependents.${index}.gender` as const, {
                            required: "Please select a option",
                            onChange: (e) => {
                              if (watch(`dependents.${index}.relationId`) === 3) {
                                setValue(`coApplicant.gender`, e.target.value);
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="Gender-label"
                                label="Gender"
                                id="dependentGender"
                                {...field}
                                error={!!errors?.dependents?.[index]?.gender}
                                inputRef={ref}
                              >
                                <MenuItem value='male'>Male</MenuItem>
                                <MenuItem value='female'>Female</MenuItem>
                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dependents?.[index]?.gender?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>)
            })
          }
          {fields1?.length < 8 &&
            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
              <Grid item xs={6}>
                <Button variant="outlined" onClick={() => append1({
                  firstName: '',
                  lastName: '',
                  dob: null,
                  gender: '',
                  nationality: '',
                  residentialCountry: '',
                  relationId: null
                })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                  {t("PersonalDetailsAddDependent")}
                </Button>
              </Grid>
            </Grid>
          }
          {/* end accordion 4 */}
          {/* accordion 5 */}
          <Grid item xs={12} sx={{ marginTop: '25px', display: isJointApplicant ? 'block' : 'none' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: isCoApplicantError }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : panel6}
              expanded={isClosedSession === true || isQuoteGenerated ? true : panel6}
              onChange={() => setPanel6(!panel6)} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("PersonalDetailsJointLifeApplicant")}</Typography></Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }} >
                  <IconButton aria-label="delete" sx={{ color: '#FF7B6F', padding: '0' }} onClick={() => _deleteCoApplicant()}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>

              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container className="padding-top">
                  <Grid item xs={6}>
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="CoappTitle-label" className='formLabel'>{t("PersonalDetailsTitle")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        {...register(`coApplicant.title` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (
                            <Select
                              labelId="CoappTitle-label"
                              label="Title"
                              error={!!errors?.coApplicant?.title}
                              type="string"
                              id="coapptitle"
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value='Mr.'>Mr.</MenuItem>
                              <MenuItem value='Ms.'>Ms.</MenuItem>
                              <MenuItem value='Mrs.'>Mrs.</MenuItem>
                              <MenuItem value='Dr.'>Dr.</MenuItem>
                              <MenuItem value='Prof.'>Prof.</MenuItem>
                              <MenuItem value='Dame'>Dame</MenuItem>
                              <MenuItem value='Earl'>Earl</MenuItem>
                              <MenuItem value='Er'>Er</MenuItem>
                              <MenuItem value='Lady'>Lady</MenuItem>
                              <MenuItem value='Master'>Master</MenuItem>
                              <MenuItem value='Mdm'>Mdm</MenuItem>
                              <MenuItem value='Miss'>Miss</MenuItem>
                              <MenuItem value='Mr. & Mrs.'>Mr. & Mrs.</MenuItem>
                              <MenuItem value='Reverend'>Reverend</MenuItem>
                              <MenuItem value='Rt Hon'>Rt Hon</MenuItem>
                              <MenuItem value='Sir'>Sir</MenuItem>
                            </Select>
                          )
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.title?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      className="formElement"
                      label={t("firstNameText")}
                      type="text"
                      id="coAppfirstName"
                      {...register(`coApplicant.firstName`, {
                        validate: {
                          noValue: (fieldValue) => {
                            if (isJointApplicant === true) {
                              return (
                                (fieldValue !== undefined) || 'Please select option'
                              )
                            }
                          },
                          maximumLength: (fieldValue) => {
                            if (isJointApplicant === true) {
                              return (
                                (fieldValue?.length < 21 && fieldValue !== '') || 'You have exceeded the character limit.'
                              )
                            }
                          },
                        }
                      })}
                      error={!!errors?.coApplicant?.firstName}
                      helperText={errors?.coApplicant?.firstName?.message}
                    />
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <TextField
                      variant="standard"
                      className="formElement"
                      label={t("lastNameText")}
                      type="text"
                      id="coApplastName"
                      {...register(`coApplicant.lastName`, {
                        validate: {
                          noValue: (fieldValue) => {
                            if (isJointApplicant === true) {
                              return (
                                (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                              )
                            }
                          },
                          maximumLength: (fieldValue) => {
                            if (isJointApplicant === true) {
                              return (
                                (fieldValue?.length < 21) || 'You have exceeded the character limit.'
                              )
                            }
                          },
                        }
                      })}
                      error={!!errors?.coApplicant?.lastName}
                      helperText={errors?.coApplicant?.lastName?.message}
                    />
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="coApplicant-resident-country-label">Country of Resident</InputLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        {...register(`coApplicant.residentialCountry` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                )
                              }
                            },
                            noUAE: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue === 'AE') || 'Our products are designed to serve residents of the UAE'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (
                            <Select
                              labelId="coApplicant-resident-country-label"
                              label="Resident country"
                              id="coAppresidentCountry"
                              error={!!errors?.coApplicant?.residentialCountry}
                              onChange={(e) => field.onChange(e)}
                              value={field.value}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>
                          )
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.residentialCountry?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="coApplicant-nationality-label">{t("PersonalDetailsNationality")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        {...register(`coApplicant.nationality` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (
                            <Select
                              labelId="CoApplicant-nationality-label"
                              label="Nationality"
                              id="coAppNntionality"
                              error={!!errors?.coApplicant?.nationality}
                              onChange={(e) => field.onChange(e)}
                              value={field.value}
                              inputRef={ref}
                            >
                              {countryCodeList ?
                                countryCodeList?.map((listElement: any, index: number) => {
                                  return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                }) : ''
                              }
                            </Select>
                          )
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.nationality?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="CoApplicant-gender-label">Gender</InputLabel>
                      <Controller
                        control={control}
                        defaultValue=""
                        {...register(`coApplicant.gender` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (
                            <Select
                              labelId="CoApplicant-gender-label"
                              label="Gender"
                              id="coapplicantgender"
                              error={!!errors?.coApplicant?.gender}
                              {...field}
                              inputRef={ref}
                            >
                              <MenuItem value='male'>Male</MenuItem>
                              <MenuItem value='female'>Female</MenuItem>
                            </Select>
                          )
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.gender?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <Controller
                        defaultValue={null}
                        control={control}
                        {...register(`coApplicant.dob`, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue?.$d != 'Invalid Date' && fieldValue?.$d !== '' && fieldValue !== undefined) || 'Please enter a valid date'
                                )
                              }
                            },
                            noFutureDate: (fieldValue) => {
                              let psDate = new Date().toDateString();

                              let entererDate = new Date(fieldValue?.$d).toDateString();

                              let diff = Number(Math.floor((Date.parse(psDate) - Date.parse(entererDate)) / 86400000));

                              return (

                                // (enteredDate < currentDate && diff  <= 27027 && enteredDate > pastDate )  || 'Error: minimum age is 17 and maximum age is 74'



                                (diff >= 6209 && diff <= 27027) || 'Error: minimum age is 17 and maximum age is 74'
                              )
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (<LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={(e) => field.onChange(e)}
                              value={formatedDateDob(field.value)}
                              format="DD/MM/YYYY"
                              minDate={formatedDateDob('01/01/' + (new Date().getFullYear() - 73))}
                              maxDate={formatedDateDob('12/31/2005')}
                              label='Date of birth' slotProps={{ textField: { variant: 'standard', inputRef: ref } }} />
                          </LocalizationProvider>)
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.dob?.message?.toString()}</FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="CoapplicantMaritialStatus-label">{t("PersonalDetailsMaritalStatus")}</InputLabel>

                      <Controller
                        defaultValue={"" as any}
                        control={control}
                        {...register(`coApplicant.maritalStatus` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined) || 'Please select option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (
                            <Select
                              labelId="CoapplicantMaritialStatus-label"
                              label="Maritial Status"
                              error={!!errors?.coApplicant?.maritalStatus}
                              id="coapplicantmaritalStatus"
                              {...field}
                              inputRef={field.ref}
                            >
                              <MenuItem value={true as any}>Married</MenuItem>
                              <MenuItem value={false as any}>Single</MenuItem>

                            </Select>
                          )
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.maritalStatus?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="marginTop">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="smokerStatus-label">{t("PersonalDetailsSmoker")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={'' as any}
                        {...register(`coApplicant.isSmoker` as const, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined) || 'Please select option'
                                )
                              }
                            }
                          }
                        })}
                        render={({ field }) => {
                          const { ref } = field;
                          return (<Select
                            labelId="isSmoker-label"
                            label="Are you a smoker or use tobacco products?"
                            error={!!errors?.coApplicant?.isSmoker}
                            id="maritalStatus"
                            {...field}
                            inputRef={ref}
                          >
                            <MenuItem value={true as any}>Smoker</MenuItem>
                            <MenuItem value={false as any}>Non Smoker</MenuItem>
                          </Select>)
                        }} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.isSmoker?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {
                    fields2.map((field, index) => {
                      return <Grid key={field.id} container sx={{ textAlign: 'left', padding: '30px 0 0 18px' }}>
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement">
                            <Controller
                              control={control}
                              defaultValue={"" as any}
                              {...register(`coApplicant.contactDetails.${index}.countryCode` as const, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (isJointApplicant === true) {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== '' && fieldValue !== null) || 'Please select option'
                                      )
                                    }
                                  }
                                }
                              })}
                              render={({ field: { ref, onChange, value } }) => (
                                <Autocomplete
                                  onChange={(event: any, item: any) => {
                                    onChange(item ? item?.dialCode : null);
                                  }}
                                  value={
                                    value
                                      ? countryDialCodeList?.find((country: any) => {
                                        return value === country?.dialCode

                                      }) ?? null
                                      : null
                                  }
                                  options={countryDialCodeList}
                                  getOptionLabel={(item: any) => (item.dialCode ? item.dialCode + " " + item.name : "")}

                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      value={params.value}
                                      label="Country Code"
                                      name="countryCode"
                                      margin="normal"
                                      variant="standard"
                                      sx={{ marginTop: '0' }}
                                      error={!!errors?.coApplicant?.contactDetails?.[index]?.countryCode}
                                      helperText={errors?.coApplicant?.contactDetails?.[index]?.countryCode?.message}
                                      inputRef={ref}
                                    />
                                  )}
                                  selectOnFocus={false}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            label={t("PersonalDetailsPhoneNumber")}
                            id="coappphoneNo"
                            {...register(`coApplicant.contactDetails.${index}.phoneNo`, {
                              validate: {
                                noValue: (fieldValue) => {
                                  if (isJointApplicant === true) {
                                    return (
                                      (fieldValue !== undefined && fieldValue !== '') || 'Please enter a value'
                                    )
                                  }
                                }
                              },
                              maxLength: {
                                value: 10,
                                message: "Please enter a valid mobile number."
                              }
                            })}
                            error={!!errors?.coApplicant?.contactDetails?.[index]?.phoneNo}
                            helperText={errors?.coApplicant?.contactDetails?.[index]?.phoneNo?.message}
                          />
                          {index > 0 &&
                            <IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => deleteContactDetails(index, true)}>
                              <DeleteIcon />
                            </IconButton>
                          }
                        </Grid>
                      </Grid>
                    })}
                  {fields2?.length < 2 &&
                    <Grid container>
                      <Grid item xs={6} sx={{ marginTop: '15px', padding: '12px', textAlign: 'left' }}>
                        <Button variant="outlined" onClick={() => append2({
                          id: '',
                          countryCode: '',
                          phoneNo: ''
                        })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                          {t("PersonalDetailsAddPhoneNmber")}
                        </Button>
                      </Grid>
                    </Grid>
                  }
                  <Grid container>
                    <Grid item xs={6} sx={{ marginTop: '15px', padding: '10px 0 0 18px', textAlign: 'left' }}>
                      <TextField
                        variant="standard"
                        className="formElement"
                        label={t("PersonalDetailsEmailID")}
                        type="email"
                        id="coappemail"
                        {...register(`coApplicant.email`, {
                          validate: {
                            noValue: (fieldValue) => {
                              if (isJointApplicant === true) {
                                return (
                                  (fieldValue !== undefined && fieldValue !== '') || 'Please enter a value'
                                )
                              }
                            }
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })}
                        error={!!errors?.coApplicant?.email}
                        helperText={errors?.coApplicant?.email?.message}
                      />
                    </Grid>
                    {/* fatca co-applicant */}
                    <Grid item xs={6} sx={{ marginTop: '15px', padding: '10px 0 0 0', textAlign: 'left' }}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="CoappisUSTaxPayer-label" className='formLabel'>{t("PersonalDetailsUStaxPayer")}</InputLabel>
                        <Controller
                          defaultValue={'' as any}
                          control={control}
                          {...register(`coApplicant.fatcaDeclaration.isUSTaxPayer`, {
                            validate: {
                              noValue: (fieldValue) => {
                                if (isJointApplicant === true) {
                                  return (
                                    (fieldValue !== undefined) || 'Please select option'
                                  )
                                }
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="CoappisUSTaxPayer-label"
                                label="Are you a US* tax payer?"
                                id="CoAppisUSTaxPayer"
                                type="string"
                                error={!!errors?.coApplicant?.fatcaDeclaration?.isUSTaxPayer}
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.fatcaDeclaration?.isUSTaxPayer?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ marginTop: '15px', padding: '10px 0 0 18px', textAlign: 'left' }}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="CoAppis_us_textpayer-label" className='formLabel'>Are you a US* citizen?</InputLabel>
                        <Controller
                          defaultValue={'' as any}
                          control={control}
                          {...register(`coApplicant.fatcaDeclaration.isUSCitizen` as const, {
                            validate: {

                              noValue: (fieldValue) => {
                                if (isJointApplicant === true) {
                                  return (
                                    (fieldValue !== undefined) || 'Please select option'
                                  )
                                }
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="CoAppis_isUSCitizen-label"
                                label="Are you a US* tax payer ?"
                                type="string"
                                error={!!errors?.coApplicant?.fatcaDeclaration?.isUSCitizen}
                                id="CoAppis_isUSCitizen"
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.fatcaDeclaration?.isUSCitizen?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ marginTop: '15px', padding: '10px 0 0 0', textAlign: 'left' }}>
                      <FormControl variant="standard" className="formElement">
                        <InputLabel id="isTelephoneUS-label" className='formLabel'>{t("PersonalDetailsPhoneUSBased")}</InputLabel>
                        <Controller
                          defaultValue={'' as any}
                          control={control}
                          {...register(`coApplicant.fatcaDeclaration.isTelephoneUS` as const, {
                            validate: {
                              noValue: (fieldValue) => {
                                if (isJointApplicant === true) {
                                  return (
                                    (fieldValue !== undefined) || 'Please select option'
                                  )
                                }
                              }
                            }
                          })}
                          render={({ field }) => {
                            const { ref } = field;
                            return (
                              <Select
                                labelId="isTelephoneUS-label"
                                label="Is phone number given a US* based?"
                                id="CoApp_isTelephoneUS"
                                error={!!errors?.coApplicant?.fatcaDeclaration?.isTelephoneUS}
                                onChange={(e) => field.onChange(e)}
                                value={field.value}
                                inputRef={ref}
                              >
                                <MenuItem value={true as any}>Yes</MenuItem>
                                <MenuItem value={false as any}>No</MenuItem>

                              </Select>
                            )
                          }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicant?.fatcaDeclaration?.isTelephoneUS?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} className="marginTop" sx={{ textAlign: 'left', paddingLeft: '15px' }}>
                      <Typography variant="h6" color="textPrimary"
                        style={{ color: '#A89F96', marginBottom: '0.75em', fontSize: '12px' }}>
                        {t("PersonalDetailsDefinationMessage")}
                      </Typography>
                      <Typography variant="h6" color="textPrimary"
                        style={{ color: '#A89F96', marginBottom: '0.75em', fontSize: '12px' }}>
                        {t("PersonalDetailsClause")}
                      </Typography>
                    </Grid>
                    {/* end facta */}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* end accodion 5*/}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} endIcon={<ArrowForwardIcon />} variant="contained" sx={{ background: '#005EB8', float: 'right', width: '10%' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form>
        {/* end Form */}
      </Grid>
      <DevTool control={control} />
      <ToastContainer />
      <Dialog
        open={open}
        onClose={() => handleClosePopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("PersonalDetailsDeclaration")}:
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              {t("PersonalDetailsDeclarationMessage")}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClosePopUp(false)}>Disagree</Button>
          <Button onClick={() => handleClosePopUp(true)} autoFocus>
            {t("PersonalDetailsAgree")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openConfirmation}
        onClose={() => handleConfirmationClosePopUp(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              {t("PersonalDetailsThanksMessage")}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationClosePopUp(false)} autoFocus>
            {t("FinanceAdviceOk")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PersonalDetails;
