import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Drawer, Typography } from '@mui/material';
import List from '@mui/material/List';
import logo from '../Images/logo.png'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import dashboardImage from '../Images/dashboardImage.png'
import settingsImage from '../Images/settings.png'
import navigation from '../Images/navigationPointer.png'
import plusCircleImage from '../Images/plus-circle.png'
import contactImage from '../Images/contact.png'
import fileWarningImage from '../Images/file-warning.png'
import clipboardListImage from '../Images/clipboard-list.png'
import frameImage from '../Images/Frame.png'
import { useNavigate } from 'react-router-dom'
import summaryImg from "../Images/Frame.png"
import coins from "../Images/coins.svg"
import { useOktaAuth } from '@okta/okta-react';

const SideBar = ({ currentPage, setCurrentPage }: any) => {
    let navigate = useNavigate();
    const [isNavBarOpen, setIsNavBarOpen] = React.useState(true)
    const _goToSoluations = () => {
        navigate('/solution', { replace: true })
    }
    const { oktaAuth } = useOktaAuth();
    const goToLogin = () => {
        //await oktaAuth.signOut();
        navigate('/login')
    };
    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Drawer
                variant="permanent"
                anchor='left'
                className="navigationBar"
                open={isNavBarOpen}
                sx={{
                    width: '100%',
                    position: 'relative',
                    height: '100%',
                }}>
                <Grid container sx={{
                    backgroundColor: '#012169 !important',
                    paddingBottom: "20px",
                    backgroundImage: `url(${summaryImg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right bottom",
                    backgroundSize: "60%",
                    borderBottom: '1px solid #b8b8b8',
                    height: '100%',
                    marginBottom: '32%',
                }}>
                    <Grid xs={12}>
                        <List sx={{ padding: '5px' }}>
                            <img id="headerLogo" className='zurich-logo' alt='logo' src={logo} />
                        </List>
                        <List className='menu-items' onClick={() => setCurrentPage(1)} sx={{
                            textAlign: 'left', marginTop: '15% !important', display: 'flex', backgroundImage: `url(${currentPage === 1 ? navigation : ''})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right center",
                            backgroundSize: "10%",
                        }}>
                            <Box sx={{ marginRight: '15px' }} > <img id="dashboard" className='navigation-image' alt='logo' src={dashboardImage} /></Box><Box> Dashboard </Box>
                        </List>
                        <>
                            <List className='menu-items' sx={{
                                textAlign: 'left', display: 'flex',
                                backgroundImage: `url(${currentPage === 4 ? navigation : ''})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right center",
                                backgroundSize: "10%"
                            }} onClick={() => setCurrentPage(4)}>
                                <Box sx={{ marginRight: '15px' }}><img id="settingsImage" className='navigation-image' alt='logo' src={settingsImage} /> </Box><Box>Settings</Box>

                            </List>
                            <List className='menu-items' sx={{ textAlign: 'left', display: 'flex' }} onClick={() => setCurrentPage(5)}>
                                <Box sx={{ marginRight: '15px' }}  ><img id="contactImage" className='navigation-image' alt='logo' src={contactImage} /></Box><Box>User Guide</Box>
                            </List>
                            <List className='menu-items' sx={{
                                textAlign: 'left', display: 'flex',
                                backgroundImage: `url(${currentPage === 6 ? navigation : ''})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right center",
                                backgroundSize: "10%"
                            }} onClick={() => setCurrentPage(6)}>
                                <Box sx={{ marginRight: '15px' }}><img id="plusCircleImage" className='navigation-image' alt='logo' src={plusCircleImage} /></Box><Box>Create Session</Box>
                            </List>
                            <List className='menu-items' sx={{
                                textAlign: 'left', display: 'flex',
                                backgroundImage: `url(${currentPage === 15 ? navigation : ''})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right center",
                                backgroundSize: "10%"
                            }} >
                                <Box sx={{ marginRight: '15px' }}><img id="fileWarningImage" className='navigation-image' alt='logo' src={fileWarningImage} /></Box><Box>General Feedback</Box>
                            </List>
                            <List className='menu-items' sx={{ textAlign: 'left', display: 'flex' }}>
                                <Box sx={{ marginRight: '15px' }}><img id="clipboardListImage" className='navigation-image' alt='logo' src={clipboardListImage} /></Box><Box>Sales Performance</Box>
                            </List>
                        </>
                        <List onClick={goToLogin} className='menu-items' sx={{ textAlign: 'left', display: 'flex', position: 'absolute', bottom: '28px' }}>
                            <Box sx={{ marginRight: '15px' }}><LogoutOutlinedIcon className='navigation-image' /></Box><Box>Logout</Box>
                        </List>
                        <List className='menu-items' sx={{ textAlign: 'center !important', position: 'absolute', bottom: '2px' }}>
                            <Box><Typography sx={{ fontSize: '12px', color: '#fff', paddingTop: '10px', textAlign: 'center' }}> Version 1.10 </Typography></Box>
                        </List>
                    </Grid>
                </Grid>
            </Drawer>

        </Box>
    )
}

export default SideBar