import React, { useEffect } from 'react'
import AllSession from './AllSession'
import { fetchCustomersSelectExisting, fetcAppConstant, fetchAnnualReviewCustomersSelectExisting, fetchDashboardAPI, fetchAnnualReviewCustomersClose, fetchAnnualReviewCustomers, fetchCountry, fetchCountryDialCode, fetchRateVisecountryList, fetchCountryRate, fetchRelations, fetchIncomeSource, fetchIncomeExpense, fetchIncomeSavings } from '../redux/GenericDetailsSlice'
import Cards from './Cards'
import Grid from '@mui/material/Grid';
import { useAppSelector, useAppDispatch } from '../redux/hook'
import Dialog from '@mui/material/Dialog';
import spinner from '../Images/zurich.gif'
import RelationTable from './RelationTable'
import { useOktaAuth } from '@okta/okta-react';

import { useNavigate } from 'react-router-dom'
type PropsType = {
  setCurrentPage: any,
  isZenHead: boolean
}
const Dashboard = ({ setCurrentPage, isZenHead }: PropsType) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)


  useEffect(() => {
    sessionStorage.removeItem("closedSession");
    setShowSpinner(true);
    try {
      // dispatch(fetchCustomersSelectExisting(''));
      dispatch(fetchAnnualReviewCustomersSelectExisting());
      dispatch(fetchCountry());
      dispatch(fetchAnnualReviewCustomers());
      dispatch(fetchAnnualReviewCustomersClose());
      dispatch(fetchCountryDialCode());
      dispatch(fetchRateVisecountryList());
      dispatch(fetchCountryRate());
      dispatch(fetchRelations());
      dispatch(fetchIncomeSource());
      dispatch(fetchIncomeExpense());
      dispatch(fetchIncomeSavings());
      dispatch(fetcAppConstant());
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
      }
    }
    setShowSpinner(false);
  }, []);
  return (
    <>
      <Dialog className='spinner' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Grid container spacing={2} sx={{ padding: '35px' }}>
        <Grid xs={12} md={12} sx={{ paddingRight: '25px' }}>
          <Cards setCurrentPage={setCurrentPage} isZenHead={isZenHead} />
        </Grid>
        <Grid xs={12} md={isZenHead === true ? 5 : 6} sx={{ paddingRight: isZenHead === true ? '5px' : '25px' }}>
          <AllSession setCurrentPage={setCurrentPage} sessionDetails={'Open'} />
        </Grid>
        <Grid xs={12} md={isZenHead === true ? 5 : 6} sx={{ paddingLeft: isZenHead === true ? '5px' : '25px' }}>
          <AllSession setCurrentPage={setCurrentPage} />
        </Grid>
        {isZenHead === true &&
          <Grid xs={12} md={2} sx={{ paddingLeft: isZenHead === true ? '5px' : '25px' }}>
            <RelationTable setCurrentPage={setCurrentPage} sessionDetails={'RelationShipTable'} />
          </Grid>
        }
      </Grid>
    </>
  )
}

export default Dashboard