import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Button, Container, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { NavigationSection } from './NavigationSection';
import SimCardAlertOutlinedIcon from '@mui/icons-material/SimCardAlertOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import vector from '../../Images/Vector.svg'
import Grid from '@mui/material/Grid';
import FourRisksComponent from './FourRisksComponent';
function FourRisks() {
  return (
    <>
     <Container style={{ marginTop: '40px',marginBottom:'40px'}}>
           
           <Grid container sx={{paddingTop:'15px'}}>
              
                <Grid item xs={12}> 
                    <FourRisksComponent />
                </Grid>
            </Grid>    
        </Container></>
  )
}

export default FourRisks
