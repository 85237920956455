import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Drawer, Typography } from '@mui/material';
import List from '@mui/material/List';
import logo from '../Images/logo.png'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom'
import summaryImg from "../Images/Loginscreen.png"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useOktaAuth } from '@okta/okta-react';
import config from './../config';

const Login = ({ setCurrentPage }: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = React.useState(null);
  let navigate = useNavigate();
  const [isNavBarOpen, setIsNavBarOpen] = React.useState(true)
  const _goToDashboard = () => {
    navigate('/dashboard', { replace: true })
  }
  const navigateRegistration = () => {
    navigate('/registration', { replace: true })
  }
  React.useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, [])
  React.useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const oktalogin = async () => {
    //await oktaAuth.signInWithRedirect();//Use signInWithRedirect if the flow would go along with the details set initially.
    let authorisationURL = `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/v1/authorize?idp=${process.env.REACT_APP_OKTA_IDP}&client_id=${config.oidc.clientId}&response_type=id_token&response_mode=fragment&scope=openid email&redirect_uri=${config.oidc.redirectUri}&state=${generateRandomDigits()}&nonce=${generateRandomDigits()}`
    window.location.replace(authorisationURL);
  };
  const generateRandomDigits = () => {
    return Math.random().toString(36).slice(2);
  }
  return (
    <Box sx={{
      backgroundColor: '#012169 !important',
      height: '100vh',
      overflow: 'hidden',
      backgroundImage: `url(${summaryImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: '100% 100vh'
    }}>
      <Grid container>
        <Grid xs={6} sx={{ height: '100vh', textAlign: 'left' }} className='navigationSection'>
          <Grid container sx={{ padding: '25px 60px', color: '#fff' }}>
            <Grid item xs={12}><img id="headerLogo" className='zurich-logo' alt='logo' src={logo} style={{ paddingLeft: '0' }} /></Grid>
            <Grid item xs={12}><Typography variant="h3" sx={{ color: '#fff', fontSize: '28px', fontweight: '600' }}>Welcome to</Typography></Grid>
            <Grid item xs={12}><Typography variant="h3" className="font-face-ogg" sx={{ color: '#fff', fontSize: '36px' }}>Zurich Digital Advice</Typography></Grid>
          </Grid>
        </Grid>
        <Grid xs={3} sx={{ height: '100vh', textAlign: 'left', padding: '0' }} className='navigationSection'>
          <Grid container sx={{ margin: 'auto', background: '#fff', height: '160px', padding: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="h3" className="loginFont">Welcome <Typography component="span" sx={{ fontWeight: '600' }}>Back</Typography></Typography>
              <Typography sx={{ fontSize: '12px' }}>For authorized Zurich users only</Typography>
              <Button onClick={oktalogin} sx={{ display: 'block', justifyContent: 'left !important', marginTop: '25px' }} className="loginButton" variant="contained" ><span className="buttonText">Login via ZIO</span><span><ArrowForwardIosIcon sx={{ float: 'right', paddingLeft: '10%' }} /></span></Button>
            </Grid>
          </Grid>

        </Grid>
        <Grid xs={3} sx={{ height: '100vh' }}></Grid>
      </Grid>
    </Box>
  )
}

export default Login