import React, { useEffect, useState } from 'react';
import { DataGrid, GridEventListener, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { useSelector, useDispatch } from 'react-redux';
import { getAllCustomers, fetchCustomers, fetchCustomersCloseSession, fetcAppConstant, setSaveStatusPayload, saveStatus } from '../redux/GenericDetailsSlice'
import { BrowserRouter as Router } from 'react-router-dom';
import NotesIcon from '@mui/icons-material/Notes';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../redux/hook'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom'
import spinner from '../Images/zurich.gif'

type propsType = {
  tableFilter?: string,
  sessionDetails?: string,
  setCurrentPage?: any
}

const RelationTable = ({ tableFilter, sessionDetails, setCurrentPage }: propsType) => {
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const saveStatusPayload = useSelector((state: any) => state.genericDetails?.saveStatusPayload);
  const dispatch = useAppDispatch();
  const customerDetailsObj = useSelector((state: any) => state.genericDetails?.dashboardDetails);
  const applicationStatus = useSelector((state: any) => state.genericDetails?.applicationStatus);
  const [notesValue, setNotesValue] = React.useState<string>('')
  const CustomToolbar = () => {
    return <Grid container sx={{ padding: '8px' }}>
      <Grid item xs={8}>
        <Typography className="secoundaryFont primary-color bold-font textAlign">My Team({customerDetails?.length})</Typography>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'right' }}>
        <Box sx={{ display: 'none', cursor: 'pointer' }} ><Typography className="primary-color smallFont">View all </Typography><ArrowRightAltIcon className="primary-color smallFont" sx={{ paddingTop: '3px' }} /></Box>
      </Grid>
    </Grid>
  }
  const [expenseDetails, setExpenseDetails] = React.useState({
    expensePlace: {
      value: '',
      error: false,
      errorText: ''
    }
  })
  useEffect(() => {
    setShowSpinner(true)
    dispatch(fetcAppConstant());
    setShowSpinner(false)
  }, [])

  let customerDetails = []
  const excludeColumns = ["name", "advisors"];
  if (tableFilter) {
    customerDetails = customerDetailsObj?.teams?.list
      .filter((item: any) => {
        return Object.keys(item).some(key =>
          excludeColumns?.includes(key) ?
            item[key]?.toString().toLowerCase().includes(tableFilter.toLowerCase()) : false);
      });
  } else {
    customerDetails = customerDetailsObj?.teams?.list?.length > 0 ? customerDetailsObj?.teams?.list : [];
  }

  const tableSettingsObj = useSelector((state: any) => state.genericDetails.settingsTable);

  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const getAge = (dob: string) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const _navigateSoluations = (id: string, customerId: string) => {
    let closedSession = false
    sessionStorage.setItem('sessiondetail', id)
    sessionStorage.setItem('customerdetail', customerId)
    sessionStorage.setItem('closedSession', closedSession?.toString())
    navigate('/solution', { replace: true, state: { closedSession } })
  }
  const renderNotesCell = (status: string, selectedId: string, notes: any, e: any) => {
    e.stopPropagation()
    setNotesValue(notes)
    dispatch(setSaveStatusPayload({ 'name': 'status', 'value': status, 'sessionId': selectedId }))
    setNotesDialogOpen(!notesDialogOpen)
  }
  const handleNotesDialogOpen = () => {
    setNotesDialogOpen(true)
  }
  const handleStatusChangeDialogOpen = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    setStatusDialogOpen(false)
    _fetchCustomerUpdatedList()
    window.location.reload()
    setShowSpinner(false)
  }
  const _fetchCustomerUpdatedList = () => {
    dispatch(fetchCustomers())
    dispatch(fetchCustomersCloseSession())
  }
  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false)
  }
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value, 'sessionId': selectedId }))
    setStatusDialogOpen(true)
  }
  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    setNotesValue(value)
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value }))
  }
  const renderRating = (params: any) => {
    let value = params?.row?.status
    return <Select
      labelId="expensePlace-label"
      id="expensePlace-select"
      variant="outlined"
      name="status"
      onChange={(e: any) => handleStatusChange(e, params?.id)}
      value={value}
      sx={{ width: '100%', height: '40px', border: '0', margin: '2px 5px 2px 0' }}
    >
      {applicationStatus ?
        applicationStatus.map((listElement: any, index: string) => {
          if (sessionDetails !== 'Open') {
            if (listElement.name !== "Meeting 1" && listElement.name !== "Meeting 2") {
              return <MenuItem key={index} value={listElement.key}>{listElement.name}</MenuItem>
            }
          } else {
            return <MenuItem key={index} value={listElement.key}>{listElement.name}</MenuItem>
          }
        }) : ''
      }
    </Select>;
  }
  const createData = (
    id: string,
    name: string,
    advisors: string,
  ) => {
    return { id, name, advisors };
  }
  const renderNotes = (params: any) => {
    return <><NotesIcon onClick={(e) => renderNotesCell(params?.row?.status, params?.row?.id, params.row?.NotesComments, e)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} /></>;
  }
  const renderEdit = (params: any) => {
    return <MoreVertIcon fontSize='small' onClick={(e) => _navigateSoluations(params?.row?.id, params?.row?.customerId)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer', display: 'none' }} />;
  }
  const columns: GridColDef[] = [
    { field: 'name', headerName: "Name", width: 120, hideable: tableSettingsObj.Name, disableColumnMenu: true, sortable: false },
    { field: 'advisors', headerName: "", width: 50, hideable: tableSettingsObj.Advisors, disableColumnMenu: true, sortable: false },
    {
      field: 'edit', headerName: "", renderCell: renderEdit, hideable: tableSettingsObj.edit,
      width: 40, minWidth: 40, disableColumnMenu: true, sortable: false
    },
  ];

  const rows: GridRowsProp = customerDetails?.length > 0 && customerDetails?.map((element: any, index: any) => {
    return createData(index, element?.name, "(" + element?.advisors + ")")
  })
  const useRowClick: GridEventListener<'rowClick'> = (params, event, details) => {
    let id = params?.row?.id;
    let customerId = params?.row?.customerId;
    //  _navigateSoluations(id,customerId)
  }
  const saveNotes = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    _fetchCustomerUpdatedList()
    window.location.reload()
    setShowSpinner(false)
  }

  return (
    <>

      <Dialog className='spinner' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Grid container>
        <Grid item xs={12}>
          <DataGrid
            className="applicantion"
            rows={rows}
            sx={{ height: '390px' }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}
            onRowClick={useRowClick}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Grid>
        <Dialog open={notesDialogOpen}>
          <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '250px', background: '#f6f9f8' }}>
            <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Advisor's note</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              value={notesValue}
              rows={5}
              name="notes"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotesChange(e, '')}
              sx={{ height: 'auto !important', width: '450px' }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { saveNotes(); setNotesDialogOpen(false) }}>
                Save
              </Button>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => setNotesDialogOpen(false)}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog onClose={handleStatusDialogClose} open={statusDialogOpen}>
          <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '15px', height: 'auto', background: '#f6f9f8' }}>
            <Box>
              <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Are you sure you want to update the sales stage?</Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleStatusChangeDialogOpen}>
                Save
              </Button>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleStatusDialogClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Grid>
    </>
  )
}

export default RelationTable