import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import logo from '../../Images/logoWhite.png'
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
function SoluationHeader({ setSelectedCurrency, selectedCurrency }: any) {
  const { t, i18n } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate()
  const userDetailsToken = localStorage.getItem('userDetails');
  const userDetails: any = userDetailsToken ? JSON.parse(userDetailsToken) : {};
  const bearerToken = localStorage.getItem('bearerToken')
  const [currency, setCurrency] = React.useState<string>(selectedCurrency);
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions);
        const responseDetails = await response.data;
        let detail = responseDetails?.gapCurrency ? responseDetails?.gapCurrency : 'USD'
        setCurrency(detail)
        setSelectedCurrency(detail)
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    border: '1px solid #D1D1D1',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '40ch'
      },
    },
  }));

  const goToLogin = () => {
    //await oktaAuth.signOut();
    navigate('/login')
  };
  const _changeCurrency = async (e: any) => {
    let { name, value } = e.target
    let details = {
      "sessionId": sessionStorage.getItem('sessiondetail'),
      "currency": value
    }
    const response = await axios.post(baseUrl + '/customers/gap-currency', details, requestOptions)
      .then((response) => {
        let detail = response?.data?.gapCurrency ? response?.data?.gapCurrency : 'USD'
        setCurrency(detail)
        setSelectedCurrency(detail)
      }).catch(error => {
        //_setPageID(2)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })

  }
  return (
    <AppBar position="static" sx={{ padding: '10px 25px', background: '#012169', boxShadow: 'none', color: '#000', borderBottom: '1px solid #f4f4f4' }}>
      <Grid maxWidth="xl" sx={{ textAlign: 'left' }}>
        <Toolbar disableGutters>
          <Grid item xs={2}>
            <img id="headerLogo" className='zurich-logo' alt='logo' src={logo} />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              noWrap
              className="font-face-ogg"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 500,
                color: '#FFF',
                fontSize: '30px',
                textDecoration: 'none',
              }}
            >
              {t("FinanceAdviceHello")}, {userDetails?.name}
            </Typography>
            <Typography
              variant="h2"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'arial',
                fontWeight: 500,
                color: '#21AFE5',
                fontSize: '12px',
                textDecoration: 'none',
              }}
            >
              {userDetails?.email}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right', color: '#005EB8' }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={currency}
              onChange={(e) => _changeCurrency(e)}
              sx={{ color: '#012169', background: '#fff', height: '40px', borderRadius: '5px' }}
              variant="outlined"
            >
              <MenuItem value='USD'>USD</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <Box sx={{ flexGrow: 0 }}>

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp">{userDetails?.name?.charAt(0).toUpperCase() + userDetails?.lastName?.charAt(0).toUpperCase()}</Avatar>
                  <ArrowDropDownIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px', float: 'right' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={goToLogin}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Toolbar>
      </Grid>
      <ToastContainer />
    </AppBar>
  );
}
export default SoluationHeader;
