import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Logout'];

function Header() {
  const { t, i18n } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const userDetailsToken = localStorage.getItem('userDetails');
  const userDetails: any = userDetailsToken ? JSON.parse(userDetailsToken) : {};

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    border: '1px solid #D1D1D1',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '40ch'
      },
    },
  }));

  const goToLogin = async () => {
    await oktaAuth.signOut({ // Note the 'await' here
      postLogoutRedirectUri: `${window.location.origin}/login`
    })
  };
  const latestDate = new Date().toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit"
  })
  return (
    <AppBar position="static" sx={{ padding: '5px 15px', background: '#fff', boxShadow: 'none', color: '#000', borderBottom: '1px solid #f4f4f4' }}>
      <Grid maxWidth="xl">
        <Toolbar disableGutters>
          <Grid item xs={6}>
            <Typography
              variant="h6"
              noWrap
              className="font-face-ogg"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 500,
                color: '#005EB8',
                fontSize: '30px',
                textDecoration: 'none',
              }}
            >
              {t("FinanceAdviceHello")}, {userDetails?.name}
            </Typography>
            <Typography
              variant="h2"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'arial',
                fontWeight: 500,
                color: '#b8b8b8',
                fontSize: '12px',
                textDecoration: 'none',
              }}
            >
              {latestDate}
            </Typography>
          </Grid>
          <Grid item xs={5}>

          </Grid>
          <Grid item xs={1} sx={{ textAlign: 'right', color: '#005EB8' }}> </Grid>
          <Grid item xs={1} sx={{ textAlign: 'center' }}>
            <Box sx={{ flexGrow: 0 }}>

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{
                  p: 0, "&:hover": {
                    backgroundColor: "#fff",
                  }
                }}>
                  <Avatar alt="Remy Sharp">{userDetails?.name?.charAt(0).toUpperCase() + userDetails?.lastName?.charAt(0).toUpperCase()}</Avatar>
                  <ArrowDropDownIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px', float: 'right' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={goToLogin}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Grid>
        </Toolbar>
      </Grid>
    </AppBar>
  );
}
export default Header;
