import React from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { setAnnualReviewTableCustomisation, setAnnualReviewCloseSessionTableCustomisation } from '../redux/GenericDetailsSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next';
const AnnualReviewTableSettings = ({ isOpenSession }: any) => {

    const dispatch = useDispatch()
    const handleTableSeetingsChange = (event: any) => {
        let columnName = event.target.name;
        let value = event.target.checked;
        if (isOpenSession === 'open') {
            dispatch(setAnnualReviewTableCustomisation({ 'name': columnName, 'value': !value }))
        } else {
            dispatch(setAnnualReviewCloseSessionTableCustomisation({ 'name': columnName, 'value': !value }))
        }
    }
    const { t, i18n } = useTranslation();
    return (
        <Box sx={{ width: '225px', textAlign: 'center', padding: '20px', background: '#fafafa', color: '#000' }}>
            <Typography variant='h6' style={{ textAlign: 'center', fontSize: '18px', padding: '18px', fontWeight: 700, marginTop: '15px' }} >Settings</Typography>
            <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: 400 }}>Settings</Typography>

            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Name" name="Name" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Status" name="Status" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="NotesComments" name="NotesComments" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Age" name="Age" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Gender" name="Gender" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Date Of Birth" name="DateOfBirth" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Mobile No" name="MobileNo" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Email Address" name="EmailAddress" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox />} label="Client Id" name="ClientId" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox />} label="Session Id" name="SessionId" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Last Edited" name="LastEdited" onChange={handleTableSeetingsChange} />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Owner" name="Owner" onChange={handleTableSeetingsChange} />
            </FormGroup>
        </Box>
    )
}

export default AnnualReviewTableSettings