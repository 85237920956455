import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import logo from '../../Images/logoWhite.png'
import { DevTool } from "@hookform/devtools";
import Tooltip from '@mui/material/Tooltip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Autocomplete, Dialog, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Registration() {
  // const { t, i18n } = useTranslation();
  let navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  //const countryDialCodeList = useSelector((state: any) => state.genericDetails.countryDialCodeList);
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [availableBudget, setAvailableBudget] = React.useState(0)
  const [channel, setChannel] = React.useState([])
  // const [radioValue, setRadioValue] = React.useState('L7')
  const [advisorManagerList, setAdvisorManagerList] = React.useState([])
  const [countryList, setCountryList] = React.useState([])
  const [openDialog, setOpenDialog] = React.useState(false)
  const [incomeTypeError, setIncomeTypeError] = React.useState(false)
  const [hideManager, setHideManager] = React.useState(false)
  const getChannel = async () => {

    const response = await fetch(
      baseUrl + '/channels', requestOptions
    );
    const data = await response.json();

    setChannel(data)
  }
  const fetchCountryDialCode = async () => {

    const response = await fetch(baseUrl + '/generic/countries?type=dial_code', requestOptions).then((response) => response.json())

    setCountryList(response)
  }
  const getAdvisorManager = async (role: string) => {

    const response = await fetch(
      baseUrl + '/users?type=' + role, requestOptions
    );
    const data = await response.json();


    setAdvisorManagerList(data)
  }


  const form = useForm<any>({
    mode: 'all'
  })

  const { register, control, handleSubmit, formState, reset, watch, setValue, getValues } = form
  let level = getValues('levelCode')
  let levelwatch = watch('levelCode')

  React.useEffect(() => {
    getChannel()
    fetchCountryDialCode()
    getAdvisorManager('L5')
  }, [])
  React.useEffect(() => {
    level === 'L7' ? setHideManager(true) : setHideManager(false)
  }, [level])

  const { errors } = formState;

  const _onSubmit = (data: any) => {

    let payload = !hideManager ? { ...data, supervisorId: '' } : { ...data }


    handleRegistration(payload)
  }


  const handleRegistration = async (userDetails: {}) => {
    const response = axios.post(baseUrl + '/users/v1/register', userDetails, requestOptions)
      .then((response) => {
        toast.success('User registered successfully', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        setTimeout(() => navigate('/login'), 3000)

      }).catch(error => {

        toast.error('User already present', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })


    return response
  }
  return (
    <>
      <AppBar position="static" sx={{ background: 'white', boxShadow: 'none', color: '#000', borderBottom: '1px solid #f4f4f4' }}>
        <Grid maxWidth="xl" sx={{ display: 'inline-flex' }}>

          <Grid item xs={2} sx={{ backgroundColor: '#012169', height: 70, display: 'flex', alignItems: 'center' }} >
            <img id="headerLogo" style={{ height: '40%', width: '60%' }} className='zurich-logo' alt='logo' src={logo} />
          </Grid>
          <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', paddingLeft: 5 }}>
            <Typography
              variant="h2"
              noWrap
              component="a"
              className="font-face-ogg"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 500,
                color: 'black',
                fontSize: '24px',
                textDecoration: 'none',
              }}
            >
              Registration

            </Typography>

          </Grid>


        </Grid>
      </AppBar>
      <Grid container sx={{ justifyContent: 'center' }}>
        {/* forms start */}

        <form onSubmit={handleSubmit(_onSubmit)} noValidate className='formFourCs' style={{ width: '50%' }}>
          <Grid item xs={12} sx={{ alignSelf: 'center', alignItems: 'center', paddingTop: '10%' }}>
            <FormControl variant="standard" className="formElement">
              <Grid >
                <TextField
                  variant="standard"
                  type="string"
                  defaultValue=""
                  //InputLabelProps={{ shrink: true }}
                  label='First Name'
                  sx={{ color: "#005EB8", width: '90%' }}
                  id="firstName"
                  {...register("firstName", {
                    required: "Please enter a value",

                  }
                  )}
                  error={!!errors?.firstName}
                  helperText={errors?.firstName?.message?.toString()}

                />
              </Grid>
              <Grid className='marginTop'>
                <TextField
                  variant="standard"
                  type="string"
                  defaultValue=""
                  //InputLabelProps={{ shrink: true }}
                  label='Last Name'
                  sx={{ color: "#005EB8", width: '90%' }}
                  id="lastName"
                  {...register("lastName", {
                    required: "Please enter a value",

                  }
                  )}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName?.message?.toString()}

                />
              </Grid>
              <Grid className="marginTop" >
                <TextField
                  variant="standard"
                  type="string"
                  defaultValue=""
                  //InputLabelProps={{ shrink: true }}
                  label='Email'
                  sx={{ color: "#005EB8", width: '90%' }}
                  id="email"
                  {...register("email", {
                    required: "Please enter a value",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    }
                  }
                  )}
                  error={!!errors?.email}
                  helperText={errors?.email?.message?.toString()}

                />
              </Grid>

              <Grid className="marginTop" >

                <FormControl variant="standard" className="formElement">

                  <Controller
                    control={control}
                    defaultValue={"" as any}
                    {...register(`countryCode` as const, {
                      required: "Please select a option"
                    })}
                    render={({ field: { ref, onChange, value } }) => (
                      <Autocomplete
                        onChange={(event: any, item: any) => {
                          onChange(item ? item?.dialCode : null);
                        }}
                        value={
                          value
                            ? countryList?.find((country: any) => {
                              return value === country.dialCode

                            }) ?? null
                            : null
                        }
                        options={countryList}
                        getOptionLabel={(item: any) => (item.dialCode ? item.dialCode + " " + item.name : "")}

                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            value={params.value}
                            label="Country Code"
                            name="countryCode"
                            margin="normal"
                            variant="standard"
                            sx={{ marginTop: '3px' }}
                            inputRef={ref}
                            error={!!errors.countryCode}
                            helperText={errors.countryCode?.message}
                          />
                        )}
                        selectOnFocus={false}
                      />
                    )}
                  />
                </FormControl>


              </Grid>
              <Grid className="marginTop" >
                <TextField
                  variant="standard"
                  type="number"
                  defaultValue=""
                  //InputLabelProps={{ shrink: true }}
                  label='Mobile '
                  sx={{ color: "#005EB8", width: '90%' }}
                  id="mobileNo"
                  {...register("mobileNo", {
                    required: "Please enter a value",
                    maxLength: {
                      value: 10,
                      message: "You have exceeded the character limit."
                    }

                  }
                  )}
                  error={!!errors?.mobileNo}
                  helperText={errors?.mobileNo?.message?.toString()}

                />

              </Grid>

              <Grid container className="padding-top" >
                <Grid item xs={12}>
                  <FormControl variant="standard" className="formElement">
                    <InputLabel id="Title-label" className='formLabel'>Channel</InputLabel>
                    <Controller
                      control={control}
                      defaultValue=""
                      //InputLabelProps={{ shrink: true }}
                      {...register(`channelId`, {
                        required: "Please select option"
                      })}
                      render={({ field }) => {
                        const { ref } = field;
                        return (<Select
                          sx={{ textAlign: "left" }}
                          variant="standard"
                          id="title"
                          label="Please select a option"

                          error={!!errors?.channelId}
                          {...field}
                          inputRef={ref}
                        >
                          {(channel.map((item: any) => {
                            return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                          }))}



                        </Select>)
                      }}

                    />
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.channelId?.message?.toString()}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid sx={{ textAlign: 'left', marginTop: '30px' }}  >
                <InputLabel id="priorityNearTermAgainstLongTerm-label" className='formElement' sx={{ color: '#fff', position: 'relative', fontSize: 16, paddingLeft: 3 }}>Role</InputLabel>
                <Controller
                  {...register(`levelCode`, {
                    required: 'Please select an option'
                  })}
                  defaultValue={'' as any}
                  control={control}
                  render={({ field }) => (
                    <FormControl   >

                      <RadioGroup
                        {...field}
                        row

                        //defaultValue='L7'
                        // defaultChecked={true}
                        onChange={(event, value) => field.onChange(value)}
                        value={field.value}

                        sx={{ paddingLeft: 3, paddingTop: 2 }}
                      >
                        <FormControlLabel sx={{ color: '#005EB8 !important' }} id="r1" value="L7" control={<Radio />} label="Advisor" />
                        <FormControlLabel value="L5" sx={{ color: '#005EB8 !important' }} control={<Radio />} id="r2" label="Manager" />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <FormHelperText sx={{ color: '#ea635c', display: 'block', paddingLeft: 3 }}>{errors?.levelCode?.message?.toString()}</FormHelperText>
              </Grid>
              {(hideManager && (advisorManagerList?.length > 0)) && (<Grid container className="padding-top" >
                <Grid item xs={12}>
                  <FormControl variant="standard" className="formElement">
                    <InputLabel id="Title-label" className='formLabel'>Manager</InputLabel>
                    <Controller
                      control={control}
                      defaultValue=""
                      {...register(`supervisorId`
                      )}
                      render={({ field }) => {
                        const { ref } = field;
                        return (<Select
                          sx={{ textAlign: "left" }}
                          variant="standard"
                          id="title"
                          // InputLabelProps={{ shrink: true }}
                          label="Please select a option"
                          //error={!!errors?.title}
                          {...field}
                          inputRef={ref}
                        >
                          {(advisorManagerList?.map((item: any) => {
                            return (<MenuItem value={item?.id}>{item?.firstName.concat(' ').concat(item.lastName)}</MenuItem>)
                          }))}
                        </Select>)
                      }} />
                    <FormHelperText sx={{ color: '#ea635c' }}></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>)}
              <Grid className="marginTop">
                <a onClick={() => navigate('/login')} >
                  <Typography color="textPrimary" sx={{ padding: '0 16px', textDecorationLine: 'underline', fontSize: 12, textAlign: 'end', cursor: 'grab' }}>Back to sign in?</Typography>
                </a>
              </Grid>
              <Grid className="marginTop">

                <Button variant="contained" sx={{ height: 50, width: 200 }}
                  type="submit" onClick={handleSubmit((data) => {
                    _onSubmit(data);
                  }, (errors) => {

                    //_onError();
                  })}
                >Registration</Button>
              </Grid>
              <Grid className="marginTop" sx={{ display: 'inline-flex' }}>
                <Typography color="textPrimary" sx={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>Use of this platform is subject to Zurich's </Typography>

                <Typography sx={{ textDecorationLine: 'underline', fontSize: 12, color: "#005EB8", whiteSpace: 'pre-wrap', cursor: 'grab' }}> Privacy Policy </Typography>
                <Typography color="textPrimary" sx={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>and </Typography>
                <Typography sx={{ textDecorationLine: 'underline', fontSize: 12, color: "#005EB8", whiteSpace: 'pre-wrap', cursor: 'grab' }}>Terms of Use.</Typography>
              </Grid>
            </FormControl>
          </Grid>
        </form></Grid>
      <DevTool control={control} />
      <ToastContainer />
    </>
  );
}
export default Registration;
