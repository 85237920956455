import { fabClasses } from '@mui/material'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from './store'


import axios from 'axios'
const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const initialState = {
  listTiemlyRecords: [],
  fetchCustomersSelectExistingList: [],
  fetchAnnualReviewCustomersSelectExistingList: [],
  allCustomerDetails: [],
  annualReviewCustomerList: [],
  annualReviewCustomerCloseList: [],
  portFolioGrowthRate: {},
  customerList: [],
  closeSessionCustomerList: [],
  customerDetails: {},
  relationsList: [],
  residentialCountry: '',
  incomeSourceList: [],
  incomeExpenseList: [],
  incomeSavingsList: [],
  countryCodeList: [],
  currentCountryRate: [],
  livingLongCountryRates: [],
  countryDialCodeList: [],
  settingsTable: {
    Name: false,
    Status: false,
    NotesComments: false,
    Age: false,
    Gender: false,
    DateOfBirth: false,
    MobileNo: false,
    EmailAddress: false,
    SessionId: true,
    ClientId: true,
    LastEdited: false,
    Owner: false
  },
  settingsCloseSessionTable: {
    Name: false,
    Status: false,
    NotesComments: false,
    Age: false,
    Gender: false,
    DateOfBirth: false,
    MobileNo: false,
    EmailAddress: false,
    SessionId: true,
    ClientId: true,
    LastEdited: false,
    Owner: false
  },
  settingsAnnualReviewCloseSessionTable: {
    Name: false,
    Status: false,
    NotesComments: false,
    Age: false,
    Gender: false,
    DateOfBirth: false,
    MobileNo: false,
    EmailAddress: false,
    SessionId: true,
    ClientId: true,
    LastEdited: false,
    Owner: false
  },
  settingsAnnualReviewTable: {
    Name: false,
    Status: false,
    NotesComments: false,
    Age: false,
    Gender: false,
    DateOfBirth: false,
    MobileNo: false,
    EmailAddress: false,
    SessionId: true,
    ClientId: true,
    LastEdited: false,
    Owner: false
  },
  countryRate: [],
  appConstant: [],
  applicationStatus: [],
  applicationStatusAnnualReview: [],
  status: 'idle',
  saveStatusPayload: {
    sessionId: '',
    status: '',
    notes: ''
  },
  statusSaved: '',
  error: false,
  rateVisecountryList: {},
  dashboardDetails: {},
  agenciesList: []
}

export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions?state=OPEN', requestOptions).then((response) => response.json())
})

export const fetchDashboardAPI = createAsyncThunk('customers/fetchDashboardAPI', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/users/dashboard', requestOptions).then((response) => response.json())
})

export const fetchAnnualReviewCustomers = createAsyncThunk('customers/fetchAnnualReviewCustomers', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions?state=ANNUAL_REVIEW_OPEN', requestOptions).then((response) => response.json())
})
export const fetchAnnualReviewCustomersClose = createAsyncThunk('customers/fetchAnnualReviewCustomersClose', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions?state=ANNUAL_REVIEW_CLOSED', requestOptions).then((response) => response.json())
})
export const fetchAnnualReviewCustomersSelectExisting = createAsyncThunk('customers/fetchAnnualReviewCustomersSelectExisting', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions/existing?forSessionAnnualReview=true', requestOptions).then((response) => response.json())
})
export const fetchCustomersSelectExisting = createAsyncThunk('customers/fetchCustomersSelectExisting', async (searchTerm: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions/existing?forSessionAnnualReview=false&searchTerm=' + searchTerm, requestOptions).then((response) => response.json())
})
export const fetchCustomersCloseSession = createAsyncThunk('customers/fetchCustomersCloseSession', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions?state=CLOSED', requestOptions).then((response) => response.json())
})
export const fetchRelations = createAsyncThunk('customers/fetchRelations', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/relations', requestOptions).then((response) => response.json())
})
export const fetchIncomeSource = createAsyncThunk('customers/fetchIncomeSource', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/income-sources', requestOptions).then((response) => response.json())
})
export const fetchIncomeExpense = createAsyncThunk('customers/fetchIncomeExpense', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/income-expenses', requestOptions).then((response) => response.json())
})

export const fetchIncomeSavings = createAsyncThunk('customers/fetchIncomeSavings', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/income-saving-options', requestOptions).then((response) => response.json())
})

export const fetchCountry = createAsyncThunk('customers/fetchCountry', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/countries', requestOptions).then((response) => response.json())
})

export const fetchCountryDialCode = createAsyncThunk('customers/fetchCountryDialCode', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/countries?type=dial_code', requestOptions).then((response) => response.json())
})
export const fetchRateVisecountryList = createAsyncThunk('customers/fetchRateVisecountryList', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/countries?type=is_dts_country', requestOptions).then((response) => response.json())
})

export const fetchCountryRate = createAsyncThunk('customers/fetchCountryRate', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/country-rates', requestOptions).then((response) => response.json())
})
export const fetcAppConstant = createAsyncThunk('customers/fetcAppConstant', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/generic/app-constants', requestOptions).then((response) => response.json())
})
/* save Status Dashboared details */
export const saveStatus = createAsyncThunk('customer/saveStatus', async (statusDetails: any) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  const response = await axios.post(baseUrl + '/sessions/status', statusDetails, requestOptions).then((response) => response.data)
  return response;
})


/* get gap analysis */
export const fetchGapAnalysis = createAsyncThunk('customers/fetchGapAnalysis', async (id) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await axios.get(baseUrl + '/calculate/gap-analysis?id=' + id, requestOptions).then((response) => response.data)
})
/* get portFolioGrowthRate */
export const fetchPortFolioGrowthRate = createAsyncThunk('customers/portFolioGrowthRate', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await axios.get(baseUrl + '/generic/portfolio-growth-rates', requestOptions).then((response) => response.data)
})
export const setConsent = createAsyncThunk('customer/setConsent', async (details) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'POST',
    headers: headers,
  };
  const response = await axios.post(baseUrl + '/customers/consent', details, requestOptions)
    .then((response) => response.data)
  return response;
})
/* get timely records for Head */
export const fetchTiemlyRecords = createAsyncThunk('customers/fetchTiemlyRecords', async (action: string) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  return await window.fetch(baseUrl + '/sessions/sales-head?dateRange=' + action, requestOptions).then((response) => response.json())
})

/* get agencies records */
export const fetchAgencies = createAsyncThunk('customers/fetchAgencies', async () => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('bearerToken')}`,
  };
  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  const detailsObj = localStorage.getItem('userDetails')
  const parseDetails: any = detailsObj && JSON.parse(detailsObj);
  let zioId = parseDetails?.zioId;
  let email = parseDetails?.email;

  return await window.fetch(baseUrl + '/users/agencies?zioId=' + zioId + '&email=' + email, requestOptions).then((response) => response.json())
})
const GenericDetailsSlice = createSlice({
  name: "GenericDetails",
  initialState,
  reducers: {
    setTableCustomisation: (state, action) => {
      state.settingsTable = { ...state.settingsTable, [action.payload.name]: action.payload.value }
    },
    setCloseSessionTableCustomisation: (state, action) => {
      state.settingsCloseSessionTable = { ...state.settingsCloseSessionTable, [action.payload.name]: action.payload.value }
    },
    setAnnualReviewTableCustomisation: (state, action) => {
      state.settingsAnnualReviewTable = { ...state.settingsAnnualReviewTable, [action.payload.name]: action.payload.value }
    },
    setAnnualReviewCloseSessionTableCustomisation: (state, action) => {
      state.settingsAnnualReviewCloseSessionTable = { ...state.settingsAnnualReviewCloseSessionTable, [action.payload.name]: action.payload.value }
    },
    setRedisentialCountry: (state, action) => {
      state.residentialCountry = action.payload
    },
    setCurrentCountryRate: (state, action) => {
      let currentCountry = action.payload.value;
      let countryRateDetailsObj = state.countryRate.filter((element: any) => element.countryCode === currentCountry)
      state.currentCountryRate = countryRateDetailsObj
    },
    setCurrentLivingLongCountryRate: (state, action) => {
      let currentCountry = action.payload.value;
      let countryRateDetailsObj = state.countryRate.filter((element: any) => element.countryCode === currentCountry)
      state.livingLongCountryRates = countryRateDetailsObj
    },
    setSaveStatusPayload: (state, action) => {
      let { name, value, sessionId } = action.payload
      if (sessionId) {
        state.saveStatusPayload = { ...state.saveStatusPayload, [name]: value, 'sessionId': sessionId }
      }
      else {
        state.saveStatusPayload = { ...state.saveStatusPayload, [name]: value }
      }
      state.statusSaved = 'pending'
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchRelations.fulfilled, (state, action) => {
      state.relationsList = action.payload
      state.status = 'fulfilled'
      state.error = false
    })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.customerList = action.payload
      })
      .addCase(fetchCustomersCloseSession.fulfilled, (state, action) => {
        state.closeSessionCustomerList = action.payload
      })
      .addCase(fetchAnnualReviewCustomers.fulfilled, (state, action) => {
        state.annualReviewCustomerList = action.payload
      })
      .addCase(fetchAnnualReviewCustomersClose.fulfilled, (state, action) => {
        state.annualReviewCustomerCloseList = action.payload
      })
      .addCase(fetchIncomeSource.fulfilled, (state, action) => {
        state.incomeSourceList = action.payload
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetchIncomeExpense.fulfilled, (state, action) => {
        state.incomeExpenseList = action.payload
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetchIncomeSavings.fulfilled, (state, action) => {
        state.incomeSavingsList = action.payload
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetchCountryDialCode.fulfilled, (state, action) => {
        state.countryDialCodeList = action.payload
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.countryCodeList = action.payload
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetchCountryRate.fulfilled, (state, action) => {
        state.countryRate = action.payload
        /*let resident_country = sessionStorage.getItem('country');
        let countryRateDetailsObj = state.countryRate.filter((element) => element.country_code === resident_country)
        state.currentCountryRate = countryRateDetailsObj*/
        state.status = 'fulfilled'
        state.error = false
      })
      .addCase(fetcAppConstant.fulfilled, (state, action) => {
        state.appConstant = action.payload
        state.applicationStatus = action.payload.filter((element: any) => element.type === 'application-progress')
        state.applicationStatusAnnualReview = action.payload.filter((element: any) => element.type === 'application-progress-annual-review')
      })
      .addCase(saveStatus.fulfilled, (state, action) => {
        state.statusSaved = 'fulfiled'
      })
      .addCase(fetchPortFolioGrowthRate.fulfilled, (state, action) => {
        state.portFolioGrowthRate = action.payload
      })
      .addCase(fetchRateVisecountryList.fulfilled, (state, action) => {
        state.rateVisecountryList = action.payload?.filter((element: any) => element.isDtsCountry === true)
      })
      .addCase(fetchDashboardAPI.fulfilled, (state, action) => {
        state.dashboardDetails = action.payload
      })
      .addCase(fetchAnnualReviewCustomersSelectExisting.fulfilled, (state, action) => {
        state.fetchAnnualReviewCustomersSelectExistingList = action.payload
      })
      .addCase(fetchCustomersSelectExisting.fulfilled, (state, action) => {
        state.fetchCustomersSelectExistingList = action.payload
      })
      .addCase(fetchTiemlyRecords.fulfilled, (state, action) => {
        state.listTiemlyRecords = action.payload
      })
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.agenciesList = action.payload.agent
      })

  },
})

export const getAllCustomers = (state: RootState) => state.genericDetails.customerList;
export const getStatus = (state: RootState) => state.genericDetails.status
export const getRelationsList = (state: RootState) => state.genericDetails.relationsList;
export const getIncomeSourceList = (state: RootState) => state.genericDetails.incomeSourceList;
export const getIncomeExpenseList = (state: RootState) => state.genericDetails.incomeExpenseList;
export const getIncomeSavingsList = (state: RootState) => state.genericDetails.incomeSavingsList;
export const getCountryDialCodeList = (state: RootState) => state.genericDetails.countryDialCodeList;
export const getCountryCodeList = (state: RootState) => state.genericDetails.countryCodeList;
export const getTableSettings = (state: RootState) => state.genericDetails.settingsTable;
export const { setAnnualReviewTableCustomisation, setAnnualReviewCloseSessionTableCustomisation, setTableCustomisation, setCloseSessionTableCustomisation, setRedisentialCountry, setSaveStatusPayload, setCurrentCountryRate, setCurrentLivingLongCountryRate } = GenericDetailsSlice.actions
export default GenericDetailsSlice.reducer
