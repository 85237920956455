import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs';
import { DataGrid } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import { useTranslation } from 'react-i18next';

const SelectExistingAnnualReview = ({ setCurrentPage }: any) => {
  const { t, i18n } = useTranslation();
  let customerDetailsList = useSelector((state: any) => state.genericDetails?.fetchAnnualReviewCustomersSelectExistingList)
  const [tableFilter, setTableFilter] = React.useState<any>('')
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [isError, setIsError] = React.useState<boolean>(true)
  const [idValue, setIdValue] = React.useState<string>()
  let customerDetailsObj = []
  const excludeColumns = ["name", "email", "customerId"];
  if (tableFilter) {
    customerDetailsObj = customerDetailsList?.filter((item: any) => {
      return Object.keys(item).some((key: any) =>
        excludeColumns.includes(key) ?
          item[key]?.toString().toLowerCase().includes(tableFilter.toLowerCase()) : false);
    });
  } else {
    customerDetailsObj = customerDetailsList;
  }
  const createData = (
    test: any,
    name: string,
    id: any,
    sessionId: string
  ) => {
    return { test, name, id, sessionId };
  }
  const rows = customerDetailsObj.map((element: any) => {
    return createData("", element?.firstName + " " + element?.lastName, element.customerId, element.id)
  })

  const renderRadio = (params: any) => {
    return <Radio checked={params?.row?.id === idValue} name={params?.row?.id} value={params?.row?.checked} onClick={(e) => renderRadioCell(params?.row?.id, e)} />;
  }

  const columns = [
    { field: 'test', headerName: '', renderCell: renderRadio, sortable: false, disableColumnMenu: true, fontWeight: 600, color: 'rgb(62, 79, 115)', },
    { field: 'name', headerName: "Name", sortable: false, disableColumnMenu: true, width: 300 },
    { field: 'id', headerName: 'ID', sortable: false, disableColumnMenu: true, width: 300 },
    { field: 'sessionId', headerName: 'sessionId', sortable: false, disableColumnMenu: true, width: 300 },

  ];
  const renderRadioCell = (id: string, e: any) => {
    setIdValue(id);

    setIsError(false);
  }

  const formatedDate = (value: any) => {
    const date = (value === '' || value === undefined) ? new Date() : new Date(value);
    const newDate = date.setFullYear(date.getFullYear());
    var formatedDate = new Date(newDate).toISOString()
    return dayjs(formatedDate);
  }
  //let radioChecked = [rows[0]?.id];

  const [selectionModel, setSelectionModel] = React.useState(idValue);
  const selectedRow = rows.filter((item: any) => {
    return item.id === idValue
  });
  let radioChecked = [selectedRow[0]?.id];
  let radioCheckedSessionId = [selectedRow[0]?.sessionId];
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  let navigate = useNavigate();
  const _navigateToSoluation = () => {
    if (radioChecked !== undefined) {
      createExistingSession({ customerId: radioChecked[0], sessionId: radioCheckedSessionId?.length > 0 ? radioCheckedSessionId[0] : '' })
    }
  }
  const createExistingSession = async (userDetails: {}) => {
    const response = await axios.post(baseUrl + '/customers/create-annual-review-session', userDetails, requestOptions)
      .then((response) => {
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        let closedSession = false;
        let annualReviewSession = true;
        const sessionId = response?.data?.sessionId
        sessionStorage.setItem('sessiondetail', sessionId)
        sessionStorage.setItem('customerdetail', response?.data?.customerId)
        sessionStorage.setItem('closedSession', closedSession?.toString())
        navigate('/solution', { replace: true, state: { closedSession, annualReviewSession } })

      }).catch(error => {
        //_setPageID(2)
        toast.error('There is already an open session with this client. Please continue with that current open session.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  const getFilterRecords = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target
    setTableFilter(value)
  }
  return (
    <>
      <Grid container sx={{ padding: '15px 15px' }}>
        <Grid xs={12} sx={{ padding: '0' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="smallFont" underline="hover" color="inherit" href="/dashboard">
              {t("Dashboard")}
            </Link>
            <Link className="smallFont" underline="hover" color="inherit" onClick={() => setCurrentPage(2)}>
              {t("openSessions")}
            </Link>
            <Link className="smallFont" underline="hover" color="inherit" onClick={() => setCurrentPage(7)}>{t("AnnualReviewOpenSessions")}</Link>
            <Typography className="smallFont" color="text.primary">{t("SelectExistingAnunalReviewSession")}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid xs={12} md={12} sx={{ textAlign: 'right', padding: '15px 0', float: 'right' }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            sx={{ height: '35px !important', padding: '0' }}
            placeholder='Search'
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => getFilterRecords(e)}
            endAdornment={<InputAdornment position="end">
              <Button sx={{ fontSize: 'medium', background: '#005EB8', height: '35px' }} variant="contained" endIcon={<SearchIcon />}></Button></InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'left' }}><Typography className="secoundaryFont primary-color bold-font"> {t("CreateAnnualReviewSession")}</Typography></Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}> <Button className='primary-color createSessionButton' variant="outlined" sx={{ width: '98%', marginTop: '10px' }}>{t("SelectExistingSession")}</Button></Grid>
        <Grid item xs={12} sx={{ marginTop: '35px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={(newSelectionModel: any) => {
              setIdValue(newSelectionModel[0])
              setSelectionModel(newSelectionModel);
            }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10 },
              },
            }}
          />
          <Grid item xs={7} className="marginTop" sx={{ textAlign: 'left' }}> <Button className='createSessionButton' variant="outlined" onClick={_navigateToSoluation} sx={{ width: '70%', marginTop: '10px', color: '#fff', background: '#005EB8', padding: '10px 15px' }}> {t("CreateAnnualReviewSession")}</Button></Grid>
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  )
}

export default SelectExistingAnnualReview