import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import m from '@mui/material/TextField';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import summaryImg from "../../Images/Frame.png"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ReactSpeedometer from "react-d3-speedometer"
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import {
  FormHelperText,
  InputLabel,
} from "@mui/material";

type FormValues = {
  sessionId: string,
  customerRiskProfile: {
    investmentYears: number | undefined,
    investmentKnowledge: string,
    investmentObjective: string,
    comfortabilityInShortTermDeclineInInvestment: string,
    rangeOfInvestmentIn5Years: string,
    riskApproach: string,
    otherSavingsInFinancialEmergency: string,
    impactOnPoorInvestment: string,
    riskProfileIndex: number | undefined,
    isDifferentRiskLevel: any,
    choosenRiskAppetite: string,
    isCoApplicantRiskProfileUsed: any
  },
  isApplicationForJointOwnership: any,
  coApplicantRiskProfile?: {
    investmentYears: number | undefined,
    coApplicantId: string,
    investmentKnowledge: string,
    investmentObjective: string,
    comfortabilityInShortTermDeclineInInvestment: string,
    rangeOfInvestmentIn5Years: string,
    riskApproach: string,
    otherSavingsInFinancialEmergency: string,
    impactOnPoorInvestment: string,
    riskProfileIndex: number | undefined,
    isDifferentRiskLevel: any,
    choosenRiskAppetite: string,
  }
}
export const RiskProfiler = ({ setIsFormSubmited, isQuoteGenerated, isClosedSession, _setPageID, _navigateToDashboard, submitRef }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const chartStyle = {
    height: 50,
  }
  const value = 60
  const [details, setDetails] = React.useState<any>()
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [isJointApplicant, setIsJointApplicant] = React.useState(false)
  const countryCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const [financialObjectivSummaryDetails, setSummaryDetails] = React.useState<any>()
  const [RiskIndexDetails, setRiskIndexDetails] = React.useState<any>()
  const [coAppRiskIndexDetails, setCoAppRiskIndexDetails] = React.useState<any>()
  const [riskIndexDescription, setRiskIndexDescription] = React.useState<any>()
  const [coAppriskIndexDescription, setCoAppRiskIndexDescription] = React.useState<any>()
  const [mainApplicantRiskProfileError, setMainApplicantRiskProfileError] = React.useState(false)
  const [mainApplicantSummaryError, setMainApplicantSummaryError] = React.useState(false)
  const [jointApplicantRiskProfileError, setJointApplicantRiskProfileError] = React.useState(false)
  const [jointApplicantSummaryError, setJointApplicantSummaryError] = React.useState(false)
  const _getRadioButtonValue = (flag: boolean) => {
    if (flag && flag === true) {
      return 'yes'
    } else if (flag === false || flag === 'false') {
      return 'no'
    } else {
      return 'no'
    }
  }
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const responseDetails = await response.json();
      const data = responseDetails.riskProfile
      setDetails(responseDetails);
      (responseDetails?.personalDetails?.coApplicant !== null) ? setIsJointApplicant(true) : setIsJointApplicant(false)
      setShowSpinner(false)
      responseDetails?.riskProfile?.customerRiskProfile?.riskProfileIndex ? setRiskIndexDetails(responseDetails?.riskProfile?.customerRiskProfile?.riskProfileIndex) : setRiskIndexDetails(1);
      responseDetails?.riskProfile?.coApplicantRiskProfile?.riskProfileIndex ? setCoAppRiskIndexDetails(responseDetails?.riskProfile?.coApplicantRiskProfile?.riskProfileIndex) : setCoAppRiskIndexDetails(1);
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        "customerRiskProfile": {
          "investmentYears": data?.customerRiskProfile?.investmentYears || undefined,
          "investmentKnowledge": data?.customerRiskProfile?.investmentKnowledge || "",
          "investmentObjective": data?.customerRiskProfile?.investmentObjective || "",
          "comfortabilityInShortTermDeclineInInvestment": data?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment || "",
          "rangeOfInvestmentIn5Years": data?.customerRiskProfile?.rangeOfInvestmentIn5Years || "",
          "riskApproach": data?.customerRiskProfile?.riskApproach || "",
          "otherSavingsInFinancialEmergency": data?.customerRiskProfile?.otherSavingsInFinancialEmergency || "",
          "impactOnPoorInvestment": data?.customerRiskProfile?.impactOnPoorInvestment || "",
          "riskProfileIndex": data?.customerRiskProfile?.riskProfileIndex || undefined,
          "isDifferentRiskLevel": data?.customerRiskProfile?.isDifferentRiskLevel,
          "choosenRiskAppetite": data?.customerRiskProfile?.choosenRiskAppetite || "",
          "isCoApplicantRiskProfileUsed": data?.customerRiskProfile?.isCoApplicantRiskProfileUsed === true ? 'yes' : 'no'
        },
        "isApplicationForJointOwnership": data?.isApplicationForJointOwnership === true ? 'yes' : 'no',
        "coApplicantRiskProfile": {
          "investmentYears": data?.coApplicantRiskProfile?.investmentYears || undefined,
          "coApplicantId": responseDetails?.personalDetails?.coApplicant?.id || '',
          "investmentKnowledge": data?.coApplicantRiskProfile?.investmentKnowledge || "",
          "investmentObjective": data?.coApplicantRiskProfile?.investmentObjective || "",
          "comfortabilityInShortTermDeclineInInvestment": data?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment || "",
          "rangeOfInvestmentIn5Years": data?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years || "",
          "riskApproach": data?.coApplicantRiskProfile?.riskApproach || "",
          "otherSavingsInFinancialEmergency": data?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency || "",
          "impactOnPoorInvestment": data?.coApplicantRiskProfile?.impactOnPoorInvestment || "",
          "riskProfileIndex": data?.coApplicantRiskProfile?.riskProfileIndex || undefined,
          "isDifferentRiskLevel": data?.coApplicantRiskProfile?.isDifferentRiskLevel,
          "choosenRiskAppetite": data?.coApplicantRiskProfile?.choosenRiskAppetite || ""
        }
      }
    },
    mode: 'all'
  })

  const { register, control, handleSubmit, formState: { errors, touchedFields, dirtyFields }, watch, setValue } = form
  
  let riskDetails = watch()
  /*submit code start */
  const _onSubmit = (data: FormValues, action: any) => {
    let reponse = { ...data }
    if (reponse.coApplicantRiskProfile !== undefined) {
      reponse.coApplicantRiskProfile.isDifferentRiskLevel = (data?.coApplicantRiskProfile?.isDifferentRiskLevel === 'true' || data?.coApplicantRiskProfile?.isDifferentRiskLevel === true) ? true : false
      reponse.coApplicantRiskProfile.choosenRiskAppetite = (data?.coApplicantRiskProfile?.isDifferentRiskLevel === 'true' || data?.coApplicantRiskProfile?.isDifferentRiskLevel === true) ? reponse.coApplicantRiskProfile.choosenRiskAppetite : ''
    }
    if (data?.isApplicationForJointOwnership === 'no') {
      delete reponse.coApplicantRiskProfile
    }
    reponse.customerRiskProfile.isCoApplicantRiskProfileUsed = data?.customerRiskProfile?.isCoApplicantRiskProfileUsed === 'yes' ? true : false
    reponse.isApplicationForJointOwnership = data?.isApplicationForJointOwnership === 'yes' ? true : false
    reponse.customerRiskProfile.isDifferentRiskLevel = (data?.customerRiskProfile?.isDifferentRiskLevel === 'true' || data?.customerRiskProfile?.isDifferentRiskLevel === true) ? true : false
    reponse.customerRiskProfile.choosenRiskAppetite = (data?.customerRiskProfile?.isDifferentRiskLevel === 'true' || data?.customerRiskProfile?.isDifferentRiskLevel === true) ? reponse.customerRiskProfile.choosenRiskAppetite : ''
    setRiskProfiler(reponse, action)
  }

  React.useEffect(() => {
    let obj = riskDetails
    if (RiskIndexDetails === 3) {
      setRiskIndexDescription('Balanced')
    } else if (RiskIndexDetails === 4) {
      setRiskIndexDescription('Performance Driven')
    }
    else if (RiskIndexDetails === 2) {
      setRiskIndexDescription('Cautious')
    }
    else if (RiskIndexDetails === 1) {
      setRiskIndexDescription('Defensive')
    }
    else if (RiskIndexDetails === 5) {
      setRiskIndexDescription('Adventurous')
    }
    else {
      setRiskIndexDescription('Performance Driven')
    }

    //  dispatch(setRiskProfiler({obj,isCoApplicant}))
  }, [RiskIndexDetails])


  useEffect(() => {
    let obj = coAppRiskIndexDetails
    if (coAppRiskIndexDetails === 3) {
      setCoAppRiskIndexDescription('Balanced')
    } else if (coAppRiskIndexDetails === 4) {
      setCoAppRiskIndexDescription('Performance Driven')
    }
    else if (coAppRiskIndexDetails === 2) {
      setCoAppRiskIndexDescription('Cautious')
    }
    else if (coAppRiskIndexDetails === 1) {
      setCoAppRiskIndexDescription('Defensive')
    }
    else if (coAppRiskIndexDetails === 5) {
      setCoAppRiskIndexDescription('Adventurous')
    }
    else {
      setCoAppRiskIndexDescription('Performance Driven')
    }
  }, [coAppRiskIndexDetails])


  const setRiskProfiler = async (details: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/risk-profile', details, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        if (action === 'next') {
          _setPageID(6)
        } else if (action === 'prev') {
          _setPageID(4)
        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
      }).catch((error: any) => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          _onError();
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }
  /* submit code end */
  useEffect(() => {
    if (touchedFields?.customerRiskProfile?.investmentYears || touchedFields?.customerRiskProfile?.investmentKnowledge || touchedFields?.customerRiskProfile?.investmentObjective || touchedFields?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment || touchedFields?.customerRiskProfile?.rangeOfInvestmentIn5Years || touchedFields?.customerRiskProfile?.riskApproach || touchedFields?.customerRiskProfile?.otherSavingsInFinancialEmergency || touchedFields?.customerRiskProfile?.impactOnPoorInvestment) {
      if (riskDetails?.customerRiskProfile?.investmentYears && riskDetails?.customerRiskProfile?.investmentKnowledge && riskDetails?.customerRiskProfile?.investmentObjective && riskDetails?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment && riskDetails?.customerRiskProfile?.rangeOfInvestmentIn5Years && riskDetails?.customerRiskProfile?.riskApproach && riskDetails?.customerRiskProfile?.otherSavingsInFinancialEmergency && riskDetails?.customerRiskProfile?.impactOnPoorInvestment) {
        let obj = {
          "sessionId": sessionStorage.getItem('sessiondetail'),
          "isCoApplicant": false,
          "riskFactors": {
            "duration": riskDetails?.customerRiskProfile?.investmentYears,
            "alphaLevel": riskDetails?.customerRiskProfile?.investmentObjective,
            "betaLevel": riskDetails?.customerRiskProfile?.investmentKnowledge,
            "charlieLevel": riskDetails?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment,
            "gammaLevel": riskDetails?.customerRiskProfile?.rangeOfInvestmentIn5Years,
            "epsilonLevel": riskDetails?.customerRiskProfile?.riskApproach,
            "omegaLevel": riskDetails?.customerRiskProfile?.otherSavingsInFinancialEmergency,
            "deltaLevel": riskDetails?.customerRiskProfile?.impactOnPoorInvestment,
          }
        }
        calculateRiskIndex(obj, 'main')
      }
    }

  }, [riskDetails?.customerRiskProfile?.investmentYears, riskDetails?.customerRiskProfile?.investmentKnowledge, riskDetails?.customerRiskProfile?.investmentObjective, riskDetails?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment, riskDetails?.customerRiskProfile?.rangeOfInvestmentIn5Years, riskDetails?.customerRiskProfile?.riskApproach, riskDetails?.customerRiskProfile?.otherSavingsInFinancialEmergency, riskDetails?.customerRiskProfile?.impactOnPoorInvestment])

  useEffect(() => {
    if (touchedFields?.coApplicantRiskProfile?.investmentYears || touchedFields?.coApplicantRiskProfile?.investmentKnowledge || touchedFields?.coApplicantRiskProfile?.investmentObjective || touchedFields?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment || touchedFields?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years || touchedFields?.coApplicantRiskProfile?.riskApproach || touchedFields?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency || touchedFields?.coApplicantRiskProfile?.impactOnPoorInvestment) {
      if (riskDetails?.coApplicantRiskProfile?.investmentYears && riskDetails?.coApplicantRiskProfile?.investmentKnowledge && riskDetails?.coApplicantRiskProfile?.investmentObjective && riskDetails?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment && riskDetails?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years && riskDetails?.coApplicantRiskProfile?.riskApproach && riskDetails?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency && riskDetails?.coApplicantRiskProfile?.impactOnPoorInvestment) {
        let obj = {
          "sessionId": sessionStorage.getItem('sessiondetail'),
          "customerId": details?.personalDetails?.coApplicant?.id,
          "isCoApplicant": true,
          "riskFactors": {
            "duration": riskDetails?.coApplicantRiskProfile?.investmentYears,
            "alphaLevel": riskDetails?.coApplicantRiskProfile?.investmentObjective,
            "betaLevel": riskDetails?.coApplicantRiskProfile?.investmentKnowledge,
            "charlieLevel": riskDetails?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment,
            "gammaLevel": riskDetails?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years,
            "epsilonLevel": riskDetails?.coApplicantRiskProfile?.riskApproach,
            "omegaLevel": riskDetails?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency,
            "deltaLevel": riskDetails?.coApplicantRiskProfile?.impactOnPoorInvestment
          }
        }
        calculateRiskIndex(obj, 'secound')
      }
    }

  }, [riskDetails?.coApplicantRiskProfile?.investmentYears, riskDetails?.coApplicantRiskProfile?.investmentKnowledge, riskDetails?.coApplicantRiskProfile?.investmentObjective, riskDetails?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment, riskDetails?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years, riskDetails?.coApplicantRiskProfile?.riskApproach, riskDetails?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency, riskDetails?.coApplicantRiskProfile?.impactOnPoorInvestment])
  /* Risk Index calculator */
  const calculateRiskIndex = async (riskProfilerDetails: any, applicant: string) => {
    const response = await axios.post(baseUrl + '/calculate/risk-index', riskProfilerDetails, requestOptions).then((response) => response.data).catch((error: any) => {
      if (error.response.status === 401) {
        navigate('/login')
      } else {
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    })
    if (applicant === 'main') {
      setRiskIndexDetails(response?.riskIndex);
      setValue(`customerRiskProfile.riskProfileIndex`, response?.riskIndex)
    } else {
      setCoAppRiskIndexDetails(response?.riskIndex);
      setValue(`coApplicantRiskProfile.riskProfileIndex`, response?.riskIndex)
    }
  }
  React.useEffect(() => {
    let status = details?.progress?.riskProfile?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {
    const { customerRiskProfile, coApplicantRiskProfile } = errors

    customerRiskProfile && !(customerRiskProfile.hasOwnProperty('choosenRiskAppetite') || customerRiskProfile.hasOwnProperty('isCoApplicantRiskProfileUsed')) ? setMainApplicantRiskProfileError(true) : setMainApplicantRiskProfileError(false)
    customerRiskProfile && (customerRiskProfile.hasOwnProperty('choosenRiskAppetite') || customerRiskProfile.hasOwnProperty('isCoApplicantRiskProfileUsed')) ? setMainApplicantSummaryError(true) : setMainApplicantSummaryError(false)

    coApplicantRiskProfile && !(coApplicantRiskProfile.hasOwnProperty('choosenRiskAppetite') || coApplicantRiskProfile.hasOwnProperty('isCoApplicantRiskProfileUsed')) ? setJointApplicantRiskProfileError(true) : setJointApplicantRiskProfileError(false)
    coApplicantRiskProfile && (coApplicantRiskProfile.hasOwnProperty('choosenRiskAppetite') || coApplicantRiskProfile.hasOwnProperty('isCoApplicantRiskProfileUsed')) ? setJointApplicantSummaryError(true) : setJointApplicantSummaryError(false)


    //setIsError('#FF7B6F')
  }
  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Box>
        <h3 style={{ color: '#012169', textAlign: 'left', marginTop: "30px", marginBottom: '40px' }}>{t("FinanceAdviceFor")} {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3>
      </Box>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          {/* accordion 1 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: mainApplicantRiskProfileError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("RiskProfilerMainApplicant")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container className="padding-top">
                  <Grid item xs={6}>
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>{t("RiskProfilerPlanInvest")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={"" as any}
                        {...register(`customerRiskProfile.investmentYears` as const, {
                          required: 'Please select an option'
                        })}
                        render={({ field }) => (<Select
                          id="investmentYears"
                          error={!!errors?.customerRiskProfile?.investmentYears}
                          {...field}
                        >
                          <MenuItem value={1} selected>1 year</MenuItem>
                          <MenuItem value={2}>2 years</MenuItem>
                          <MenuItem value={3}>3 years</MenuItem>
                          <MenuItem value={4}>4 years</MenuItem>
                          <MenuItem value={5}>5 years</MenuItem>
                          <MenuItem value={6}>6 years</MenuItem>
                          <MenuItem value={7}>7 years</MenuItem>
                          <MenuItem value={8}>8 years</MenuItem>
                          <MenuItem value={9}>9 years</MenuItem>
                          <MenuItem value={10}>10 years</MenuItem>
                          <MenuItem value={11}>11 years</MenuItem>
                          <MenuItem value={12}>12 years</MenuItem>
                          <MenuItem value={13}>13 years</MenuItem>
                          <MenuItem value={14}>14 years</MenuItem>
                          <MenuItem value={15}>15+ years</MenuItem>
                        </Select>)} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.investmentYears?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>How would you describe your investment knowledge?</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={"" as any}
                        {...register(`customerRiskProfile.investmentKnowledge` as const, {
                          required: 'Please select an option'
                        })}
                        render={({ field }) => (<Select
                          id="investmentKnowledge"
                          error={!!errors?.customerRiskProfile?.investmentKnowledge}
                          {...field}
                        >
                          <MenuItem value='LOW'>Expert: I have a great deal of experience of investments and have in-depth knowledge of how markets work.</MenuItem>
                          <MenuItem value='LOW_MEDIUM'>High: I have experience with investing and understand the various different types of investment categories and risks.</MenuItem>
                          <MenuItem value='MEDIUM'>Moderate: I have some experience in investing and I know the basic types of investments.</MenuItem>
                          <MenuItem value='MEDIUM_HIGH'>Little: I have little knowledge about investments and risks and have very limited experience in investing.</MenuItem>
                          <MenuItem value='HIGH'>None: I have no investment knowledge.</MenuItem>
                        </Select>)} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.investmentKnowledge?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="padding-top">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>{t("RiskProfilerInvestmentObjective")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={"" as any}
                        {...register(`customerRiskProfile.investmentObjective` as const, {
                          required: 'Please select an option'
                        })}
                        render={({ field }) => (<Select
                          id="investmentObjective"
                          error={!!errors?.customerRiskProfile?.investmentObjective}
                          {...field}
                        >
                          <MenuItem value='LOW'>I want to achieve the best possible return and I understand that this can come with the highest risk.</MenuItem>
                          <MenuItem value='LOW_MEDIUM'>I would like high long term growth but will accept lower rate of growth with reduced fluctuations.</MenuItem>
                          <MenuItem value='MEDIUM'>I place equal weight on growth and security.</MenuItem>
                          <MenuItem value='MEDIUM_HIGH'>I aim for a return which beats inflation but do not want to be exposed to large fluctuations in value.</MenuItem>
                          <MenuItem value='HIGH'>My aim is to protect the capital I have invested.</MenuItem>
                        </Select>)} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.investmentObjective?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className="padding-top">
                    <FormControl variant="standard" className="formElement">
                      <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>{t("RiskProfilerShortTermDecline")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={"" as any}
                        {...register(`customerRiskProfile.comfortabilityInShortTermDeclineInInvestment` as const, {
                          required: 'Please select an option'
                        })}
                        render={({ field }) => (<Select
                          id="comfortabilityInShortTermDeclineInInvestment"
                          error={!!errors?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment}
                          {...field}
                        >
                          <MenuItem value='LOW' selected>I would not be concerned.</MenuItem>
                          <MenuItem value='LOW_MEDIUM'>I would only be slightly concerned.</MenuItem>
                          <MenuItem value='MEDIUM'>I would be moderately concerned.</MenuItem>
                          <MenuItem value='MEDIUM_HIGH'>I would be a little uncomfortable.</MenuItem>
                          <MenuItem value='HIGH'>I would be very uncomfortable.</MenuItem>
                        </Select>)} />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.comfortabilityInShortTermDeclineInInvestment?.message}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '5px 25px 0 25px' }}>
                    <InputLabel id="continuation-label" className='primary-color revertTextOverFlow'>{t("RiskProfiler5YearPeriodMessage1")}</InputLabel>
                    <InputLabel className='primary-color'>{t("RiskProfiler5YearPeriodMessage2")}</InputLabel>
                    <Controller
                      {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors?.customerRiskProfile?.rangeOfInvestmentIn5Years}>
                          <RadioGroup
                            {...field}
                            row
                            {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)} value="LOW" control={<CheckRadioButton />} label="$6,300 to $17,200" />
                            <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }} {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="$6,800 to $16,300" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}    {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)} value="MEDIUM" control={<CheckRadioButton />} label="$7,900 to $14,000" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="$8,900 to $12,100" />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.rangeOfInvestmentIn5Years`)} value="HIGH" control={<CheckRadioButton />} label="$9,800 to $11,600" />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.rangeOfInvestmentIn5Years?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '0 25px' }}>
                    <InputLabel className='primary-color' id="continuation-label">{t("RiskProfilerRiskApproach")}</InputLabel>
                    <Controller
                      {...register(`customerRiskProfile.riskApproach`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors?.customerRiskProfile?.riskApproach}>
                          <RadioGroup
                            {...field}
                            {...register(`customerRiskProfile.riskApproach`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.riskApproach`)} value="LOW" control={<CheckRadioButton />} label="be willing to take a lot more risk with all of your money" />
                            <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.riskApproach`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="be willing to take a little more risk with all of your money" />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.riskApproach`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="consider the potential losses and only be willing to take a little more risk with some of your money" />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.riskApproach`)} value="HIGH" control={<CheckRadioButton />} label="consider the potential losses and be unwilling to take any more risk" />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.riskApproach?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '0 25px' }}>
                    <InputLabel className='primary-color' id="continuation-label">{t("RiskProfilerFinancialEmergency")}</InputLabel>
                    <Controller
                      {...register(`customerRiskProfile.otherSavingsInFinancialEmergency`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors?.customerRiskProfile?.otherSavingsInFinancialEmergency}>
                          <RadioGroup
                            {...field}
                            {...register(`customerRiskProfile.otherSavingsInFinancialEmergency`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.otherSavingsInFinancialEmergency`)} value="LOW" control={<CheckRadioButton />} label="No. I have no other savings I can use in the event of an emergency." />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.otherSavingsInFinancialEmergency`)} value="MEDIUM" control={<CheckRadioButton />} label="I have some other savings, but nothing specifically for a financial emergency." />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.otherSavingsInFinancialEmergency`)} value="HIGH" control={<CheckRadioButton />} label="Yes. I have specific savings that I can use in an emergency." />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.otherSavingsInFinancialEmergency?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '0 25px' }}>
                    <InputLabel className='primary-color' id="continuation-label">{t("RiskProfilerFinancialWell")}</InputLabel>
                    <Controller
                      {...register(`customerRiskProfile.impactOnPoorInvestment`, {
                        required: 'Please select an option'
                      })}
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors?.customerRiskProfile?.impactOnPoorInvestment}>
                          <RadioGroup
                            {...field}
                            {...register(`customerRiskProfile.impactOnPoorInvestment`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`customerRiskProfile.impactOnPoorInvestment`)} value="LOW" control={<CheckRadioButton />} label="It would have a big impact. This investment is required for a specific purpose." />
                            <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.impactOnPoorInvestment`)} value="MEDIUM" control={<CheckRadioButton />} label="It would have some impact. I would need to make some adjustments to my future plans." />
                            <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`customerRiskProfile.impactOnPoorInvestment`)} value="HIGH" control={<CheckRadioButton />} label="It would have no impact, as I have other savings." />
                          </RadioGroup>
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.impactOnPoorInvestment?.message?.toString()}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 1*/}
          {/* summary section start */}
          <Grid style={{ marginTop: "40px", textAlign: "left" }}>
            <Accordion
              style={{
                border: "1px solid ",
                borderColor: mainApplicantSummaryError ? '#FF7B6F' : 'rgba(157, 157, 157, 0.67)',
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                borderRadius: "21px",
                backgroundColor: "#012169",
                color: "#fff",
                paddingBottom: "60px",
                backgroundImage: `url(${summaryImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
                backgroundSize: "12%"
              }}
              defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
              
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-control="about-you"
                id="about-you"

              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography sx={{ padding: "0 16px", textAlign: "left" }}>
                      {t("RiskProfiler")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>

                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={10}
                  sx={{ textAlign: "left", padding: "12px 16px" }}
                >
                  <Grid item xs={4}>
                    <ReactSpeedometer
                      maxValue={500}
                      value={(RiskIndexDetails < 5 && RiskIndexDetails > 0) ? Number(RiskIndexDetails) * 100 : 500}
                      needleColor="#fff"
                      startColor="#1990A2"
                      segments={100}
                      width={350}
                      height={225}
                      endColor="blue"
                      textColor={'#012169'}
                      currentValueText="Level"
                    />

                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="h6" color="#fff"
                      style={{ marginBottom: '0.75em', fontSize: '14px', fontWeight: 400, textTransform: 'capitalize' }}>{RiskIndexDetails} - {riskIndexDescription}</Typography>

                    {(RiskIndexDetails === 1)
                      && <><Typography variant="h6" color="#fff"
                        style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                        Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                      </Typography>
                        <Typography variant="h6" color="#fff"
                          style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                          Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                        </Typography></>
                    }
                    {(RiskIndexDetails === 2)
                      && <><Typography variant="h6" color="#fff" style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                        Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                      </Typography>
                        <Typography variant="h6" color="#fff"
                          style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                          Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                        </Typography></>
                    }
                    {(RiskIndexDetails === 3)
                      && <><Typography variant="h6" color="#fff"
                        style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                        Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.
                      </Typography>
                        <Typography variant="h6" color="#fff"
                          style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                          Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                        </Typography></>
                    }
                    {(RiskIndexDetails === 4)
                      && <><Typography variant="h6" color="#fff"
                        style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                        Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                      </Typography>
                        <Typography variant="h6" color="#fff"
                          style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                          Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                        </Typography></>
                    }
                    {(RiskIndexDetails === 5)
                      && <><Typography variant="h6" color="#fff"
                        style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                        Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                      </Typography>
                        <Typography variant="h6" color="#fff"
                          style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                          Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                        </Typography></>
                    }
                    <Controller
                      {...register(`customerRiskProfile.isDifferentRiskLevel`, {
                      })}
                      defaultValue={"" as any}
                      control={control}
                      render={({ field }) => (
                        <FormControl fullWidth >
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                sx={{ color: '#fff' }}
                                {...register(`customerRiskProfile.isDifferentRiskLevel`)}
                                onChange={(event, value) => field.onChange(value)}
                                value={field.value}
                                checked={field.value}
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleOutlineIcon />}
                              />
                            }
                            label="I want to select a different risk level from the results of the risk profile questionnaire."
                          />
                        </FormControl>
                      )}
                    />
                    {(watch(`customerRiskProfile.isDifferentRiskLevel`) === true || watch(`customerRiskProfile.isDifferentRiskLevel`) === 'true') &&
                      <FormControl variant="standard" className="formElement ">
                        <InputLabel id="choosenRiskAppetite-label" className='whiteFont formLabel'>{t("RiskProfilerChosenRiskAppetite")}</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={"" as any}
                          {...register(`customerRiskProfile.choosenRiskAppetite` as const, {
                            required: 'Please select an option'
                          })}
                          render={({ field }) => (<Select
                            id="customerRiskProfilerchoosenRiskAppetite"
                            sx={{ borderBottom: '1px solid #fff', color: '#fff' }}
                            error={!!errors?.customerRiskProfile?.choosenRiskAppetite}
                            {...field}
                          >
                            {RiskIndexDetails > 0 && <MenuItem value='LOW'>1 - Defensive</MenuItem>}
                            {RiskIndexDetails > 1 && <MenuItem value='LOW_MEDIUM'>2 - Cautious</MenuItem>}
                            {RiskIndexDetails > 2 && <MenuItem value='MEDIUM'>3 - Balanced</MenuItem>}
                            {RiskIndexDetails > 3 && <MenuItem value='MEDIUM_HIGH'>4 - Performance Driven</MenuItem>}
                            {RiskIndexDetails > 4 && <MenuItem value='HIGH'>5 - Adventurous</MenuItem>}
                          </Select>)} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.choosenRiskAppetite?.message}</FormHelperText>
                      </FormControl>
                    }
                    {riskDetails?.isApplicationForJointOwnership === 'yes' &&
                      <FormControl variant="standard" className="formElement margin-top">
                        <InputLabel id="isCoApplicantRiskProfileUsed-label" className='whiteFont formLabel'>Which risk profile would you like to use?</InputLabel>
                        <Controller
                          control={control}
                          defaultValue={"no" as any}
                          {...register(`customerRiskProfile.isCoApplicantRiskProfileUsed` as const, {
                            required: 'Please select an option'
                          })}
                          render={({ field }) => (<Select
                            id="isCoApplicantRiskProfileUsed"
                            sx={{ borderBottom: '1px solid #fff', color: '#fff' }}
                            error={!!errors?.customerRiskProfile?.isCoApplicantRiskProfileUsed}
                            {...field}
                          >
                            <MenuItem value={'no'}>{details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</MenuItem>
                            <MenuItem value={'yes'}>{details?.personalDetails?.coApplicant?.firstName + " " + details?.personalDetails?.coApplicant?.lastName}</MenuItem>
                          </Select>)} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerRiskProfile?.isCoApplicantRiskProfileUsed?.message?.toString()}</FormHelperText>
                      </FormControl>


                    }

                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 3*/}
          {/* accordion co-applicant risk profile */}
          {
            isJointApplicant &&
            <>
              <Grid item xs={12} sx={{ marginTop: '25px' }}>
                <Accordion className="accordion" sx={{ border: '2px solid', borderColor: jointApplicantRiskProfileError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ padding: '0 16px' }}>Risk Profiler for 2nd Applicant</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='accordionPadding'>
                    <Grid container >
                      <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '15px 25px' }}>
                        <InputLabel className='primary-color' id="continuation-label">Is your application for Joint Ownership</InputLabel>
                        <Controller
                          {...register(`isApplicationForJointOwnership`, {
                          })}
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <RadioGroup
                                {...field}
                                row
                                {...register(`isApplicationForJointOwnership`)}
                                onChange={(event, value) => field.onChange(value)}
                                value={field.value}
                              >
                                <FormControlLabel   {...register(`isApplicationForJointOwnership`)} value="yes" control={<CheckRadioButton />} label="Yes" />
                                <FormControlLabel   {...register(`isApplicationForJointOwnership`)} value="no" control={<CheckRadioButton />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {riskDetails?.isApplicationForJointOwnership === 'yes' &&
                        <>
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>How long do you plan to invest for?</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={"" as any}
                                {...register(`coApplicantRiskProfile.investmentYears` as const, {
                                  validate: {
                                    noValue: (fieldValue: any) => {
                                      if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                        return (
                                          (fieldValue !== undefined && fieldValue !== null && fieldValue !== '') || 'Please select option'
                                        )
                                      }
                                    }
                                  }
                                })}
                                render={({ field }) => (<Select
                                  id="investmentYears"
                                  error={!!errors?.coApplicantRiskProfile?.investmentYears}
                                  {...field}
                                >
                                  <MenuItem value={1} selected>1 year</MenuItem>
                                  <MenuItem value={2}>2 years</MenuItem>
                                  <MenuItem value={3}>3 years</MenuItem>
                                  <MenuItem value={4}>4 years</MenuItem>
                                  <MenuItem value={5}>5 years</MenuItem>
                                  <MenuItem value={6}>6 years</MenuItem>
                                  <MenuItem value={7}>7 years</MenuItem>
                                  <MenuItem value={8}>8 years</MenuItem>
                                  <MenuItem value={9}>9 years</MenuItem>
                                  <MenuItem value={10}>10 years</MenuItem>
                                  <MenuItem value={11}>11 years</MenuItem>
                                  <MenuItem value={12}>12 years</MenuItem>
                                  <MenuItem value={13}>13 years</MenuItem>
                                  <MenuItem value={14}>14 years</MenuItem>
                                  <MenuItem value={15}>15+ years</MenuItem>
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.investmentYears?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>{t("RiskProfilerInvestmentknowledge")}</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={"" as any}
                                {...register(`coApplicantRiskProfile.investmentKnowledge` as const, {
                                  validate: {
                                    noValue: (fieldValue) => {
                                      if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                        return (
                                          (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                        )
                                      }
                                    }
                                  }
                                })}
                                render={({ field }) => (<Select
                                  id="investmentKnowledge"
                                  error={!!errors?.coApplicantRiskProfile?.investmentKnowledge}
                                  {...field}
                                >
                                  <MenuItem value='LOW'>Expert: I have a great deal of experience of investments and have in-depth knowledge of how markets work.</MenuItem>
                                  <MenuItem value='LOW_MEDIUM'>High: I have experience with investing and understand the various different types of investment categories and risks.</MenuItem>
                                  <MenuItem value='MEDIUM'>Moderate: I have some experience in investing and I know the basic types of investments.</MenuItem>
                                  <MenuItem value='MEDIUM_HIGH'>Little: I have little knowledge about investments and risks and have very limited experience in investing.</MenuItem>
                                  <MenuItem value='HIGH'>None: I have no investment knowledge.</MenuItem>
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.investmentKnowledge?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} className="padding-top">
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>{t("RiskProfilerInvestmentObjective")}</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={"" as any}
                                {...register(`coApplicantRiskProfile.investmentObjective` as const, {
                                  validate: {
                                    noValue: (fieldValue) => {
                                      if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                        return (
                                          (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                        )
                                      }
                                    }
                                  }
                                })}
                                render={({ field }) => (<Select
                                  id="investmentObjective"
                                  error={!!errors?.coApplicantRiskProfile?.investmentObjective}
                                  {...field}
                                >
                                  <MenuItem value='LOW'>I want to achieve the best possible return and I understand that this can come with the highest risk.</MenuItem>
                                  <MenuItem value='LOW_MEDIUM'>I would like high long term growth but will accept lower rate of growth with reduced fluctuations.</MenuItem>
                                  <MenuItem value='MEDIUM'>I place equal weight on growth and security.</MenuItem>
                                  <MenuItem value='MEDIUM_HIGH'>I aim for a return which beats inflation but do not want to be exposed to large fluctuations in value.</MenuItem>
                                  <MenuItem value='HIGH'>My aim is to protect the capital I have invested.</MenuItem>
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.investmentObjective?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6} className="padding-top">
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="priorityNearTermAgainstLongTerm-label" className='primary-color formLabel'>How comfortable would you be with a short term decline in your investment?</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={"" as any}
                                {...register(`coApplicantRiskProfile.comfortabilityInShortTermDeclineInInvestment` as const, {
                                  validate: {
                                    noValue: (fieldValue) => {
                                      if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                        return (
                                          (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                        )
                                      }
                                    }
                                  }
                                })}
                                render={({ field }) => (<Select
                                  id="comfortabilityInShortTermDeclineInInvestment"
                                  error={!!errors?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment}
                                  {...field}
                                >
                                  <MenuItem value='LOW' selected>I would not be concerned.</MenuItem>
                                  <MenuItem value='LOW_MEDIUM'>I would only be slightly concerned.</MenuItem>
                                  <MenuItem value='MEDIUM'>I would be moderately concerned.</MenuItem>
                                  <MenuItem value='MEDIUM_HIGH'>I would be a little uncomfortable.</MenuItem>
                                  <MenuItem value='HIGH'>I would be very uncomfortable.</MenuItem>
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.comfortabilityInShortTermDeclineInInvestment?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '15px 25px' }}>
                            <InputLabel className='primary-color' id="continuation-label">Below are a range of returns of an investment of $10,000 over a 5 year period. Looking at the potential loss and gain, which one of these</InputLabel>
                            <InputLabel className='primary-color'>outcomes would you choose?</InputLabel>
                            <Controller
                              {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                      )
                                    }
                                  }
                                }
                              })}
                              defaultValue=""
                              control={control}
                              render={({ field }) => (
                                <FormControl fullWidth error={!!errors?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years}>
                                  <RadioGroup
                                    {...field}
                                    row
                                    {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)}
                                    onChange={(event, value) => field.onChange(value)}
                                    value={field.value}
                                  >
                                    <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)} value="LOW" control={<CheckRadioButton />} label="$6,300 to $17,200" />
                                    <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }}  {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="$6,800 to $16,300" />
                                    <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)} value="MEDIUM" control={<CheckRadioButton />} label="$7,900 to $14,000" />
                                    <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="$8,900 to $12,100" />
                                    <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.rangeOfInvestmentIn5Years`)} value="HIGH" control={<CheckRadioButton />} label="$9,800 to $11,600" />
                                  </RadioGroup>
                                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.rangeOfInvestmentIn5Years?.message?.toString()}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '15px 25px' }}>
                            <InputLabel className='primary-color' id="continuation-label">What is your approach to taking risks?</InputLabel>
                            <Controller
                              {...register(`coApplicantRiskProfile.riskApproach`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                      )
                                    }
                                  }
                                }
                              })}
                              defaultValue=""
                              control={control}
                              render={({ field }) => (
                                <FormControl fullWidth error={!!errors?.coApplicantRiskProfile?.riskApproach}>
                                  <RadioGroup
                                    {...field}
                                    {...register(`coApplicantRiskProfile.riskApproach`)}
                                    onChange={(event, value) => field.onChange(value)}
                                    value={field.value}
                                  >
                                    <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.riskApproach`)} value="LOW" control={<CheckRadioButton />} label="be willing to take a lot more risk with all of your money" />
                                    <FormControlLabel sx={{ color: field.value === 'LOW_MEDIUM' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.riskApproach`)} value="LOW_MEDIUM" control={<CheckRadioButton />} label="be willing to take a little more risk with all of your money" />
                                    <FormControlLabel sx={{ color: field.value === 'MEDIUM_HIGH' ? '#005EB8' : '#000000de' }}     {...register(`coApplicantRiskProfile.riskApproach`)} value="MEDIUM_HIGH" control={<CheckRadioButton />} label="consider the potential losses and only be willing to take a little more risk with some of your money" />
                                    <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}    {...register(`coApplicantRiskProfile.riskApproach`)} value="HIGH" control={<CheckRadioButton />} label="consider the potential losses and be unwilling to take any more risk" />
                                  </RadioGroup>
                                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.riskApproach?.message?.toString()}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '15px 25px' }}>
                            <InputLabel className='primary-color' id="continuation-label">Do you have other savings available to you, should you encounter a financial emergency:</InputLabel>
                            <Controller
                              {...register(`coApplicantRiskProfile.otherSavingsInFinancialEmergency`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                      )
                                    }
                                  }
                                }
                              })}
                              defaultValue=""
                              control={control}
                              render={({ field }) => (
                                <FormControl fullWidth error={!!errors?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency}>
                                  <RadioGroup
                                    {...field}
                                    {...register(`coApplicantRiskProfile.otherSavingsInFinancialEmergency`)}
                                    onChange={(event, value) => field.onChange(value)}
                                    value={field.value}
                                  >
                                    <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}  {...register(`coApplicantRiskProfile.otherSavingsInFinancialEmergency`)} value="LOW" control={<CheckRadioButton />} label="No. I have no other savings I can use in the event of an emergency." />
                                    <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}  {...register(`coApplicantRiskProfile.otherSavingsInFinancialEmergency`)} value="MEDIUM" control={<CheckRadioButton />} label="I have some other savings, but nothing specifically for a financial emergency." />
                                    <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.otherSavingsInFinancialEmergency`)} value="HIGH" control={<CheckRadioButton />} label="Yes. I have specific savings that I can use in an emergency." />
                                  </RadioGroup>
                                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.otherSavingsInFinancialEmergency?.message?.toString()}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} className="padding-top" sx={{ textAlign: 'left', padding: '15px 25px' }}>
                            <InputLabel className='primary-color' id="continuation-label">If this investment were to perform poorly, what impact would this have on your financial well-being:</InputLabel>
                            <Controller
                              {...register(`coApplicantRiskProfile.impactOnPoorInvestment`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (riskDetails?.isApplicationForJointOwnership === 'yes') {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== '') || 'Please select option'
                                      )
                                    }
                                  }
                                }
                              })}
                              defaultValue=""
                              control={control}
                              render={({ field }) => (
                                <FormControl fullWidth error={!!errors?.coApplicantRiskProfile?.impactOnPoorInvestment}>
                                  <RadioGroup
                                    {...field}
                                    {...register(`coApplicantRiskProfile.impactOnPoorInvestment`)}
                                    onChange={(event, value) => field.onChange(value)}
                                    value={field.value}
                                  >
                                    <FormControlLabel sx={{ color: field.value === 'LOW' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.impactOnPoorInvestment`)} value="LOW" control={<CheckRadioButton />} label="It would have a big impact. This investment is required for a specific purpose." />
                                    <FormControlLabel sx={{ color: field.value === 'MEDIUM' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.impactOnPoorInvestment`)} value="MEDIUM" control={<CheckRadioButton />} label="It would have some impact. I would need to make some adjustments to my future plans." />
                                    <FormControlLabel sx={{ color: field.value === 'HIGH' ? '#005EB8' : '#000000de' }}   {...register(`coApplicantRiskProfile.impactOnPoorInvestment`)} value="HIGH" control={<CheckRadioButton />} label="It would have no impact, as I have other savings." />
                                  </RadioGroup>
                                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.impactOnPoorInvestment?.message?.toString()}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* end accodion joint applicant*/}

              {/* summary section start */}
              <Grid style={{ marginTop: "40px", textAlign: "left" }}>
                {riskDetails?.isApplicationForJointOwnership === 'yes' &&
                  <Accordion
                    style={{
                      border: "1px solid rgba(157, 157, 157, 0.67)",
                      borderColor: jointApplicantSummaryError ? '#FF7B6F' : "rgba(157, 157, 157, 0.67)",
                      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                      borderRadius: "21px",
                      backgroundColor: "#012169",
                      color: "#fff",
                      paddingBottom: "60px",
                      backgroundImage: `url(${summaryImg})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right bottom",
                      backgroundSize: "12%"
                    }}
                    defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
                   
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                      aria-control="about-you"
                      id="about-you"

                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography sx={{ padding: "0 16px", textAlign: "left" }}>
                            Risk Profile
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>

                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={10}
                        sx={{ textAlign: "left", padding: "12px 16px" }}
                      >
                        <Grid item xs={4}>
                          <ReactSpeedometer
                            maxValue={500}
                            value={(coAppRiskIndexDetails < 5 && coAppRiskIndexDetails > 0) ? Number(coAppRiskIndexDetails) * 100 : 500}
                            needleColor="#fff"
                            startColor="#1990A2"
                            segments={100}
                            width={350}
                            height={225}
                            endColor="blue"
                            textColor={'#012169'}
                            currentValueText="Level"
                          />

                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="h6" color="#fff"
                            style={{ marginBottom: '0.75em', fontSize: '14px', fontWeight: 400, textTransform: 'capitalize' }}>{coAppRiskIndexDetails} - {coAppriskIndexDescription}</Typography>
                          {(coAppRiskIndexDetails === 1)
                            && <><Typography variant="h6" color="#fff"
                              style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                              Your answers suggest that your risk appetite is low. This means that you will accept limited risks with your money, but will want to try to avoid large fluctuations in the value of your investment, accepting the potential of more modest returns to achieve this.
                            </Typography>
                              <Typography variant="h6" color="#fff"
                                style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                                Funds with a Risk Profile of 1 may include a high proportion of bank deposits, fixed interest investments, such as government bonds and high quality corporate bonds (bonds issued by companies), and may include a small proportion invested in equities (company shares). Over the long term, high returns are unlikely, and the returns may not keep pace with inflation.
                              </Typography></>
                          }
                          {(coAppRiskIndexDetails === 2)
                            && <><Typography variant="h6" color="#fff" style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                              Your answers suggest that your risk appetite is Medium. This means that you are likely to accept some risk in return for the potential of higher investment gains over the longer term. You will want to try to avoid large fluctuations in the value of your investment, but accept there will be some fluctuation, particularly over the shorter term.
                            </Typography>
                              <Typography variant="h6" color="#fff"
                                style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                                Funds with a Risk Profile of 2 are likely to be made up mainly of fixed interest investments such as government bonds and high quality corporate bonds (bonds issued by companies) and bank deposits. These funds are also likely to contain a significant proportion invested in equities (company shares).
                              </Typography></>
                          }
                          {(coAppRiskIndexDetails === 3)
                            && <><Typography variant="h6" color="#fff"
                              style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                              Your answers suggest that your risk appetite is medium. This means that you are likely to accept significant risk in return for the potential for good investment gains over the longer term. You accept there will be significant fluctuations in the value of your investment, particularly over the shorter term. However, you will want to limit the amount of your money held in more risky investments.
                            </Typography>
                              <Typography variant="h6" color="#fff"
                                style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                                Funds with a Risk Profile of 3 may invest in a mix of equities (company shares), and fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                              </Typography></>
                          }
                          {(coAppRiskIndexDetails === 4)
                            && <><Typography variant="h6" color="#fff"
                              style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                              Your answers suggest that your risk appetite is Medium to high. This means that you understand that the value of your investment can go down and up sharply with the potential for greater returns over the longer term.
                            </Typography>
                              <Typography variant="h6" color="#fff"
                                style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                                Funds with a Risk Profile of 4 may include a high proportion of equities (company shares), and a smaller proportion of fixed interest investments such as government bonds and corporate bonds (bonds issued by companies).
                              </Typography></>
                          }
                          {(coAppRiskIndexDetails === 5)
                            && <><Typography variant="h6" color="#fff"
                              style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                              Your answers suggest that your risk appetite is high. This means that you aim for high possible returns and accept higher levels of risk, recognising the value of your investment may fluctuate very sharply, particularly over the short-term.
                            </Typography>
                              <Typography variant="h6" color="#fff"
                                style={{ marginBottom: '0.75em', fontSize: '12px', fontWeight: 400 }}>
                                Funds with a Risk Profile of 5 are likely to invest in high risk assets, such as equities or commodities. The assets may see large day-to-day changes in value, both negative and positive.
                              </Typography></>
                          }
                          <Controller
                            {...register(`coApplicantRiskProfile.isDifferentRiskLevel`, {
                            })}
                            defaultValue={"" as any}
                            control={control}
                            render={({ field }) => (

                              <FormControl fullWidth >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      {...field}
                                      sx={{ color: '#fff' }}
                                      {...register(`coApplicantRiskProfile.isDifferentRiskLevel`)}
                                      onChange={(event, value) => field.onChange(value)}
                                      value={field.value}
                                      checked={field.value}
                                      icon={<CircleOutlinedIcon />}
                                      checkedIcon={<CheckCircleOutlineIcon />}
                                    />
                                  }
                                  label="I want to select a different risk level from the results of the risk profile questionnaire."
                                />
                              </FormControl>
                            )}
                          />
                          {(watch(`coApplicantRiskProfile.isDifferentRiskLevel`) === 'true' || watch(`coApplicantRiskProfile.isDifferentRiskLevel`) === true) &&
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="choosenRiskAppetite-label" className='primary-color formLabel'>{t("RiskProfilerChosenRiskAppetite")}</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={"" as any}
                                {...register(`coApplicantRiskProfile.choosenRiskAppetite` as const, {
                                  required: 'Please select an option'
                                })}
                                render={({ field }) => (<Select
                                  id="coApplicantRiskProfile-choosenRiskAppetite"
                                  error={!!errors?.coApplicantRiskProfile?.choosenRiskAppetite}
                                  {...field}
                                  sx={{ color: '#fff', borderBottom: '1px solid #fff' }}
                                >
                                  {coAppRiskIndexDetails > 0 && <MenuItem value='LOW'>1 - Defensive</MenuItem>}
                                  {coAppRiskIndexDetails > 1 && <MenuItem value='LOW_MEDIUM'>2 - Cautious</MenuItem>}
                                  {coAppRiskIndexDetails > 2 && <MenuItem value='MEDIUM'>3 - Balanced</MenuItem>}
                                  {coAppRiskIndexDetails > 3 && <MenuItem value='MEDIUM_HIGH'>4 - Performance Driven</MenuItem>}
                                  {coAppRiskIndexDetails > 4 && <MenuItem value='HIGH'>5 - Adventurous</MenuItem>}
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.coApplicantRiskProfile?.choosenRiskAppetite?.message}</FormHelperText>
                            </FormControl>
                          }
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                }
              </Grid>
            </>
          }
          {/* end accodion 3*/}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated}  type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })} startIcon={<ArrowBackIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'left' }}>
              {t("FinanceAdviceBack")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} type="submit" endIcon={<ArrowForwardIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'right' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form >
        {/* end Form */}
      </Grid >
      <ToastContainer />
      <DevTool control={control} />
    </Box >
  );
};

export default RiskProfiler;
