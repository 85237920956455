import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, IconButton, Box, Button, Container, FormControl, FormHelperText, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import summaryImg from "../../Images/Frame.png"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios'
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import LinearProgress, {
    linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
const GapAnalysis = ({ isClosedSession, _setPageID, selectedCurrency, isQuoteGenerated }: any) => {
    /* get gap analysis */

    const { t, i18n } = useTranslation();
    const [shouldRender, setShouldRender] = React.useState<string>('')
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [details, setDetails] = React.useState<any>()
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const bearerToken = localStorage.getItem('bearerToken')
    const headers = {
        Authorization: `Bearer ${bearerToken}`,
    };
    const requestOptions = {
        headers: headers,
    };
    React.useEffect(() => {
        _getSessionDetails()
    }, [])
    const _getSessionDetails = async () => {
        const response = await fetch(
            baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
        );
        const reponseDetails = await response.json();
        setDetails(reponseDetails)
    }

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 10,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
    const [gapAnalysisDetails, setGapAnalysisDetails] = React.useState<any>()
    React.useEffect(() => {
        setShowSpinner(true)
        let id = sessionStorage.getItem('sessiondetail')
        axios.get(baseUrl + '/calculate/gap-analysis?id=' + id, requestOptions).then((response: any) => setGapAnalysisDetails(response.data))
        setShowSpinner(false)
    }, [selectedCurrency])
    const dtsPercent = ((gapAnalysisDetails?.dyingTooSoon?.currentAssets / gapAnalysisDetails?.dyingTooSoon?.gapAmount) * 100) > 100 ? 100 : ((gapAnalysisDetails?.dyingTooSoon?.currentAssets / gapAnalysisDetails?.dyingTooSoon?.gapAmount) * 100);
    const dsPercent = ((gapAnalysisDetails?.disability?.currentAssets / gapAnalysisDetails?.disability?.gapAmount) * 100) > 100 ? 100 : ((gapAnalysisDetails?.disability?.currentAssets / gapAnalysisDetails?.disability?.gapAmount) * 100);
    const ciPercent = ((gapAnalysisDetails?.criticalIllness?.currentAssets / gapAnalysisDetails?.criticalIllness?.gapAmount) * 100) > 100 ? 100 : ((gapAnalysisDetails?.criticalIllness?.currentAssets / gapAnalysisDetails?.criticalIllness?.gapAmount) * 100);
    const retirementPercent = ((gapAnalysisDetails?.retirement?.futureAssets / gapAnalysisDetails?.retirement?.gapAmount) * 100) > 100 ? 100 : ((gapAnalysisDetails?.retirement?.futureAssets / gapAnalysisDetails?.retirement?.gapAmount) * 100);
    const progress1 = (gapAnalysisDetails?.dyingTooSoon?.currentAssets && gapAnalysisDetails?.dyingTooSoon?.gapAmount) ? Number(dtsPercent?.toFixed(2)) : 0;
    const progress2 = (gapAnalysisDetails?.disability?.currentAssets && gapAnalysisDetails?.disability?.gapAmount) ? Number(dsPercent?.toFixed(2)) : 0;
    const progress3 = (gapAnalysisDetails?.criticalIllness?.currentAssets && gapAnalysisDetails?.criticalIllness?.gapAmount) ? Number(ciPercent?.toFixed(2)) : 0;
    const progress4 = (gapAnalysisDetails?.retirement?.futureAssets && gapAnalysisDetails?.retirement?.gapAmount) ? Number(retirementPercent?.toFixed(2)) : 0;
    const gapProgress1 = (100 - progress1) >= 100 ? 100 : (100 - progress1).toFixed(2)
    const gapProgress2 = (100 - progress2) >= 100 ? 100 : (100 - progress2).toFixed(2)
    const gapProgress3 = (100 - progress3) >= 100 ? 100 : (100 - progress3).toFixed(2)
    const gapProgress4 = (100 - progress4) >= 100 ? 100 : (100 - progress4).toFixed(2)
    const _getWidth = () => {
        if (gapAnalysisDetails?.financialObjectives?.length > 4 && gapAnalysisDetails?.financialObjectives?.length < 6) {
            return "50px"
        } else if (gapAnalysisDetails?.financialObjectives?.length >= 6) {
            return "45px"
        }
        else {
            return '75px'
        }
    }
    const VerticalLinearProgress = styled(LinearProgress)(() => ({
        width: _getWidth(),
        height: "100%",
        borderRadius: '15px 15px 0 0',
        [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: "#005EB8",
            borderBottom: "2px solid 005EB8"
        },
        [`&.${linearProgressClasses.colorSecondary}`]: {
            backgroundColor: "#65D5FF"
        }
    }));
    const _getPercent = (currentAssets: number, gapAmount: number) => {
        if (currentAssets && gapAmount) {
            let percent = (currentAssets / gapAmount) * 100
            if (percent > 100) {
                return -100
            } else {
                return -percent
            }
        } else {
            return -0.5
        }
    }
   
    const _getLabel = (dependentId: string) => {
        let childName = details?.personalDetails?.dependents.filter((element: any) => element?.id === dependentId)
        return (childName?.length > 0) ? "University - " + childName[0]?.firstName + " " + childName[0]?.lastName : 'University'
    }
    const _getLabell = (dependentId: string) => {
        let childName = details?.personalDetails?.dependents.filter((element: any) => element?.id === dependentId)
        return (childName?.length > 0) ? "Marriage - " + childName[0]?.firstName + " " + childName[0]?.lastName : 'Marriage'
    }
    const _getExpensePer =(element:any)=>{
      return  (element?.lessFutureValue && element?.gapAmount) ? +(element?.lessFutureValue / element?.gapAmount).toFixed(2) : 0
    }
    const _getExpenseGap = (element:any) =>{
     let value = 100 -   _getExpensePer(element)
     return value >= 100 ? 100 : value?.toFixed(2)
    }
    return (
        <>
            <Dialog className='spinner' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
            <Container style={{ marginTop: '0', marginBottom: '40px', padding: '0' }}>
                <Grid container sx={{ paddingTop: '15px' }}>
                    {/* summary section start */}
                    <Grid item xs={12} style={{ marginTop: "40px", textAlign: "left" }}>
                        <Accordion
                            expanded={expanded === true}
                            style={{
                                border: "1px solid rgba(157, 157, 157, 0.67)",
                                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                                borderRadius: "21px",
                                borderBottom: '5px solid #005EB8',
                                backgroundColor: "#012169",
                                color: "#fff",
                                paddingBottom: "60px",
                                backgroundImage: `url(${summaryImg})`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right bottom",
                                backgroundSize: "12%"
                            }}
                            onChange={() => setExpanded(!expanded)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                                aria-control="about-you"
                                id="about-you"

                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography sx={{ padding: "0 16px", textAlign: "left" }}>
                                            Gap analysis
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    sx={{ textAlign: "left", padding: "5px 16px" }}
                                >
                                    
                                        
                                    
                                    <Grid item xs={10}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                height: "300px",
                                                margin: '0 auto 12% auto',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                                
                                            }}
                                        >
                                            <Box>
                                                <VerticalLinearProgress
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={progress1}
                                                    sx={{
                                                        [`& .${linearProgressClasses.bar}`]: {
                                                            transform: `translateY(${-progress1}%)!important`
                                                        },
                                                        margin: '10px'
                                                    }}
                                                    title={"Assets "+progress1+"% , Gap "+ gapProgress1+"%"}
                                                />
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto' }}>Life protection</Typography>
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Assets - "+progress1+"%"}</Typography>  
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Gap - "+ gapProgress1+"%"}</Typography>
                                                </Box>
                                      
                                            <Box>
                                                <VerticalLinearProgress
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={progress2}
                                                    sx={{
                                                        [`& .${linearProgressClasses.bar}`]: {
                                                            transform: `translateY(${-progress2}%)!important`
                                                        },
                                                        margin: '10px'
                                                    }}
                                                    title={"Assets "+progress2+"% , Gap "+ gapProgress2+"%"}
                                                />
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto' }}>Disability protection </Typography>
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Assets - "+progress2+"%"}</Typography>  
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Gap - "+ gapProgress2+"%"}</Typography>
                                                
                                            </Box>
                                            <Box>
                                                <VerticalLinearProgress
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={progress3}
                                                    sx={{
                                                        [`& .${linearProgressClasses.bar}`]: {
                                                            transform: `translateY(${-progress3}%)!important`
                                                        },
                                                        margin: '10px'
                                                    }}
                                                    title={"Assets "+progress3+"% , Gap "+ gapProgress3+"%"}
                                                />
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto' }}>Critical illness protection</Typography>
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Assets - "+progress3+"%"}</Typography>  
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Gap - "+ gapProgress3+"%"}</Typography>
                                                
                                            </Box>
                                            <Box >
                                                <VerticalLinearProgress
                                                    variant="determinate"
                                                    color="secondary"
                                                    value={progress4}
                                                    sx={{
                                                        [`& .${linearProgressClasses.bar}`]: {
                                                            transform: `translateY(${-progress4}%)!important`
                                                        },
                                                        margin: '10px'
                                                    }}
                                                    title={"Assets "+progress4+"% , Gap "+ gapProgress4+"%"}
                                                />
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto' }}> Retirement protection </Typography>
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Assets - "+progress4+"%"}</Typography>  
                                                <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Gap - "+ gapProgress4+"%"}</Typography>
                                                
                                            </Box>
                                            {gapAnalysisDetails?.financialObjectives?.length > 0 &&
                                                gapAnalysisDetails?.financialObjectives?.map((element: any, index: any) => {
                                                    return <Box key={index} >
                                                        <VerticalLinearProgress
                                                            variant="determinate"
                                                            color="secondary"
                                                            value={_getExpensePer(element)}
                                                            sx={{
                                                                [`& .${linearProgressClasses.bar}`]: {
                                                                    transform: `translateY(${_getPercent(element?.lessFutureValue, element?.gapAmount)}%)!important`
                                                                },
                                                                margin: '10px'
                                                            }}
                                                        />
                                                        <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto' }}>{element.name?.includes('University') ? _getLabel(element?.dependentId) : element.name?.includes('Marriage') ? _getLabell(element?.dependentId) : element.name}</Typography>
                                                        <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Assets - "+_getExpensePer(element)+"%"}</Typography>  
                                                        <Typography sx={{ width: _getWidth(), fontSize: '12px', margin: 'auto',fontWeight:'bold' }}>{"Gap - "+ (_getExpenseGap(element))+"%"}</Typography>
                                                
                                                    </Box>
                                                })
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid xs={2} sx={{ margin: 'auto' }}>
                                        <Button variant="text" sx={{ color: '#fff' }} startIcon={<Box className="square" />}>Assets/Plan</Button>
                                        <Button variant="text" sx={{ color: '#fff' }} startIcon={<Box className="square1" />}>Gap</Button>

                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {/* end accodion 3*/}
                    <Grid item xs={12} style={{ marginTop: "40px", textAlign: "left" }}>
                        <Grid container>
                            <Grid item xs={6} sx={{ paddingRight: '15px', paddingTop: '15px' }}>
                                <Accordion sx={{ borderBottom: '5px solid #005EB8' }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ padding: '15px 0' }}>
                                                <Typography variant="h1" sx={{ fontSize: '16px', color: '#005EB8', fontWeight: '600' }}>Dying too soon (Life Protection)</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>Current Assets/Plans</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>Life protection gap</Typography>
                                            </Grid>
                                            <Grid item xs={6} >
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.currentAssets?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>

                                        </Grid>

                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '15px 15px !important', borderTop: '1px solid #fff' }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginBottom: '15px' }}><BorderLinearProgress variant="determinate" value={(gapAnalysisDetails?.dyingTooSoon?.currentAssets && gapAnalysisDetails?.dyingTooSoon?.gapAmount) ? gapAnalysisDetails?.dyingTooSoon?.currentAssets / gapAnalysisDetails?.dyingTooSoon?.gapAmount : 0} /></Grid>
                                            <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                <Typography sx={{ color: '#000066', fontSize: '16px', fontWeight: '700' }}>
                                                    {t("GapAnalysisGotAmount")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("FourRisksExpectedLivingExpenses")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.expectedLivingExpense.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisAnnualExpenses")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.reportedAnnualExpense.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ padding: '15px' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#4c433d', fontWeight: '400' }}>{t("GapAnalysisYearsDependency")}</Typography>
                                            </Grid>

                                            <Grid item xs={12} sx={{ margin: '2px 0' }}>

                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px', border: '2px solid #34A853' }}>
                                                <Grid item xs={7} sx={{ background: '#fff' }}>
                                                    <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700', padding: '15px' }}>{t("FourRisksLargeFutureExpenses")}</Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.largeFutureExpense.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px' }}>
                                                <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("FourRisksLifeProtectionNeeds")}</Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.lifeProtectionNeeds.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px', border: gapAnalysisDetails?.dyingTooSoon?.details?.currentLifeProtection.toFixed(0) > 0 ? '2px solid #34A853' : '2px solid #FF7B6F' }}>
                                                <Grid item xs={7} sx={{ background: '#fff' }}>
                                                    <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', padding: '15px', fontWeight: '700' }}>{t("GapAnalysisCurrentLifeProtection")}</Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.currentLifeProtection.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px', background: '#012169' }}>
                                                <Grid item xs={7} sx={{ padding: '15px' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', fontWeight: '700' }}>{t("PortfolioAssetsLifeProtectionGap")}</Typography></Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.dyingTooSoon?.details?.gapAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>

                                </Accordion>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: '15px' }}>
                                <Accordion sx={{ borderBottom: '5px solid #21AFE5' }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ padding: '15px 0' }}>
                                                <Typography variant="h1" sx={{ fontSize: '16px', color: '#005EB8', fontWeight: '600' }}>{t("GapAnalysisDisabilityProtection")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisCurrentAssets")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>Disability Protection gap</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.disability?.currentAssets?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.disability?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>

                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '15px 15px !important', borderTop: '1px solid #fff' }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginBottom: '15px' }}><BorderLinearProgress variant="determinate" value={(gapAnalysisDetails?.disability?.currentAssets && gapAnalysisDetails?.disability?.gapAmount) ? gapAnalysisDetails?.disability?.currentAssets / gapAnalysisDetails?.disability?.gapAmount : 0} /></Grid>
                                            <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                <Typography sx={{ color: '#000066', fontSize: '16px', fontWeight: '700' }}>
                                                    {t("GapAnalysisGotAmount")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                <Typography sx={{ color: '#A89F96', fontSize: '14px', fontWeight: '700' }}>
                                                    {t("FourRisksOptimalCoverage")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("GapAnalysisDisabilityProtectionNeeds")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.disability?.details?.disabilityProtectionNeeds.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px', border: gapAnalysisDetails?.disability?.details?.currentDiasabilityCoverage.toFixed(0) > 0 ? '2px solid #34A853' : '2px solid #FF7B6F' }}>
                                                <Grid item xs={7} sx={{ background: '#fff' }}>
                                                    <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', padding: '15px', fontWeight: '700' }}>{t("GapAnalysisDisabilityProtectionCoverage")} </Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.disability?.details?.currentDiasabilityCoverage.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px', background: '#012169' }}>
                                                <Grid item xs={7} sx={{ padding: '15px' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{t("PortfolioAssetsDisabilitProtectionGap")}</Typography></Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.disability?.details?.gapAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingRight: '15px', paddingTop: '15px' }}>
                                <Accordion sx={{ borderBottom: '5px solid #DBD3BD' }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ padding: '15px 0' }}>
                                                <Typography variant="h1" sx={{ fontSize: '16px', color: '#005EB8', fontWeight: '600' }}>{t("GapAnalysisCriticalIllness")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisCurrentAssets")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("PortfolioAssetsCriticalillnessGap")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency} {gapAnalysisDetails?.criticalIllness?.currentAssets?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency} {gapAnalysisDetails?.criticalIllness?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>

                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '15px 15px !important', borderTop: '1px solid #fff' }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginBottom: '15px' }}><BorderLinearProgress variant="determinate" value={(gapAnalysisDetails?.criticalIllness?.currentAssets && gapAnalysisDetails?.criticalIllness?.gapAmount) ? gapAnalysisDetails?.criticalIllness?.currentAssets / gapAnalysisDetails?.criticalIllness?.gapAmount : 0} /></Grid>
                                            <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                <Typography sx={{ color: '#005EB8', fontSize: '16px', fontWeight: '700' }}>
                                                    {t("GapAnalysisGotAmount")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("FourRisksCriticalIllnessNeeds")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.criticalIllness?.details?.ciProtectionNeeds ? gapAnalysisDetails?.criticalIllness?.details?.ciProtectionNeeds?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}</Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ margin: '2px 0', padding: '0 15px' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisExpectedLivingExpenses")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ margin: '2px 0', padding: '0 15px', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.criticalIllness?.details?.expectedLivingExpense?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '2px', border: '2px solid #34A853' }}>
                                                <Grid item xs={7}>
                                                    <Grid container><Grid item xs={11} ><Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700', padding: '15px' }}>{t("GapAnalysisExpectedLumpMedical")}</Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.criticalIllness?.details?.expectedLumpSumMedicalCost?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px', border: gapAnalysisDetails?.criticalIllness?.details?.currentCiCoverage?.toFixed(0) > 0 ? '2px solid #34A853' : '2px solid #FF7B6F' }}>
                                                <Grid item xs={7} sx={{ background: '#fff' }}>
                                                    <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', padding: '15px', fontWeight: '700' }}>{t("GapAnalysisCriticalIllnessProtectionCoverage")}</Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.criticalIllness?.details?.currentCiCoverage?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px', background: '#012169' }}>
                                                <Grid item xs={7} sx={{ padding: '15px' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{t("PortfolioAssetsCriticalillnessGap")}</Typography></Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.criticalIllness?.details?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item xs={6} sx={{ paddingTop: '15px' }}>
                                <Accordion sx={{ borderBottom: '5px solid #7371D2' }}>
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ padding: '15px 0' }}>
                                                <Typography variant="h1" sx={{ fontSize: '16px', color: '#005EB8', fontWeight: '600' }}>{t("GapAnalysisLivingLongRetirement")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisFutureAssets")}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("PortfolioAssetsRetirementGap")}</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.futureAssets?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '15px 15px !important', borderTop: '1px solid #fff' }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginBottom: '15px' }}><BorderLinearProgress variant="determinate" value={(gapAnalysisDetails?.retirement?.futureAssets && gapAnalysisDetails?.retirement?.gapAmount) ? gapAnalysisDetails?.retirement?.futureAssets / gapAnalysisDetails?.retirement?.gapAmount : 0} /></Grid>
                                            <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                <Typography sx={{ color: '#005EB8', fontSize: '16px', fontWeight: '700' }}>
                                                    {t("GapAnalysisGotAmount")}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("FourRisksAnnualLivingExpensesAge")}</Typography>
                                            </Grid>
                                            <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.details?.annualLivingExpenseAtAgeOfRetirement.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px' }}>
                                                <Grid item xs={12} sx={{ background: '#fff', border: '2px solid #34A853' }}>
                                                    <Grid container> <Grid item xs={10}> <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700', padding: '15px' }}>{t("GapAnalysisYearsRetirement")}</Typography></Grid></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px' }}>
                                                <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{t("GapAnalysisRetirementgoal")}</Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.details?.retirementGoal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '3px' }}>
                                                <Grid item xs={7} sx={{ background: '#fff' }}>
                                                    <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', padding: '15px', fontWeight: '700' }}>{t("GapAnalysisValueAssetsGoalRetirement")}</Typography></Grid></Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.details?.valueOfAssetsForGoal.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container sx={{ marginTop: '10px', background: '#012169' }}>
                                                <Grid item xs={7} sx={{ padding: '15px' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{t("PortfolioAssetsRetirementGap")} </Typography></Typography>
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{selectedCurrency}  {gapAnalysisDetails?.retirement?.details?.gapAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography></Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            {gapAnalysisDetails?.financialObjectives?.length > 0 &&
                                gapAnalysisDetails?.financialObjectives?.map((element: any, index: any) => {
                                    return <Grid item xs={6} key={index}> <Box sx={{ borderTop: index > 1 ? '2px solid #fff' : '0', paddingTop: '15px', paddingRight: (index % 2 === 0) ? '15px' : '0' }}><Accordion sx={{ borderBottom: '5px solid #22BDB9' }}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ padding: '15px 0' }} >
                                                    {element.description === '' ?
                                                        <Typography variant="h1" sx={{ fontSize: '14px', color: '#005EB8', fontWeight: '600' }}>Financial Objectives - {element.name?.includes('University') ? _getLabel(element?.dependentId) : element.name?.includes('Marriage') ? _getLabell(element?.dependentId) : element.name}</Typography> :
                                                        <Typography variant="h1" sx={{ fontSize: '14px', color: '#005EB8', fontWeight: '600' }}>{element.name?.includes('University') ? _getLabel(element?.dependentId) : element.name?.includes('Marriage') ? _getLabell(element?.dependentId) : element.name}{" (" + element.description + " )"}</Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisFutureAssets")}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700' }}>{t("GapAnalysisGap")}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {element?.lessFutureValue?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '12px', color: '#005EB8', fontWeight: '700', marginTop: '10px' }}>{selectedCurrency}  {element?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>

                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: '15px 15px !important', borderTop: '1px solid #fff' }}>
                                            <Grid container>
                                                <Grid item xs={12} sx={{ marginBottom: '15px' }}><BorderLinearProgress variant="determinate" value={(element?.lessFutureValue && element?.gapAmount) ? element?.lessFutureValue / element?.gapAmount : 0} /></Grid>
                                                <Grid item xs={12} sx={{ margin: '0 0 10px 0' }}>
                                                    <Typography sx={{ color: '#005EB8', fontSize: '16px', fontWeight: '700' }}>
                                                        {t("GapAnalysisGotAmount")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={7} sx={{ padding: '15px', background: '#fff' }}>
                                                    {element.description === '' ?
                                                        <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{element.name}</Typography> :
                                                        <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{element.name + " (" + element.description + ") "}</Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {Number(element?.amount)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                </Grid>
                                                <Grid container sx={{ marginTop: '3px', border: (element?.lessFutureValue)?.toFixed(0) > 0 ? '2px solid #34A853' : '2px solid #FF7B6F' }}>
                                                    <Grid item xs={7} sx={{ background: '#fff' }}>
                                                        <Grid container><Grid item xs={10} > <Typography sx={{ fontSize: '14px', color: '#4c433d', padding: '15px', fontWeight: '700' }}>Future Value of Assets/Plans for Goal</Typography></Grid></Grid>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{ padding: '15px', background: '#fff', textAlign: 'right' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#4c433d', fontWeight: '700' }}>{selectedCurrency}  {Number(element?.lessFutureValue)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{ marginTop: '10px', background: '#012169' }}>
                                                    <Grid item xs={7} sx={{ padding: '15px' }}>
                                                        {element.description === '' ?
                                                            <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{element.name?.includes('University') ? _getLabel(element?.dependentId) : element.name?.includes('Marriage') ? _getLabell(element?.dependentId) : element.name}</Typography></Typography>
                                                            :
                                                            <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{element.name?.includes('University') ? _getLabel(element?.dependentId) : element.name?.includes('Marriage') ? _getLabell(element?.dependentId) : element.name}{" (" + element.description + ")"}</Typography></Typography>
                                                        }

                                                    </Grid>
                                                    <Grid item xs={5} sx={{ padding: '15px', textAlign: 'right' }}>
                                                        <Typography sx={{ fontSize: '14px', color: '#fff', fontWeight: '700' }}><Typography sx={{ fontSize: '16px', color: '#009ee0', fontWeight: '700' }}>{selectedCurrency}  {Number(element?.gapAmount)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography></Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion></Box></Grid>
                                })
                            }

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '5%' }}>
                        <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" startIcon={<ArrowBackIcon />} onClick={() => _setPageID(6)} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'left' }}>
                            {t("FinanceAdviceBack")}
                        </Button>
                        <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" endIcon={<ArrowForwardIcon />} onClick={() => _setPageID(8)} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'right' }}>
                            {t("FinanceAdviceNext")}
                        </Button>

                    </Grid>
                </Grid>
            </Container></>
    )
}

export default GapAnalysis
