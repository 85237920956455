import React, { useEffect, useState } from 'react';
import { DataGrid, GridEventListener, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { useSelector, useDispatch } from 'react-redux';
import { getAllCustomers, fetchCustomers, fetchCustomersCloseSession, fetcAppConstant, setSaveStatusPayload, saveStatus } from '../redux/GenericDetailsSlice'
import { BrowserRouter as Router } from 'react-router-dom';
import NotesIcon from '@mui/icons-material/Notes';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../redux/hook'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom'
import spinner from '../Images/zurich.gif'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
type propsType = {
  tableFilter?: string,
  sessionDetails?: string,
  setCurrentPage?: any
}

const AllSession = ({ tableFilter, sessionDetails, setCurrentPage }: propsType) => {
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [openSess, setOpenSess] = React.useState<any>()
  const [currPage, setCurrPage] = React.useState<number>(1)
  const [closeSess, setCloseSess] = React.useState<any>()
  const [currPageClose, setCurrPageClose] = React.useState<number>(1)
  const saveStatusPayload = useSelector((state: any) => state.genericDetails?.saveStatusPayload);
  const dispatch = useAppDispatch();
  const OpenSessions = useSelector((state: any) => state.genericDetails?.customerList);
  const closeSessions = useSelector((state: any) => state.genericDetails?.closeSessionCustomerList);
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  //const { t, i18n } = useTranslation();
  const requestOptions = {
    headers: headers,
  };
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const fetchOpenSession = async (page: number, limit: number) => {
    try {
      setShowSpinner(true)
      const response = await axios.get(baseUrl + `/sessions/v1?state=OPEN&page=${page}&limit=5`, requestOptions)

      const responseDetails = await response.data;
      localStorage.setItem('openSessionCount', responseDetails.paging.totalResult)
      setOpenSess(responseDetails)
      setShowSpinner(false)
      return responseDetails
    } catch (error: any) {

      if (error?.response?.status === 401) {
        navigate('/login');
      }
      else {
        toast.error('Something went wrong, please try again later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
    setShowSpinner(false)
  }
  const fetchCloseSession = async (page: number, limit: number) => {
    try {
      setShowSpinner(true)
      const response = await axios.get(baseUrl + `/sessions/v1?state=CLOSED&page=${page}&limit=5`, requestOptions)

      const responseDetails = await response.data;

      localStorage.setItem('closeSessionCount', responseDetails.paging.totalResult)
      setShowSpinner(false)
      setCloseSess(responseDetails)
      return responseDetails
    } catch (error: any) {

      if (error?.response?.status === 401) {
        navigate('/login');
      }
      else {
        toast.error('Something went wrong, please try again later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
    setShowSpinner(false)
  }
  React.useEffect(() => {

    fetchOpenSession(currPage, 2)
    //setShowSpinner(false)
  }, [currPage])
  React.useEffect(() => {

    fetchCloseSession(currPageClose, 2)

  }, [currPageClose])

  const customerDetailsObj = sessionDetails === 'Open' ? openSess?.data : closeSess?.data
  const applicationStatus = useSelector((state: any) => state.genericDetails?.applicationStatus);
  const [notesValue, setNotesValue] = React.useState<string>('')
  const CustomToolbar = () => {
    return <Grid container sx={{ padding: '15px 15px 8px 15px' }}>
      <Grid item xs={8}>
        <Typography className="secoundaryFont primary-color bold-font textAlign">{sessionDetails === 'Open' ? `Open Sessions (${openSess?.paging.totalResult}) ` : `Closed Sessions (${closeSess?.paging.totalResult}) `}</Typography>
      </Grid>
      <Grid item xs={4} sx={{ textAlign: 'right' }}>
        <Box sx={{ display: 'inline-flex' }} onClick={() => setCurrentPage(sessionDetails === 'Open' ? 2 : 3)}><Typography className="primary-color" sx={{ fontSize: '14px', cursor: 'pointer' }}>View all </Typography><ArrowRightAltIcon className="primary-color" fontSize='medium' sx={{ paddingBottom: '5px' }} /></Box>
      </Grid>
    </Grid>
  }
  const CustomPagination = () => {
    return <Grid container sx={{ padding: '15px 15px 8px 15px' }}>
      {sessionDetails === 'Open' ?
        (<>
          <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '14px' }}>{(openSess?.paging.current - 1) * 5 + 1}</Typography>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1, fontSize: '14px' }}> - </Typography>
            <Typography sx={{ fontSize: '14px' }}>{openSess?.paging.current * 5}</Typography>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1, fontSize: '14px' }}> of </Typography>
            <Typography sx={{ fontSize: '14px', paddingRight: 4 }}>{openSess?.paging.totalResult}</Typography>
            <IconButton disabled={openSess?.paging.previous == -1} onClick={() => {

              setCurrPage(currPage - 1)

            }}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton disabled={openSess?.paging.next == -1} onClick={() => {
              setCurrPage(currPage + 1)

            }}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </>) :
        (<>
          <Box sx={{ display: 'inline-flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '14px' }}>{(closeSess?.paging.current - 1) * 5 + 1}</Typography>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1, fontSize: '14px' }}> - </Typography>
            <Typography sx={{ fontSize: '14px' }}>{closeSess?.paging.current * 5}</Typography>
            <Typography sx={{ paddingLeft: 1, paddingRight: 1, fontSize: '14px' }}> of </Typography>
            <Typography sx={{ fontSize: '14px', paddingRight: 3 }}>{closeSess?.paging.totalResult}</Typography>
            <IconButton disabled={closeSess?.paging.previous == -1} onClick={() => {
              setCurrPageClose(currPageClose - 1)
            }}>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton disabled={closeSess?.paging.next == -1} onClick={() => {

              setCurrPageClose(currPageClose + 1)
            }}>
              <ChevronRightIcon />
            </IconButton>


          </Box></>)}
    </Grid>

  }
  const [expenseDetails, setExpenseDetails] = React.useState({
    expensePlace: {
      value: '',
      error: false,
      errorText: ''
    }
  })


  let customerDetails = []
  const excludeColumns = ["firstName", "lastName", "email"];
  if (tableFilter) {
    customerDetails = customerDetailsObj
      .filter((item: any) => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ?
            item[key]?.toString().toLowerCase().includes(tableFilter.toLowerCase()) : false);
      });
  } else {
    customerDetails = customerDetailsObj;
  }

  const tableSettingsObj = useSelector((state: any) => state.genericDetails.settingsTable);

  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const getAge = (dob: string) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  const _navigateSoluations = (id: string, customerId: string) => {
    sessionStorage.setItem('sessiondetail', id)
    sessionStorage.setItem('customerdetail', customerId)
    let closedSession = sessionDetails === 'Open' ? false : true
    sessionStorage.setItem('closedSession', closedSession?.toString())
    navigate('/solution', { replace: true, state: { closedSession } })
  }
  const renderNotesCell = (status: string, selectedId: string, notes: any, e: any) => {
    e.stopPropagation()
    setNotesValue(notes)
    dispatch(setSaveStatusPayload({ 'name': 'status', 'value': status, 'sessionId': selectedId }))
    setNotesDialogOpen(!notesDialogOpen)
  }
  const handleNotesDialogOpen = () => {
    setNotesDialogOpen(true)
  }
  const handleStatusChangeDialogOpen = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    setStatusDialogOpen(false)
    _fetchCustomerUpdatedList()
    window.location.reload()
    setShowSpinner(false)
  }
  const _fetchCustomerUpdatedList = () => {
    dispatch(fetchCustomers())
    dispatch(fetchCustomersCloseSession())
  }
  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false)
  }
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value, 'sessionId': selectedId }))
    setStatusDialogOpen(true)
  }
  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    setNotesValue(value)
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value }))
  }
  const renderRating = (params: any) => {
    let value = params?.row?.status
    return <Select
      labelId="expensePlace-label"
      id="expensePlace-select"
      variant="outlined"
      name="status"
      onChange={(e: any) => handleStatusChange(e, params?.id)}
      value={value}
      sx={{ width: '100%', height: '40px', border: '0', margin: '2px 5px 2px 0' }}
    >
      {applicationStatus ?
        applicationStatus.map((listElement: any, index: string) => {
          if (sessionDetails !== 'Open') {
            if (listElement.name !== "Meeting 1" && listElement.name !== "Meeting 2") {
              return <MenuItem key={index} value={listElement.key}>{listElement.name}</MenuItem>
            }
          } else {
            return <MenuItem key={index} value={listElement.key}>{listElement.name}</MenuItem>
          }
        }) : ''
      }
    </Select>;
  }
  const createData = (
    id: string,
    name: string,
    status: string,
    NotesComments: string
  ) => {
    return { id, name, status, NotesComments };
  }
  const renderNotes = (params: any) => {
    return <><NotesIcon onClick={(e) => renderNotesCell(params?.row?.status, params?.row?.id, params.row?.NotesComments, e)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} /></>;
  }
  const renderEdit = (params: any) => {
    return <MoreVertIcon fontSize='small' onClick={(e) => _navigateSoluations(params?.row?.id, params?.row?.customerId)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} />;
  }
  const columns: GridColDef[] = [
    { field: 'name', headerName: "Name", width: 150, hideable: tableSettingsObj.Name, disableColumnMenu: true, sortable: false },
    {
      field: 'status',
      headerName: "Status",
      renderCell: renderRating,
      editable: true,
      width: 200,
      minWidth: 200,
      hideable: tableSettingsObj.Status ? true : false,
      disableColumnMenu: true, sortable: false
    },
    {
      field: 'NotesComments', headerName: "Notes", renderCell: renderNotes, hideable: tableSettingsObj.NotesComments,
      width: 60, minWidth: 60, disableColumnMenu: true, sortable: false
    },
    {
      field: 'edit', headerName: "", renderCell: renderEdit, hideable: tableSettingsObj.NotesComments,
      width: 40, minWidth: 40, disableColumnMenu: true, sortable: false
    },
  ];

  const rows: GridRowsProp = customerDetails?.length > 0 && customerDetails?.map((element: any) => {
    return createData(element?.id, element?.name, element.status, element?.notes)
  })
  const useRowClick: GridEventListener<'rowClick'> = (params, event, details) => {
    let id = params?.row?.id;
    let customerId = params?.row?.customerId;
    _navigateSoluations(id, customerId)
  }
  const saveNotes = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    _fetchCustomerUpdatedList()
    window.location.reload()
    setShowSpinner(false)
  }
  const handlePageChange = (newPage: any) => {

    setCurrPage(newPage)
  }
  return (
    <>

      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Grid container>
        <Grid item xs={12}>
          <DataGrid
            className="applicantion"
            rows={rows}
            sx={{ height: '390px' }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5 },
              },
            }}

            pagination
            paginationMode="server"

            onRowClick={useRowClick}
            slots={{
              toolbar: CustomToolbar,
              pagination: CustomPagination,
            }}
            components={{
              Footer: CustomPagination
            }}

          />

        </Grid>
        <Dialog open={notesDialogOpen}>
          <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '250px', background: '#f6f9f8' }}>
            <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Advisor's note</Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              value={notesValue}
              rows={5}
              name="notes"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotesChange(e, '')}
              sx={{ height: 'auto !important', width: '450px' }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { saveNotes(); setNotesDialogOpen(false) }}>
                Save
              </Button>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => setNotesDialogOpen(false)}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
        <Dialog onClose={handleStatusDialogClose} open={statusDialogOpen}>
          <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '15px', height: 'auto', background: '#f6f9f8' }}>
            <Box>
              <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Are you sure you want to update the sales stage?</Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleStatusChangeDialogOpen}>
                Save
              </Button>
              <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleStatusDialogClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Grid>
    </>
  )
}

export default AllSession