import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios'

const ShowPdf = ({ reportUrl }: any) => {
    const [pdfUrl, setPdfUrl] = useState<string>('');
    const [viewURL, setViewURL] = useState<string | null>(null);

    useEffect(() => {
        const fetchPdfUrl = async () => {
            try {
                if (reportUrl) {
                    const reportpdfUrl1 = reportUrl?.toString().replace(/fileType=download$/, "fileType=base64")
                    const reportpdfUrl = reportpdfUrl1.toString().replace('http', 'https')
                    setPdfUrl(reportpdfUrl);
                    _base64Data(reportpdfUrl)
                }
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        };

        fetchPdfUrl();
    }, [reportUrl]);
    const base64toBlob = (data: string) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const _base64Data = async (url: string) => {
        const bearerToken = localStorage.getItem('bearerToken')
        const headers = {
            Authorization: `Bearer ${bearerToken}`,
        };
        const requestOptions = {
            headers: headers,
        };
        const response = await axios.get(url, requestOptions);
        const responseDetails = await response.data;
        let details = base64toBlob(responseDetails?.data)
        let pdfurlstring = URL.createObjectURL(details)
        setViewURL(pdfurlstring)
    }



    return (
        <Box sx={{ height: '100%', padding: '5px 50px 5px 10px' }}>
            {viewURL ? (
                <iframe title="PDF Viewer" src={viewURL} width="100%" height="100%" />
            ) : (
                <p>Loading PDF...</p>
            )}
        </Box>
    );
};

export default ShowPdf;
