import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './Styles/globalStyles.css'
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login'
import axios from 'axios'
import BasicDocument from './components/Soluations/BasicDocument';
import { useNavigate } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { LoginCallback } from '@okta/okta-react';
import { RequiredAuth } from './SecureRoute';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from './Loading';
import config from './config';
import AppRoutes from './Routes';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
const oktaAuth = new OktaAuth(config.oidc);

function App() {

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const [timer, setTimer] = useState(3600000)
  const [noActionTimer, setNoActionTimer] = useState()
  const [logoutDialogOpen, setLogoutDialogOpen] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [lastActive, setLastActive] = useState<any>(new Date());
  const [userActionConfirm, setUserActionConfirm] = useState(false);
  const [idleTime, setIdleTime] = useState(0);
  const token = localStorage.getItem('role')


  useEffect(() => {
    if (token) {
      const intervalId = setInterval(() => {
        const currentTime: any = new Date();
        const idle: any = currentTime - lastActive;

        if (idle > 27000) {
          setIdleTime(idle);
          setLogoutDialogOpen(true);

          const logoutInterval = setTimeout(() => {
            if (!userActionConfirm)
              handleLogout();
          }, 60000);

          return () => clearTimeout(logoutInterval);
        }
      }, 2700000);

      return () => clearInterval(intervalId);
    }
  }, [token, lastActive]);

  const handleLogout = () => {
    setUserActionConfirm(false)
    setLogoutDialogOpen(false);
    toast.info('User logout due to inactivity, please login again!', {
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: 'logoutMsg',
    });

    navigate('/login', { replace: true });
  };
  const handleUserActivity = () => {
    setLastActive(new Date());
    setLogoutDialogOpen(false);
  };

  useEffect(() => {
    if (token) {
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keypress', handleUserActivity);
      return () => {
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keypress', handleUserActivity);
      };
    }
  }, [token]);
  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) => {
    // navigate(toRelativeUrl(originalUri || '/dashboard', window.location.origin));
    navigate(toRelativeUrl(originalUri || '/login', window.location.origin));
  };

  function handleError() {
    navigate('/login', { replace: true });
  }
  React.useEffect(() => {
    checkLogoutDialog()
  }, [])

  const checkLogoutDialog = () => {
    setTimeout(() => {
      let authstate = localStorage.getItem('role')
      if (authstate)
        refreshToken()
    }, timer)
  }

  const refreshToken = async () => {
    try {
      const renewToken = await oktaAuth.token.renewTokens();
      await oktaAuth.tokenManager.setTokens(renewToken);
      await callLoginSSO()
      await checkLogoutDialog()
    } catch (error) {
      toast.error('Session time out, please login again!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      navigate('/login', { replace: true });
    }
  }
  const callLoginSSO = () => {
    try {
      oktaAuth.token.getWithoutPrompt().then(async ({ tokens }) => {
        const { tokenManager } = oktaAuth;
        tokenManager.setTokens(tokens);
        const { accessToken } = tokens;
        const metadata = JSON.stringify({
          userAgent: navigator.userAgent,
        });
        if (accessToken) {
          //Call login-sso api and validate the token
          let tokenDetails: {} = {
            metaData: metadata,
            token: accessToken.accessToken,
          };
          await validateToken(tokenDetails);
        }
      });
    } catch (error) {
      toast.error('Session time out, please login again!', {
        position: toast.POSITION.BOTTOM_CENTER
      });
      navigate('/login', { replace: true });
    }
  }
  const validateToken = async (tokenDetails: any) => {
    const response = await axios.post(baseUrl + '/auth/login-sso', tokenDetails)
      .then((response) => {
        if (response.data && response.data.email) {
          localStorage.setItem('userDetails', JSON.stringify(response.data))
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('bearerToken', response.data.token)

        } else { handleError(); }
      }).catch(error => { handleError(); })
  }
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className="App">
        <main>
          <AppRoutes />
        </main>
      </div>
      <Dialog open={logoutDialogOpen}>
        <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '1s50px', background: '#f6f9f8' }}>
          <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Your session is about to expire due to inactivity, Click on cancel to continue with existing session. </Typography>

          <Box sx={{ textAlign: 'right' }}>
            <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { setUserActionConfirm(true); setLogoutDialogOpen(true) }}>
              cancel
            </Button>
            <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { setLogoutDialogOpen(false); navigate('/login') }}>
              Logout
            </Button>
          </Box>
        </Box>
      </Dialog>
      <ToastContainer />
    </Security>

  );
}

export default App;

