import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate, useLocation } from 'react-router-dom'
import Loading from './../Loading';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@mui/material/Dialog';
import spinner from '../Images/zurich.gif'

const OktaAuthCallback = () => {
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  let navigate = useNavigate();
  const location = useLocation()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  //const baseUrl = "http://localhost:8080";
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    setShowSpinner(true)
    oktaAuth.token.getWithoutPrompt().then(async ({ tokens }) => {
      const { tokenManager } = oktaAuth;
      tokenManager.setTokens(tokens);
      const { accessToken } = tokens;
      const metadata = JSON.stringify({
        userAgent: navigator.userAgent,
      });
      if (accessToken) {
        //Call login-sso api and validate the token
        let tokenDetails: {} = {
          metaData: metadata,
          token: accessToken.accessToken,
        };
        await validateToken(tokenDetails);
      }
    });
  }, []);
  const validateToken = async (tokenDetails: any) => {
    const response = await axios.post(baseUrl + '/auth/login-sso', tokenDetails)
      .then((response) => {
        if (response.data && response.data.email) {
          setShowSpinner(false)
          localStorage.setItem('userDetails', JSON.stringify(response.data))
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('bearerToken', response.data.token)
          if (location.pathname === '/oAuth/callback') {
            navigate('/dashboard', { replace: true })
          } else {
            navigate(location.pathname, { replace: true })
          }
        } else { handleError(); }
      }).catch(error => { handleError(); })
  }

  function handleError() {
    setShowSpinner(false)
    toast.error('Something went wrong please try later!', {
      position: toast.POSITION.BOTTOM_CENTER
    });
    navigate('/login', { replace: true });
  }
  return <Dialog className='spinner' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
  //return <Loading />
}

export default OktaAuthCallback


