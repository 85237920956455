import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs';
import summaryImg from "../../Images/Frame.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import m from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  TextField,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { ageCalculator } from '../../components/CommonComponent/AgeCalculator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

type FormValues = {
  sessionId: string,
  priorityNearTermAgainstLongTerm: string,
  "financialObjectives": {
    "id": string,
    "type": string,
    "description": string,
    "priority": number,
    "targetYear": any | undefined,
    "countryCode": string,
    "aspiration": string,
    "amount": number | undefined,
    "duration": any | undefined,
    "dependentId": string
  }[]
}
export const FinancialObjectives = ({ setIsFormSubmited, isClosedSession, isQuoteGenerated, _setPageID, _navigateToDashboard, submitRef }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const marks = [
    {
      value: 10,
      label: '',
    },
    {
      value: 20,
      label: '',
    },
    {
      value: 30,
      label: '',
    },
    {
      value: 40,
      label: '',
    },
    {
      value: 50,
      label: '',
    },
  ];
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [goalError, setGoalError] = React.useState<boolean>(false)
  const [priorityError, setPriorityError] = React.useState<boolean>(false)

  const _getFlag = (value: any) => {
    if (value === undefined || value === '') {
      return false
    } else {
      return true
    }
  }
  const _getLabels = (value: any) => {
    switch (value) {
      case 10:
        return 'Not important to me'
      case 'LOW':
        return 'Not important to me'
      case 20:
        return 'Somewhat important'
      case 'LOW_MEDIUM':
        return 'Somewhat important'
      case 30:
        return 'Important'
      case 'MEDIUM':
        return 'Important'
      case 40:
        return 'Very important'
      case 'MEDIUM_HIGH':
        return 'Very important'
      case 50:
        return 'I must achieve this'
      case 'HIGH':
        return 'I must achieve this'
      default:
        return 'Not important to me'
    }
  }
  const [expanded, setExpanded] = React.useState<string | false>('');
  const [currentDependentId, setCurrentDependentId] = React.useState<string>('')
  const [getDependents, setDependents] = React.useState<any>([])

  const _getYear = (value: any) => {
    let year = 0
    if (value !== '' || value !== undefined) {
      year = new Date(value).getFullYear();
    }
    return year;
  }
  const _getValue = (newValue: any) => {
    let val = 10;
    if (newValue === 'LOW') {
      val = 10
    }
    else if (newValue === 'LOW_MEDIUM') {
      val = 20
    }
    else if (newValue === 'MEDIUM') {
      val = 30
    }
    else if (newValue === 'MEDIUM_HIGH') {
      val = 40
    }
    else if (newValue === 'HIGH') {
      val = 50
    }
    else {
      val = newValue
    }
    return Number(val)
  }
  const formatedDate = (value: any) => {
    if (!value || value.$d === 'Invalid Date') {
      return null;
    }
    if (value.$d !== 'Invalid Date' && value.$d !== undefined) {
      const date = new Date(value.$d);
      return dayjs(date);
    }
    const date = new Date(isNaN(value) ? value : `10-02-${value}`);
    return dayjs(date);
  }
  const [maxTargetYearDate, setMaxTargetYearDate] = React.useState<any>(formatedDate('12/31/2072'));
  const [minTargetYearDate, setMinTargetYearDate] = React.useState<any>(formatedDate('01/01/2028'));
  const _getPriority = (newValue: any) => {
    if (newValue === 10) {
      return 'LOW'
    }
    else if (newValue === 20) {
      return 'LOW_MEDIUM'
    }
    else if (newValue === 30) {
      return 'MEDIUM'
    }
    else if (newValue === 40) {
      return 'MEDIUM_HIGH'
    }
    else if (newValue === 50) {
      return 'HIGH'
    }
    else {
      return 'LOW'
    }

  }


  const [details, setDetails] = React.useState<any>()
  const countryCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const [financialObjectivSummaryDetails, setSummaryDetails] = React.useState<any>()
  const [expenseList, setExpenseList] = React.useState<any>([])
  const [disableUpdate, setDisableUpdate] = React.useState<boolean>(false)
  const [finObjError, setFinObjError] = React.useState<boolean>(false)
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const reponseDetails = await response.json();
      const data = reponseDetails?.financialObjectives
      setDetails(reponseDetails)
      const futureExpenseArrayDetails = reponseDetails?.fourR?.dts?.futureExpenses?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        return obj;
      });
      const financialObjectivesArrayDetails = data?.financialObjectives?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        return obj;
      });
      setExpenseList(futureExpenseArrayDetails)

      setShowSpinner(false)
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        priorityNearTermAgainstLongTerm: data?.priorityNearTermAgainstLongTerm ? data?.priorityNearTermAgainstLongTerm : "",
        financialObjectives: data?.financialObjectives?.length > 0 ? financialObjectivesArrayDetails : []
      }
    },
    'mode': 'all'
  })
    ;
  const { register, control, handleSubmit, formState, reset, formState: { errors }, watch, setValue } = form
  const { fields, append, remove } = useFieldArray({
    name: 'financialObjectives',
    control
  })

  const fetchData = async (shouldUpdate: boolean) => {
    try {
      const response = await axios.get(`${baseUrl}/customers/session?id=` + sessionStorage.getItem('sessiondetail'), requestOptions);
      const reponseDetails = await response.data;
      const detailsData = reponseDetails?.financialObjectives
      const futureExpenseArrayDetails = reponseDetails?.fourR?.dts?.futureExpenses?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        return obj;
      });
      const financialObjectivesArrayDetails = detailsData?.financialObjectives?.map((element: any) => {
        let obj = { ...element }
        if (element?.dependentId) {
          obj.type = element?.type + "|" + element?.dependentId;
        }
        return obj;
      });
      setExpenseList(futureExpenseArrayDetails)
      setDependents(reponseDetails?.personalDetails?.dependents)
      setDetails(reponseDetails)
      const obj = {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        priorityNearTermAgainstLongTerm: detailsData?.priorityNearTermAgainstLongTerm ? detailsData?.priorityNearTermAgainstLongTerm : "",
        financialObjectives: detailsData?.financialObjectives?.length > 0 ? financialObjectivesArrayDetails : []
      };
      if (detailsData) {
        setDisableUpdate(false)
      }
      if (shouldUpdate) {
        reset(obj);
      }
      setShowSpinner(false)
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
      } else {
        setDisableUpdate(false)
        console.error(error);
      }
    }
  };
  /*submit code start */
  const _onSubmit = (data: FormValues, action: string) => {
    if (action === 'summary') {
      setShowSpinner(true)
      setDisableUpdate(true)
    }
    let reponse = { ...data }
    let financialObjectives = [];
    if (reponse?.financialObjectives?.length > 0) {
      financialObjectives = reponse?.financialObjectives?.map((element: any) => {
        let detailsObject = { ...element }
        detailsObject.id === '' && delete detailsObject.id
        delete detailsObject.customerId;
        if (element?.type?.includes("|")) {
          let valueType = element?.type?.split("|")
          if (valueType?.length > 0) {
            detailsObject.type = valueType[0]
          }
        }
        detailsObject.duration = (typeof element?.duration === 'string') ? Number(element?.duration) : element?.duration;
        detailsObject.targetYear = (typeof element?.targetYear === 'number') ? element?.targetYear : _getYear(element?.targetYear)
        detailsObject.priority = (typeof element?.priority === 'number') ? _getPriority(element?.priority) : element?.priority
        return detailsObject
      })
    }
    let payload = { ...reponse, financialObjectives }
    setFinancialObjectives(payload, action)
  }
  React.useEffect(() => {
    _getSummary(false)
  }, [])

  const _getSummary = async (shouldUpdate: boolean) => {
    const response = await fetch(
      baseUrl + '/calculate/fobj-summary?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
    );
    let data = await response.json()
    if (data) {
      fetchData(shouldUpdate);
    }
    setSummaryDetails(data?.calculatedResult)
  }
  const setFinancialObjectives = async (financialObjDetails: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/financial-objectives', financialObjDetails, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });

        if (action === 'next') {
          _setPageID(4)
        } else if (action === 'prev') {
          _setPageID(2)
        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
        else {
          setIsError('#23AA01')
          setPriorityError(false)
          _getSummary(true)
          setExpanded('panel2')
        }
      }).catch((error: any) => {
        setDisableUpdate(false)
        setShowSpinner(false)
        toast.error('Something went wrong please try later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  /* submit code end */
  React.useEffect(() => {
    let status = details?.progress?.financialObjectives?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {
    errors.hasOwnProperty('priorityNearTermAgainstLongTerm') ? setPriorityError(true) : setPriorityError(false)
    errors.hasOwnProperty('financialObjectives') ? setGoalError(true) : setGoalError(false)
  }
  const _deleteFinancialObjectives = (index: number, id: string) => {
    //let {id} = e?.target
    if (id) {
      axios.delete(baseUrl + '/customers/future-expense?id=' + id, requestOptions)
        .then((response) => { remove(index) })
    } else {
      remove(index)
    }
  }
  /* expense calculation for future expense */
  const getHowMuchNeed = (id: any) => {
    let selectedYear = 0
    let { countryCode, targetYear, type, aspiration } = watch(`financialObjectives.${id}`)
    let currency = details?.fourR?.currency || 'USD'
    if (targetYear && type && isClosedSession !== true && isQuoteGenerated !== true) {
      if (targetYear === '' || targetYear === undefined) { selectedYear = new Date('01/01/2027').getFullYear() } else { selectedYear = new Date(targetYear).getFullYear() }
      if (type === 'UNIVERSITY' || type?.includes('UNIVERSITY')) {
        let payload = {
          "country": countryCode || 'AE',
          "currency": currency || 'USD',
          "year": selectedYear
        }
        let amount = window.fetch(baseUrl + '/calculate/university-expense', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
          },
          method: "POST",
          body: JSON.stringify(payload)
        }).then((response) => response.json()).then((data) => {
          setValue(`financialObjectives.${id}.amount`, data?.amount);
        })
        ///dispatch(getUniversityExpense(payload))
      } else if (type === 'PROPERTY' && countryCode && aspiration) {
        let payload = {
          "country": countryCode || 'AE',
          "currency": currency || 'USD',
          "year": selectedYear,
          "priceRange": aspiration || 'LOW'
        }
        window.fetch(baseUrl + '/calculate/property-expense', {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
          },
          method: "POST",
          body: JSON.stringify(payload)
        }).then((response) => response.json()).then((data) => {
          setValue(`financialObjectives.${id}.amount`, data?.amount);
        })
        ///dispatch(getUniversityExpense(payload))
      }
    }
  }

  const _getAlreadyAddedTypeFlag = (value: string) => {
    const expenseArray = watch(`financialObjectives`);
    const futureExpenseArrayDetails = details?.fourR?.dts?.futureExpenses?.map((element: any) => {
      let obj = { ...element }
      if (element?.dependentId) {
        obj.type = element?.type + "|" + element?.dependentId;
      }
      return obj;
    });
    const combineArray = [...expenseArray, ...futureExpenseArrayDetails]
    let arrayValue = combineArray?.filter(element => element?.type?.includes(value))
    return arrayValue?.length > 0
  }
  const getMinTargetDate = (dependentId: string, type: string) => {
    if (type?.includes('MARRIAGE')) {
      let minTargetYear = new Date().getFullYear();
      return formatedDate("01/01/" + minTargetYear.toString());
    } else {
      if (dependentId) {
        let DOB = getDependents.filter((x: any) => x.id == dependentId)[0]?.dob;
        let targetYear: any = ageCalculator(new Date(DOB).getFullYear());
        let minTargetYear: any = ''
        if (targetYear <= 15) {
          minTargetYear = (new Date(DOB).getFullYear()) + 16;
        } else {
          minTargetYear = new Date().getFullYear() + 2;
        }
        return formatedDate("01/01/" + minTargetYear.toString());
      } else {
        let minTargetYear = new Date().getFullYear() + 5;
        return formatedDate("01/01/" + minTargetYear.toString());
      }
    }
  }
  const getMaxTargetDate = (dependentId: string, type: string) => {
    if (type?.includes('MARRIAGE')) {
      return formatedDate("01/01/2090");
    } else {
      if (dependentId) {
        let DOB = getDependents.filter((x: any) => x.id == dependentId)[0]?.dob;
        let targetYear: any = ageCalculator(new Date(DOB).getFullYear());
        let maxTargetYear: any = ''
        if (targetYear <= 15) {
          maxTargetYear = (new Date(DOB).getFullYear()) + 20;
        } else {
          maxTargetYear = "2090"
        }
        return formatedDate("01/01/" + maxTargetYear.toString());
      } else {
        return formatedDate("01/01/2090");
      }
    }
  }
  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Box>
        <h3 style={{ color: '#012169', textAlign: 'left', marginTop: "30px", marginBottom: '40px' }}>{t("FinanceAdviceFor")} {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3>
      </Box>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          <Grid item xs={12}>
            {
              expenseList?.map((field: any, index: number) => {
                return (<Accordion className="accordion" sx={{ marginTop: '25px !important', border: '2px solid', borderColor: isErrorColor }} key={field.id} defaultExpanded={true} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("FinancialObjectivesGoal")}</Typography></Grid>
                      <Grid item xs={6} sx={{ textAlign: 'right' }} >
                        <IconButton aria-label="delete" sx={{ color: '#FF7B6F', padding: '0' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className='accordionPadding'>
                    <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement" disabled>
                            <InputLabel id="GoalType-label">{t("FinancialObjectivesGoalType")}</InputLabel>
                            <Select
                              label="Goal Type"
                              id="GoalType"
                              disabled
                              variant="standard"
                              value={field?.type}
                            >
                              {getDependents.map((dependent: any, index: any) => (
                                dependent.relationId === 4 && (
                                  <MenuItem key={index} value={`UNIVERSITY|` + dependent.id} disabled={_getAlreadyAddedTypeFlag(`UNIVERSITY|` + dependent.id)}>
                                    University - {dependent.name}
                                  </MenuItem>
                                )
                              ))}
                              <MenuItem value='UNIVERSITY'>University</MenuItem>
                              <MenuItem value='PROPERTY'>Property</MenuItem>
                              {getDependents.map((dependent: any, index: any) => (
                                dependent.relationId === 4 && (
                                  <MenuItem key={index} value={`MARRIAGE|` + dependent.id} disabled={_getAlreadyAddedTypeFlag(`MARRIAGE|` + dependent.id)} >
                                    Marriage - {dependent.name}
                                  </MenuItem>
                                )
                              ))}
                              <MenuItem value='MARRIAGE'>Marriage</MenuItem>
                              <MenuItem value='OTHERS'>Others</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("FinancialObjectivesDescription")}
                            type="string"
                            disabled
                            id="description"
                            value={field.description}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="padding-top">
                        {(field.type === 'UNIVERSITY' || field.type?.includes('UNIVERSITY') || field.type === 'PROPERTY' || field.type === 'MARRIAGE') &&
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement" disabled>
                              <InputLabel id="Country-label">{(field.type === 'UNIVERSITY' || field.type?.includes('UNIVERSITY')) ? 'Country of education' : 'Country'}</InputLabel>
                              <Select
                                disabled
                                label="country"
                                id="country"
                                variant="standard"
                                value={field.countryCode}

                              >
                                {
                                  (field.type === 'UNIVERSITY' || field.type?.includes('UNIVERSITY')) ?
                                    (countryCodeList?.length > 0) ?
                                      countryCodeList?.map((listElement: any, index: any) => {
                                        if (listElement.isGoalEducationCountry === true)
                                          return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                      }) : ''
                                    :
                                    (countryCodeList?.length > 0) ?
                                      countryCodeList?.map((listElement: any, index: any) => {
                                        if (listElement.isGoalPropertyCountry === true)
                                          return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                      }) : ''
                                }
                              </Select>
                            </FormControl>
                          </Grid>

                        }

                        {(field.type === 'UNIVERSITY' || field.type?.includes('UNIVERSITY')) &&
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              className="formElement"
                              label={t("FinancialObjectivesDuration")}
                              type="number"
                              onWheel={event => (document.activeElement as HTMLElement).blur()}
                              disabled
                              value={field.duration}
                              id="duration"

                            />
                          </Grid>
                        }

                        {field.type === 'PROPERTY' &&
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement" disabled>
                              <InputLabel id="priceRange-label">Price Range</InputLabel>
                              <Select
                                labelId="PriceRange-label"
                                label="Price Range"
                                disabled
                                type="string"
                                value={field.aspiration}
                                id="PriceRange"
                              >
                                <MenuItem value='LOW'>Low</MenuItem>
                                <MenuItem value='MEDIUM'>Medium</MenuItem>
                                <MenuItem value='HIGH'>High</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        }
                      </Grid>
                      <Grid container className="padding-top">
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement" disabled>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                disabled
                                value={formatedDate(field.targetYear)}
                                minDate={getMinTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))}
                                maxDate={getMaxTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))}
                                label={t("FinancialObjectivesWhenNeed")} views={['year']} slotProps={{ textField: { variant: 'standard' } }} />
                            </LocalizationProvider>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("FinancialObjectivesHowNeed")}
                            type="string"
                            disabled
                            InputLabelProps={{ shrink: _getFlag(field.amount) }}
                            value={field.amount}
                            id="amount"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="padding-top">
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement" disabled>
                            <Typography id="priceRange-label" sx={{ marginBottom: '10px' }}>{t("FinancialObjectivesImportant")}</Typography>
                            <Slider
                              aria-label="Temperature"
                              disabled
                              getAriaValueText={_getLabels}
                              valueLabelDisplay="off"
                              onChange={(e) => field.onChange(e)}
                              value={_getValue(field.priority)}
                              step={10}
                              marks={marks}
                              min={10}
                              max={50}
                            />
                            <Typography sx={{ marginBottom: '10px' }}>{
                              _getLabels(field.priority)
                            }</Typography>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>)
              })
            }
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            {
              fields?.map((field, index) => {
                return (<Accordion className="accordion" sx={{ marginTop: '20px', border: '2px solid', borderColor: (goalError && errors?.financialObjectives?.[index]) ? '#FF7B6F' : isErrorColor }} key={field.id} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("FinancialObjectivesGoal")}</Typography></Grid>
                      <Grid item xs={6} sx={{ textAlign: 'right' }} >
                        <IconButton aria-label="delete" sx={{ color: '#FF7B6F' }} onClick={(e) => _deleteFinancialObjectives(index, watch(`financialObjectives.${index}.id`))}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className='accordionPadding'>
                    <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement">
                            <InputLabel id="GoalType-label">{t("FinancialObjectivesGoalType")}</InputLabel>
                            <Controller
                              control={control}
                              defaultValue={""}
                              {...register(`financialObjectives.${index}.type` as const, {
                                required: "Please select a option"
                              })}
                              // {...register(`financialObjectives.${index}.dependentId` as const, {

                              // })}
                              render={({ field }) => (<Select
                                label="Goal Type"
                                id="GoalType"
                                variant="standard"
                                error={!!errors?.financialObjectives?.[index]?.type}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  getHowMuchNeed(index);
                                  //   let id = e.target.value;
                                  //   let value = id.split('|');
                                  //   setValue(`financialObjectives.${index}.type`, id.split('|')[0]);
                                  //   setCurrentDependentId(id.split('|')[1]);
                                  //   setValue(`financialObjectives.${index}.dependentId`, id.split('|')[1]);
                                  //   if (value[0] == "UNIVERSITY") {
                                  //     let DOB = getDependents.filter((x: any) => x.id == value[1])[0]?.dob;
                                  //     setValue(`financialObjectives.${index}.targetYear`, (new Date(DOB).getFullYear()) + 18);
                                  //     let maxTargetYear: any = (new Date(DOB).getFullYear()) + 38;
                                  //     setMaxTargetYearDate(formatedDate("01/01/" + maxTargetYear.toString()));
                                  //   }
                                  //   field.onChange(e);
                                  // }}
                                  let id = e.target.value;
                                  let depId = '';
                                  let isUniversity = '';
                                  if (id) {
                                    let value = e.target.value;
                                    let valueArray = value?.split("|")
                                    if (valueArray?.length > 0) {
                                      depId = valueArray[1]
                                      isUniversity = valueArray[0] ? valueArray[0] : ''
                                    }
                                  }
                                  setValue(`financialObjectives.${index}.dependentId`, depId);
                                  if (isUniversity === "UNIVERSITY") {
                                    let DOB = getDependents.filter((x: any) => x.id == depId)[0]?.dob;
                                    setValue(`financialObjectives.${index}.targetYear`, (new Date(DOB).getFullYear()) + 18);
                                  }
                                  field.onChange(e);
                                }}
                              >

                                <MenuItem value='UNIVERSITY'>University</MenuItem>
                                <MenuItem value='PROPERTY'>Property</MenuItem>
                                <MenuItem value='GENERAL_SAVINGS'>General Savings</MenuItem>
                              </Select>)} />
                            <FormHelperText sx={{ color: '#ea635c' }}>{(errors?.financialObjectives?.[index]?.type as any)?.message?.toString()}</FormHelperText>

                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("FinancialObjectivesDescription")}
                            type="string"
                            id="description"
                            {...register(`financialObjectives.${index}.description` as const, {
                            })}
                            error={!!errors?.financialObjectives?.[index]?.description}
                            helperText={errors?.financialObjectives?.[index]?.description?.message}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="padding-top">
                        {(watch(`financialObjectives.${index}.type`) === 'UNIVERSITY' || watch(`financialObjectives.${index}.type`)?.includes('UNIVERSITY') || watch(`financialObjectives.${index}.type`) === 'PROPERTY' || watch(`financialObjectives.${index}.type`)?.includes('UNIVERSITY') || watch(`financialObjectives.${index}.type`)?.includes('PROPERTY')) &&
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="Country-label">{(watch(`financialObjectives.${index}.type`) === 'UNIVERSITY' || watch(`financialObjectives.${index}.type`)?.includes('UNIVERSITY')) ? 'Country of education' : 'Country'}</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={""}
                                {...register(`financialObjectives.${index}.countryCode` as const, {
                                  required: "Please select a value"
                                })}
                                render={({ field }) => (<Select
                                  label="country"
                                  id="country"
                                  variant="standard"
                                  error={!!errors?.financialObjectives?.[index]?.countryCode}
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    getHowMuchNeed(index);
                                    if (e.target.value == 'US') {
                                      setValue(`financialObjectives.${index}.duration`, 4);
                                    } else if (e.target.value == 'GB') {
                                      setValue(`financialObjectives.${index}.duration`, 3);
                                    } else {
                                      setValue(`financialObjectives.${index}.duration`, 5)
                                    }
                                  }}
                                >
                                  {
                                    (watch(`financialObjectives.${index}.type`) === 'UNIVERSITY' || watch(`financialObjectives.${index}.type`)?.includes('UNIVERSITY')) ?
                                      (countryCodeList?.length > 0) ?
                                        countryCodeList?.map((listElement: any, index: any) => {
                                          if (listElement.isGoalEducationCountry === true)
                                            return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                        }) : ''
                                      :
                                      (countryCodeList?.length > 0) ?
                                        countryCodeList?.map((listElement: any, index: any) => {
                                          if (listElement.isGoalPropertyCountry === true)
                                            return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                                        }) : ''
                                  }
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.financialObjectives?.[index]?.countryCode?.message?.toString()}</FormHelperText>
                            </FormControl>
                          </Grid>
                        }
                        {(watch(`financialObjectives.${index}.type`) === 'UNIVERSITY' || watch(`financialObjectives.${index}.type`)?.includes('UNIVERSITY')) &&
                          <Grid item xs={6}>
                            <TextField
                              variant="standard"
                              className="formElement"
                              label="No. of years education"
                              InputLabelProps={{ shrink: _getFlag(watch(`financialObjectives.${index}.duration`)) }}
                              defaultValue="5"
                              type="number"
                              onWheel={event => (document.activeElement as HTMLElement).blur()}
                              id="duration"
                              inputProps={{ max: 5 }}
                              {...register(`financialObjectives.${index}.duration` as const, {
                                required: "Please enter years of Education",
                                max: { value: 5, message: "Maximum value allowed is 5" },
                                min: { value: 1, message: "Minimum value allowed is 1" }

                              })}
                              error={!!errors?.financialObjectives?.[index]?.duration}
                              helperText={errors?.financialObjectives?.[index]?.duration?.message?.toString()}
                            />
                          </Grid>
                        }
                        {watch(`financialObjectives.${index}.type`) === 'PROPERTY' &&
                          <Grid item xs={6}>
                            <FormControl variant="standard" className="formElement">
                              <InputLabel id="priceRange-label">Price Range</InputLabel>
                              <Controller
                                control={control}
                                defaultValue={""}
                                {...register(`financialObjectives.${index}.aspiration` as const, {
                                  required: "Please select a value"
                                })}
                                render={({ field }) => (<Select
                                  labelId="PriceRange-label"
                                  label="Price Range"
                                  type="string"
                                  id="PriceRange"
                                  {...field}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    getHowMuchNeed(index);
                                  }}
                                  error={!!errors?.financialObjectives?.[index]?.aspiration}
                                >
                                  <MenuItem value='LOW'>Low</MenuItem>
                                  <MenuItem value='MEDIUM'>Medium</MenuItem>
                                  <MenuItem value='HIGH'>High</MenuItem>
                                </Select>)} />
                              <FormHelperText sx={{ color: '#ea635c' }}>{errors?.financialObjectives?.[index]?.aspiration?.message?.toString()}</FormHelperText>
                            </FormControl>
                          </Grid>
                        }
                      </Grid>
                      <Grid container className="padding-top">
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement">
                            <Controller
                              control={control}
                              {...register(`financialObjectives.${index}.targetYear`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    return (
                                      (fieldValue?.$d != 'Invalid Date' && fieldValue?.$d !== '' && fieldValue !== undefined) || 'Please enter a valid date'
                                    )
                                  },
                                  noFutureDate: (fieldValue) => {
                                    let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                    let minDate = getMinTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))?.toString()
                                    let maxDate = getMaxTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))?.toString()
                                    let minDateYear = new Date(minDate ? minDate : new Date())?.getFullYear();
                                    let maxDateYear = new Date(maxDate ? maxDate : new Date())?.getFullYear();
                                    let enteredDate = (typeof dt === 'number') ? dt : new Date(dt).getFullYear();
                                    return (
                                      (enteredDate <= maxDateYear && enteredDate >= minDateYear) || 'Please enter a year between ' + minDateYear + " - " + maxDateYear
                                    )
                                  }
                                }
                              })
                              }
                              render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  onChange={(e) => {
                                    field.onChange(e);
                                    getHowMuchNeed(index);
                                  }}
                                  value={formatedDate(field?.value)}
                                  minDate={getMinTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))}
                                  maxDate={getMaxTargetDate(watch(`financialObjectives.${index}.dependentId`), watch(`financialObjectives.${index}.type`))}
                                  label={t("FinancialObjectivesWhenNeed")} views={['year']} slotProps={{ textField: { variant: 'standard' } }} />
                              </LocalizationProvider>)} />
                            <FormHelperText sx={{ color: '#ea635c' }}>{errors?.financialObjectives?.[index]?.targetYear?.message?.toString()}</FormHelperText>

                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("FinancialObjectivesHowNeed")}
                            type="string"
                            InputLabelProps={{ shrink: _getFlag(watch(`financialObjectives.${index}.amount`)) }}
                            id="amount"
                            {...register(`financialObjectives.${index}.amount` as const, {
                              required: "Please enter a value",
                              pattern: {
                                value: /^\d+\.?\d{0,2}$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 11,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 100000000,
                                message: "Please enter valid value."
                              },
                              min: {
                                value: 1,
                                message: "Please enter valid value."
                              }
                            })}
                            error={!!errors?.financialObjectives?.[index]?.amount}
                            helperText={errors?.financialObjectives?.[index]?.amount?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container className="padding-top">
                        <Grid item xs={6}>
                          <FormControl variant="standard" className="formElement">
                            <Typography id="priceRange-label" sx={{ marginBottom: '10px' }}>{t("FinancialObjectivesImportant")}</Typography>
                            <Controller
                              control={control}
                              defaultValue={_getValue(watch(`financialObjectives.${index}.priority`))}
                              name={`financialObjectives.${index}.priority`}
                              render={({ field }) => (
                                <Slider
                                  aria-label="Temperature"
                                  getAriaValueText={_getLabels}
                                  valueLabelDisplay="off"
                                  onChange={(e) => field.onChange(e)}
                                  value={_getValue(field?.value)}
                                  step={10}
                                  marks={marks}
                                  min={10}
                                  max={50}
                                />
                              )} />

                          </FormControl>
                          <Typography sx={{ marginBottom: '10px' }}>{

                            _getLabels(watch(`financialObjectives.${index}.priority`))
                          }</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </AccordionDetails>
                </Accordion>)
              })
            }
          </Grid>
          {fields?.length < 5 &&
            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
              <Grid item xs={6}>
                <Button variant="outlined" onClick={() => append({ "id": '', "type": "", "description": "", "priority": 10, "targetYear": '', "countryCode": "", "aspiration": "", "amount": undefined, "duration": 5, "dependentId": "" })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                  {t("FinancialObjectivesAddGoal")}
                </Button>
              </Grid>
            </Grid>
          }
          {/* accordion 2 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: priorityError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("FinancialObjectivesYourPriorities")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography sx={{ fontSize: '12px', paddingBottom: '5px' }}>{t("FinancialObjectivesPriorityQuestion")}</Typography>
                    <FormControl variant="standard" className="formElement" sx={{ paddingTop: '10px' }}>
                      <InputLabel id="priorityNearTermAgainstLongTerm-label" className='formLabel'>{t("FinancialObjectivesSelection")}</InputLabel>
                      <Controller
                        control={control}
                        defaultValue={"" as any}
                        {...register(`priorityNearTermAgainstLongTerm` as const, {
                          required: "Please select a value"
                        })}
                        render={({ field }) => (<Select
                          sx={{ textAlign: "left" }}
                          variant="standard"
                          id="priorityNearTermAgainstLongTerm"
                          label="Which currency will your inputs be in?"
                          error={!!errors?.priorityNearTermAgainstLongTerm}
                          {...field}
                        >
                          <MenuItem value='HIGH'>High</MenuItem>
                          <MenuItem value='MEDIUM'>Medium</MenuItem>
                          <MenuItem value='LOW'>Low</MenuItem>
                        </Select>)} />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 2*/}
          {/* accordion 3 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion
              style={{
                border: "1px solid rgba(157, 157, 157, 0.67)",
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                borderRadius: "21px",
                backgroundColor: "#012169",
                color: "#fff",
                paddingBottom: "60px",
                backgroundImage: `url(${summaryImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
                backgroundSize: "12%"
              }}
              className="accordion" expanded={expanded === 'panel2' || isClosedSession === true || isQuoteGenerated} onChange={() => setExpanded(expanded === 'panel2' ? '' : 'panel2')} defaultExpanded={isClosedSession === true ? true : false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container>
                  <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left', color: '#ffffff' }}>{t("FinancialObjectivesSummary")}</Typography></Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }} >
                    <Button disabled={isClosedSession === true} variant="outlined" type='submit' onClick={handleSubmit((data) => {
                      _onSubmit(data, 'summary');
                    }, (errors) => {
                      _onError();
                    })} sx={{ color: '#fff', border: '1px solid #fff', 'pointerEvents': disableUpdate ? 'none' : 'auto' }} startIcon={<LoopIcon />} > {t("FinanceAdviceUpdate")} </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container>
                  <Grid item xs={12} sx={{ padding: '15px' }}>
                    <List aria-label="mailbox folders">
                      {
                        financialObjectivSummaryDetails?.map((element: any) => {
                          return (<>
                            <Divider className="summary-main-divider" />
                            <ListItem>
                              <Grid item xs={8}>
                                <Typography
                                  sx={{ color: "#fff", fontSize: '16px', lineHeight: '1.5', marginBottom: '0.75em', fontWeight: '700' }}>{element.name}{getDependents.filter((x: any) => x.id == element.dependentId).length > 0 ? "-" + getDependents.filter((x: any) => x.id == element.dependentId)[0]?.name : ""}{element.description ? " (" + element.description + ") Gap" : " Gap"}</Typography>
                              </Grid>
                              <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <Typography sx={{ color: "#fff", fontSize: '16px', lineHeight: '1.5', marginBottom: '0.75em', fontWeight: '700' }}>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{(Math.round(element.amount * 100) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Typography>
                              </Grid>
                            </ListItem>
                          </>)
                        })
                      }
                      <Divider className="summary-divider" />

                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 3*/}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })} startIcon={<ArrowBackIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'left' }}>
              {t("FinanceAdviceBack")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} endIcon={<ArrowForwardIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'right' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form>
        {/* end Form */}
      </Grid>
      <DevTool control={control} />
      <ToastContainer />
    </Box>
  );
};

export default FinancialObjectives;
