import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Header from './Header'
import SideBar from './SideBar'
import Dashboard from './Dashboard'
import { fetchCustomers, fetchCustomersCloseSession, fetchDashboardAPI } from '../redux/GenericDetailsSlice'
import { useSelector, useDispatch } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../redux/hook'
import ShowAllOpenSession from './ShowAllOpenSession'
import ShowAllCloseSession from './ShowAllCloseSession'
import CreateSession from './CreateSession'
import SettingsComponent from './SettingsComponent'
import AnnualReview from './AnnualReview'
import AnnualReviewCloseSession from './AnnualReviewCloseSession'
import SelectExistingAnnualReview from './SelectExistingAnnualReview'
import SecoundaryHeader from './SecoundaryHeader'
import ZenHeadDashboardList from './ZenHeadDashboardList'
import ShowTimelySessionDetails from './ShowTimelySessionDetails'
import UserManual from './UserManual'
import { useNavigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react';
const DashboardContainer = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const role = localStorage.getItem('role')
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [isZenHead, setIsZenHead] = useState<boolean>(false)

  useEffect(() => {
    if (role === 'ZAN_HEAD') {
      setIsZenHead(true)
      dispatch(fetchDashboardAPI());
    }
    else if(role=='APPLICATION_SUPPORT'){
      navigate('/registration')
    }
    else {
      setIsZenHead(false)
    }
  }, [role])
  const setCurrentPage = (pageId: number) => {
    setSelectedPage(pageId)
  }

  useEffect(() => {
    dispatch(fetchCustomers())
    dispatch(fetchCustomersCloseSession())
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} sx={{ padding: '0 !important' }}>
        {!isZenHead &&
          <Grid item xs={2}>
            <SideBar currentPage={selectedPage} setCurrentPage={setCurrentPage} />
          </Grid>
        }
        <Grid item xs={isZenHead === true ? 12 : 10} sx={{ borderBottom: '1px solid #f4f4f4', paddingLeft: isZenHead === true ? '0 !important' : '2% !important' }}>
          {
            isZenHead === true ? <SecoundaryHeader /> : <Header />
          }

          {selectedPage === 1 &&
            <Dashboard setCurrentPage={setCurrentPage} isZenHead={isZenHead} />
          }
          {selectedPage === 2 &&
            <ShowAllOpenSession setCurrentPage={setCurrentPage} />
          }
          {selectedPage === 3 &&
            <ShowAllCloseSession setCurrentPage={setCurrentPage} />
          }
          {selectedPage === 4 &&
            <SettingsComponent />
          }
          {selectedPage === 6 &&
            <CreateSession />
          }
          {selectedPage === 7 &&
            <AnnualReview setCurrentPage={setCurrentPage} />
          }
          {selectedPage === 8 &&
            <AnnualReviewCloseSession setCurrentPage={setCurrentPage} />
          }
          {selectedPage === 9 &&
            <SelectExistingAnnualReview setCurrentPage={setCurrentPage} />
          }
          {selectedPage === 10 &&
            <ZenHeadDashboardList setCurrentPage={setCurrentPage} action={'openSession'} />
          }
          {selectedPage === 11 &&
            <ZenHeadDashboardList setCurrentPage={setCurrentPage} action={'closeSession'} />
          }
          {selectedPage === 12 &&
            <ShowTimelySessionDetails selectedPage={selectedPage} setCurrentPage={setCurrentPage} action={'ptd'} />
          }
          {selectedPage === 13 &&
            <ShowTimelySessionDetails selectedPage={selectedPage} setCurrentPage={setCurrentPage} action={'mtd'} />
          }
          {selectedPage === 14 &&
            <ShowTimelySessionDetails selectedPage={selectedPage} setCurrentPage={setCurrentPage} action={'ytd'} />
          }
          {selectedPage === 5 &&
            <UserManual />
          }

        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardContainer