import React from "react";
import { useFieldArray, Controller } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from 'axios'
import {
  RadioGroup,
  Button,
  FormControlLabel,
  Typography,
  IconButton,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import { useTranslation } from 'react-i18next';
const Benifits = ({ nestIndex, control, details, register, watch, errors }: any) => {
  const { t, i18n } = useTranslation();
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const { fields, remove, append } = useFieldArray({
    control,
    name: `customerPolicies[${nestIndex}].policyBenefits`
  });
  const _deleteBenifits = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/policy-benefit?id=' + id, requestOptions)
        .then((response) => { remove(index) })
    } else {
      remove(index)
    }
  }
  return (
    <Grid container sx={{ border: '1px solid #000', marginTop: '25px', padding: '25px' }}>
      {fields.map((item, k) => {
        return (
          <>
            <Grid key={item.id} container sx={{ marginBottom: '25px' }}>
              <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("PortfolioAssetsBenefits")}</Typography></Grid>
              <Grid item xs={6} sx={{ textAlign: 'right' }} >
                <IconButton aria-label="delete" sx={{ color: '#FF7B6F' }} onClick={() => _deleteBenifits(k, watch(`customerPolicies[${nestIndex}].policyBenefits[${k}].id`))}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" className="formElement">
                  <InputLabel id="investmentType-label">{t("PortfolioAssetsKindPolicy")}</InputLabel>
                  <Controller
                    control={control}
                    {...register(`customerPolicies[${nestIndex}].policyBenefits[${k}].benefitType`, {
                      required: "Please select option"
                    })}
                    render={({ field }) => (<Select
                      sx={{ textAlign: "left" }}
                      variant="standard"
                      error={!!errors?.customerPolicies?.[nestIndex]?.policyBenefits?.[k]?.benefitType}
                      id="investmentType"
                      label="Which currency will your inputs be in?"
                      {...field}
                    >
                      <MenuItem value='LIFECOVER'>Life cover</MenuItem>
                      <MenuItem value='PERMANENTTOTALDISABILITY'>Permanent & Total Disability</MenuItem>
                      <MenuItem value='CRITICALILLNESS'>Critical illness</MenuItem>
                    </Select>)} />
                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerPolicies?.[nestIndex]?.policyBenefits?.[k]?.benefitType?.message || ''} </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" className="formElement">
                  <TextField
                    variant="standard"
                    className="formElement"
                    label={t("PortfolioAssetsSumassured")}
                    type="number"
                    onWheel={event => (document.activeElement as HTMLElement).blur()}
                    id="sumAssured"
                    {...register(`customerPolicies[${nestIndex}].policyBenefits[${k}].sumAssured` as const, {
                      required: "Please enter a value",
                      pattern: {
                        value: /^\d{1,8}(\.\d{0,2})?$/,
                        message: 'Please Enter valid decimal value.'
                      },
                      maxLength: {
                        value: 11,
                        message: "You have exceeded the character limit."
                      },
                      max: {
                        value: 100000000,
                        message: "Please enter valid amount"
                      },
                      min: {
                        value: 1,
                        message: "Please enter valid amount"
                      }
                    })}
                    error={!!errors?.customerPolicies?.[nestIndex]?.policyBenefits?.[k]?.sumAssured}
                    helperText={errors?.customerPolicies?.[nestIndex]?.policyBenefits?.[k]?.sumAssured?.message || ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )
      }
      )}
      <Grid xs={12}>
        <FormHelperText sx={{ color: '#ea635c', display: fields?.length > 0 ? 'none' : 'block' }}> {t("PortfolioAssetsSelect1")}</FormHelperText>
      </Grid>
      <Grid xs={12}>
        <Button variant="outlined"
          onClick={() => append({
            "benefitType": "",
            "id": "",
            "sumAssured": "",
          })
          } startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
          {t("PortfolioAssetsAddBenefits")}
        </Button>
      </Grid>
    </Grid>

  );
};
export default Benifits