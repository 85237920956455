import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import axios from 'axios'
import dayjs, { Dayjs } from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoopIcon from '@mui/icons-material/Loop';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Benifits from './Benifits'
import summaryImg from "../../Images/Frame.png"
import { useNavigate } from 'react-router-dom'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';

type FormValues = {
  sessionId: string,
  "investmentAssets": {
    "id": string,
    "investmentType": string,
    "currentValue": string,
    "annualValue": string,
    "expectedGrowthRate": number | undefined,
    "maturity": any,
    "financialObjective": string
  }[],
  "customerPolicies": {
    "id": string,
    "policyKind": string | undefined,
    "policyInsurer": string | undefined,
    "policyNumber": string | undefined,
    "productType": string | undefined,
    "currentValue": string | undefined,
    "annualPremium": number | undefined,
    "expectedAnnualGrowthRate": number | undefined,
    "expectedMaturityAmount": string | undefined,
    "premiumPaymentTerm": number | undefined,
    "policyStartDate": any,
    "policyExpire": any,
    "policyBenefits": {}[] | undefined
  }[]
}
export const PortfolioAssets = ({ setIsFormSubmited, isQuoteGenerated, isClosedSession, _setPageID, _navigateToDashboard, submitRef }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const requestOptions = {
    headers: headers,
  };
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const _getYear = (value: any) => {
    let year = 0
    if (value !== '' || value !== undefined) {
      year = new Date(value).getFullYear();
    }
    return year;
  }
  const [disableUpdate, setDisableUpdate] = React.useState<boolean>(false)
  const portfolioRate = useSelector((state: any) => state.genericDetails.portFolioGrowthRate);
  const formatedDate = (value: any) => {
    if (!value || value.$d === 'Invalid Date' || value === 1970) {
      return null;
    }
    if (value.$d !== 'Invalid Date' && value.$d !== undefined) {
      const date = new Date(value.$d);
      return dayjs(date);
    }
    const date = new Date(isNaN(value) ? value : `10-02-${value}`);
    return dayjs(date);
  }
  const _getFlag = (value: any) => {
    if (value === undefined || value === '') {
      return false
    } else {
      return true
    }
  }
  const _getDescriptionDetails = (stringvalue: string) => {
    let formatedString = ''
    if (stringvalue === 'GENERAL_SAVINGS') {
      formatedString = 'General Saving'
    } else if (stringvalue === 'PROPERTY') {
      formatedString = 'Property'
    } else if (stringvalue === 'UNIVERSITY') {
      formatedString = 'University'
    } else if (stringvalue === 'MARRIAGE') {
      formatedString = 'Marriage'
    } else if (stringvalue === 'OTHERS') {
      formatedString = 'Others'
    }
    return formatedString
  }
  const [details, setDetails] = React.useState<any>()
  const [isCoApplicant, setIsCoApplicant] = React.useState<boolean>(true)
  const [portFolioSummaryDetails, setportFolioSummaryDetails] = React.useState<any>()
  const [addInvestmentError, setAddInvestmentError] = React.useState<boolean>(false)
  const countryCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [growthRatesDetails, setGrowthRatesArray] = React.useState<any>([])
  const [currentRateArray, setCurrentRateArray] = React.useState<any>()
  const [investmentAssetsError, setInvestmentAssetsError] = React.useState<boolean>(false)
  const [customerPoliciesError, setCustomerPoliciesError] = React.useState<boolean>(false)
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const reponseDetails = await response.json();
      const data = reponseDetails?.portfolioAssets
      setDetails(reponseDetails)
      setShowSpinner(false)
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        investmentAssets: data?.investmentAssets || [{
          "id": "",
          "investmentType": "",
          "currentValue": "",
          "annualValue": "",
          "expectedGrowthRate": undefined,
          "maturity": undefined,
          "financialObjective": ""
        }],
        customerPolicies: data?.customerPolicies || [{
          "id": "",
          "policyKind": "PROTECTION_PLAN",
          "policyInsurer": "",
          "policyNumber": "",
          "productType": "",
          "currentValue": undefined,
          "annualPremium": undefined,
          "expectedMaturityAmount": "",
          "premiumPaymentTerm": undefined,
          "policyStartDate": undefined,
          "policyExpire": undefined,
          "expectedAnnualGrowthRate": undefined,
          "policyBenefits": [{}]
        }]
      }
    },
    mode: 'all'

  })

  const { register, control, handleSubmit, reset, formState, watch, setValue } = form
  const { fields, append, remove } = useFieldArray({
    name: 'investmentAssets',
    control
  })
  const customerPoliciesArray = useFieldArray({
    name: 'customerPolicies',
    control
  })
  const fields1 = customerPoliciesArray.fields
  const append1 = customerPoliciesArray.append
  const remove1 = customerPoliciesArray.remove


  const { errors } = formState;
  React.useEffect(() => {
    _getSummary()
  }, [])
  const _getSummary = async () => {
    const response = await fetch(
      baseUrl + '/calculate/pa-summary?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
    );
    let data = await response.json()
    if (data) {
      fetchData();
    }
    setShowSpinner(false)
    setportFolioSummaryDetails(data?.calculatedResult)
  }


  const _changRates = (id: number) => {
    /* set rates */
    let type = watch(`investmentAssets.${id}.investmentType`)
    if (type) {
      let currentObject = portfolioRate?.filter((element: any) => element?.portfolioType === type)
      if (currentObject?.length > 0) {
        setCurrentRateArray(currentObject?.length > 0 && currentObject[0])
        setGrowthRatesArray(currentObject[0])
        setValue(`investmentAssets.${id}.expectedGrowthRate`, currentObject[0]?.defaultGrowthRate * 100)
      }
    }
  }
  const _returnMinValue = (id: number) => {
    let type = watch(`investmentAssets.${id}.investmentType`)
    if (type) {
      let currentObject = portfolioRate?.filter((element: any) => element?.portfolioType === type)
      if (currentObject?.length > 0) {
        return currentObject[0]?.minGrowthRate * 100
      } else {
        return 0
      }
    }
  }
  const _returnMaxValue = (id: number) => {
    let type = watch(`investmentAssets.${id}.investmentType`)
    if (type) {
      let currentObject = portfolioRate?.filter((element: any) => element?.portfolioType === type)
      if (currentObject?.length > 0) {
        return currentObject[0]?.maxGrowthRate * 100
      } else {
        return 100
      }
    }
  }

  const handleIncrementRate = (id: number) => {
    let type = watch(`investmentAssets.${id}.investmentType`)
    let currentObject = []
    if (type) {
      currentObject = portfolioRate?.filter((element: any) => element?.portfolioType === type)
      if (currentObject?.length > 0) {
        setCurrentRateArray(currentObject?.length > 0 && currentObject[0])
      }
    }
    let currentRate = Number(watch(`investmentAssets.${id}.expectedGrowthRate`))
    if (currentRate >= (currentObject[0]?.minGrowthRate * 100) && currentRate <= (currentObject[0]?.maxGrowthRate * 100) && currentObject?.length > 0) {
      let sumvalue = currentRate ? Number((currentRate + 1)) : 0;
      if (sumvalue >= (currentObject[0]?.minGrowthRate * 100) && sumvalue <= (currentObject[0]?.maxGrowthRate * 100)) {
        setValue(`investmentAssets.${id}.expectedGrowthRate`, sumvalue)
      } else {
        setValue(`investmentAssets.${id}.expectedGrowthRate`, currentObject[0]?.maxGrowthRate * 100)
      }
    } else {
      setValue(`investmentAssets.${id}.expectedGrowthRate`, currentRateArray[0]?.maxGrowthRate * 100)
    }
  }
  const handleIncrementRatePolicy = (id: number) => {
    let currentRate = Number(watch(`customerPolicies.${id}.expectedAnnualGrowthRate`))
    if (currentRate >= 0 && currentRate <= 6.5) {
      let sumvalue = Number((currentRate + 1));
      if (sumvalue >= 0 && sumvalue <= 6.5) {
        setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, sumvalue)
      } else {
        setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, 6.5)
      }
    } else {
      setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, 6.5)
    }
  }
  const handleMinusRatePolicy = (id: number) => {

    let currentRate = Number(watch(`customerPolicies.${id}.expectedAnnualGrowthRate`))
    if (currentRate >= 0 && currentRate <= 6.5) {
      let minvalue = Number((currentRate - 1));
      if (minvalue <= 6.5 && minvalue >= 0) {
        setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, minvalue)
      } else {
        setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, 0)
      }
    } else {
      setValue(`customerPolicies.${id}.expectedAnnualGrowthRate`, 0)
    }
  }

  const handleMinusRate = (id: number) => {
    let type = watch(`investmentAssets.${id}.investmentType`)
    let currentObject = []
    if (type) {
      currentObject = portfolioRate?.filter((element: any) => element?.portfolioType === type)
      if (currentObject?.length > 0) {
        setCurrentRateArray(currentObject?.length > 0 && currentObject[0])
      }
    }
    let currentRate = Number(watch(`investmentAssets.${id}.expectedGrowthRate`))
    if (currentRate >= (currentObject[0]?.minGrowthRate * 100) && currentRate <= (currentObject[0]?.maxGrowthRate * 100) && currentObject?.length > 0) {
      let minvalue = currentRate ? Number((currentRate - 1)) : 0;
      if (minvalue <= (currentObject[0]?.maxGrowthRate * 100) && minvalue > (currentObject[0]?.minGrowthRate * 100)) {
        setValue(`investmentAssets.${id}.expectedGrowthRate`, minvalue)
      } else {
        setValue(`investmentAssets.${id}.expectedGrowthRate`, currentObject[0]?.minGrowthRate * 100)
      }
    } else {
      setValue(`investmentAssets.${id}.expectedGrowthRate`, currentObject[0]?.minGrowthRate * 100)
    }
  }


  /*submit code start */
  const _onSubmit = (data: FormValues, action: any) => {
    if (action === 'summary') {
      setShowSpinner(true)
      setDisableUpdate(true)

    }
    if (data?.investmentAssets?.length <= 0) {
      setAddInvestmentError(true)
    } else {
      setAddInvestmentError(false)
    }
    let investmentAssets = data.investmentAssets?.map((element: any) => {
      let detailsObject = { ...element }
      detailsObject.id === '' && delete detailsObject.id
      delete detailsObject.customerId
      detailsObject.expectedGrowthRate = detailsObject?.expectedGrowthRate ? detailsObject?.expectedGrowthRate : 0
      detailsObject.maturity = (typeof element?.maturity === 'number') ? element?.maturity : _getYear(element?.maturity)
      return detailsObject
    })

    let customerPolicies = data.customerPolicies?.map((element: any) => {
      let detailsObject = { ...element }
      detailsObject.id === '' && delete detailsObject.id
      delete detailsObject.customerId
      detailsObject.policyExpire = (typeof element?.policyExpire === 'number') ? element?.policyExpire : _getYear(element?.policyExpire)
      detailsObject.policyBenefits = detailsObject?.policyBenefits?.map((element: any) => {
        let benifit = { ...element }
        benifit.id === '' && delete benifit.id
        return benifit
      })
      return detailsObject
    })
    let payload = { ...data, investmentAssets, customerPolicies }
    setPortfolioAssets(payload, action)
  }
  const setPortfolioAssets = async (PortfolioAssets: {}, action: string) => {
    const response = await axios.post(baseUrl + '/customers/portfolio-assets', PortfolioAssets, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });

        if (action === 'next') {
          _setPageID(5)
        } else if (action === 'prev') {
          _setPageID(3)
        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
        else {
          setIsError('#23AA01')
          _getSummary()
          setExpanded('panel2')
        }
        setAddInvestmentError(false)
      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login')
        } else {
          _onError()
          setShowSpinner(false)
          setDisableUpdate(false)
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })
    return response
  }
  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions);
      const reponseDetails = response.data;
      const data = reponseDetails?.portfolioAssets
      setDetails(reponseDetails)
      let obj = {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        investmentAssets: data?.investmentAssets || [{
          "id": "",
          "investmentType": "",
          "currentValue": "",
          "annualValue": "",
          "expectedGrowthRate": undefined,
          "maturity": undefined,
          "financialObjective": ""
        }],
        customerPolicies: data?.customerPolicies || [{
          "id": "",
          "policyKind": "PROTECTION_PLAN",
          "policyInsurer": "",
          "policyNumber": "",
          "productType": "",
          "currentValue": undefined,
          "annualPremium": undefined,
          "expectedMaturityAmount": "",
          "premiumPaymentTerm": undefined,
          "policyStartDate": undefined,
          "policyExpire": undefined,
          "policyBenefits": [{}]
        }]
      }
      if (data) {
        setDisableUpdate(false)
      }
      reset(obj);

    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
      } else {
        toast.error('Something went wrong, please try again later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
  }
  /* submit code end */
  React.useEffect(() => {
    let status = details?.progress?.portfolioAssets?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {

    errors.hasOwnProperty('investmentAssets') ? setInvestmentAssetsError(true) : setInvestmentAssetsError(false)
    errors.hasOwnProperty('customerPolicies') || addInvestmentError ? setCustomerPoliciesError(true) : setCustomerPoliciesError(false)
  }
  const _deleteInvestment = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/investment-asset?id=' + id, requestOptions)
        .then((response) => { remove(index) })
    } else {
      remove(index)
    }
  }
  const _deletePolicy = (index: number, id: string) => {
    if (id) {
      axios.delete(baseUrl + '/customers/policy?id=' + id, requestOptions)
        .then((response) => { remove1(index) })
    } else {
      remove1(index)
    }
  }
  const _getChildName = (dependentId: string) => {
    let childName = details?.personalDetails?.dependents.filter((element: any) => element?.id === dependentId)
    return (childName?.length > 0) ? "- " + childName[0]?.firstName + " " + childName[0]?.lastName : ''
  }

  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Box>
        <h3 style={{ color: '#012169', textAlign: 'left', marginTop: "30px", marginBottom: '40px' }}>{t("FinanceAdviceFor")}  {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3>
      </Box>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          {addInvestmentError && <Grid item xs={12}>  <FormHelperText sx={{ color: '#ea635c' }}>Please add at least 1 item</FormHelperText></Grid>}
          <Grid item xs={12}>
            {
              fields?.map((field, index) => {
                return (<Accordion key={field.id} className="accordion" sx={{ border: '2px solid', borderColor: (errors?.investmentAssets?.[index] !== undefined) ? '#FF7B6F' : isErrorColor, marginTop: '20px' }} defaultExpanded={isClosedSession || isQuoteGenerated === true ? true : false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("PortfolioAssetSaveInvest")}</Typography></Grid>
                      <Grid item xs={6} sx={{ textAlign: 'right' }} >
                        <IconButton aria-label="delete" sx={{ color: '#FF7B6F', padding: '0' }} onClick={() => _deleteInvestment(index, watch(`investmentAssets.${index}.id`))}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className='accordionPadding'>
                    <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="investmentType-label">{t("PortfolioAssetsInvestmenAasset")}</InputLabel>
                          <Controller
                            control={control}
                            {...register(`investmentAssets.${index}.investmentType`, {
                              required: "Please select option"
                            })}
                            render={({ field }) => (<Select
                              sx={{ textAlign: "left" }}
                              variant="standard"
                              id="investmentType"
                              label="Which currency will your inputs be in?"
                              error={!!errors?.investmentAssets?.[index]?.investmentType}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                _changRates(index);
                              }}
                            >
                              <MenuItem value='EQUITYFUND'>Equity fund</MenuItem>
                              <MenuItem value='INCOMEFUND'>Income fund</MenuItem>
                              <MenuItem value='BALANCEFUND'>Balanced fund</MenuItem>
                              <MenuItem value='STOCKS'>Stocks</MenuItem>
                              <MenuItem value='BONDS'>Bonds</MenuItem>
                              <MenuItem value='PROPERTYINVESTMENT'>Property (Investment)</MenuItem>
                              <MenuItem value='DEPOSITS'>Deposits</MenuItem>
                              <MenuItem value='CASH'>Cash</MenuItem>
                              <MenuItem value='OTHERS'>Others</MenuItem>
                            </Select>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.investmentAssets?.[index]?.investmentType?.message?.toString()}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            label={t("PortfolioAssetsCurrentValue")}
                            type="string"
                            id="currentValue"
                            {...register(`investmentAssets.${index}.currentValue` as const, {
                              required: "Please enter a value",
                              pattern: {
                                value: /^\d{1,8}(\.\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 11,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 100000000,
                                message: "Please enter valid amount"
                              },
                              min: {
                                value: 1,
                                message: "Please enter valid amount"
                              }
                            })}
                            error={!!errors?.investmentAssets?.[index]?.currentValue}
                            helperText={errors?.investmentAssets?.[index]?.currentValue?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {(watch(`investmentAssets.${index}.investmentType`) !== 'PROPERTYINVESTMENT' && watch(`investmentAssets.${index}.investmentType`) !== 'CASH') &&
                        <Grid item xs={6} className="padding-top">
                          <FormControl variant="standard" className="formElement">
                            <TextField
                              variant="standard"
                              label={t("PortfolioAssetsContributeAnnually")}
                              type="string"
                              id="currentValue"
                              defaultValue={"" as any}
                              {...register(`investmentAssets.${index}.annualValue` as const, {
                                pattern: {
                                  value: /^\d{1,8}(\.\d{0,2})?$/,
                                  message: 'Please Enter valid decimal value.'
                                },
                                maxLength: {
                                  value: 11,
                                  message: "You have exceeded the character limit."
                                },
                                max: {
                                  value: 100000000,
                                  message: "Please enter valid amount"
                                },
                                min: {
                                  value: 0,
                                  message: "Please enter valid amount"
                                }
                              })}
                              error={!!errors?.investmentAssets?.[index]?.annualValue}
                              helperText={errors?.investmentAssets?.[index]?.annualValue?.message}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      }
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            label={t("PortfolioAssetsGrowthRate")}
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            disabled={watch(`investmentAssets.${index}.investmentType`) === 'CASH'}
                            placeholder="0.00%"
                            defaultValue=""
                            InputLabelProps={{ shrink: _getFlag(watch(`investmentAssets.${index}.expectedGrowthRate`)) }}
                            id="expectedGrowthRate"
                            {...register(`investmentAssets.${index}.expectedGrowthRate`, {
                              pattern: {
                                value: /^\d+\.?\d{0,2}$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 5,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: _returnMaxValue(index) || 100,
                                message: `Please enter value between ${_returnMinValue(index)} to ${_returnMaxValue(index)}`
                              },
                              min: {
                                value: _returnMinValue(index) || 0,
                                message: `Please enter value between ${_returnMinValue(index)} to ${_returnMaxValue(index)}`
                              }
                            }
                            )}
                            error={!!errors?.investmentAssets?.[index]?.expectedGrowthRate}
                            helperText={errors?.investmentAssets?.[index]?.expectedGrowthRate?.message}
                            InputProps={{
                              sx: {
                                "&::placeholder": {
                                  color: "green"
                                }
                              },
                              endAdornment: (
                                <InputAdornment position="start">
                                  <IconButton
                                    aria-label="add"
                                    onClick={() => handleIncrementRate(index)}
                                    sx={{
                                      color: "green",
                                    }}
                                  >
                                    <AddCircleOutlineOutlinedIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="minus"
                                    onClick={() => handleMinusRate(index)}
                                    sx={{
                                      color: "red",
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {(watch(`investmentAssets.${index}.investmentType`) !== 'PROPERTYINVESTMENT' && watch(`investmentAssets.${index}.investmentType`) !== 'CASH') &&
                        <Grid item xs={6} className="padding-top">
                          <FormControl variant="standard" className="formElement">
                            <Controller
                              control={control}
                              {...register(`investmentAssets.${index}.maturity`, {
                                validate: {
                                  noFutureDate: (fieldValue) => {
                                    if (fieldValue !== undefined && fieldValue !== null && fieldValue !== 1970) {
                                      let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                      let currentDate = (new Date().getFullYear()) - 1;
                                      let enteredDate = (typeof dt === 'number') ? dt : new Date(dt).getFullYear()
                                      return (
                                        (enteredDate > currentDate) || 'Please enter a future year'
                                      )
                                    }
                                  }
                                }
                              })
                              }
                              render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  onChange={(e) => field.onChange(e)}
                                  minDate={formatedDate('01/01/2023')}
                                  maxDate={formatedDate('12/31/2073')}
                                  value={formatedDate(field.value)}
                                  label={t("PortfolioAssetsMaturityliquidation")} views={['year']} slotProps={{ textField: { variant: 'standard' } }} />
                              </LocalizationProvider>)} />
                            <FormHelperText sx={{ color: '#ea635c' }}>{errors?.investmentAssets?.[index]?.maturity?.message?.toString()}</FormHelperText>
                          </FormControl>
                        </Grid>
                      }
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <InputLabel id="investmentType-label">{t("PortfolioAssetsfinancialObjective")}</InputLabel>
                          <Controller
                            control={control}
                            {...register(`investmentAssets.${index}.financialObjective`, {
                            })}
                            render={({ field }) => (<Select
                              sx={{ textAlign: "left" }}
                              variant="standard"
                              id="financialObjective"
                              label="Which currency will your inputs be in?"
                              error={!!errors?.investmentAssets?.[index]?.financialObjective}
                              {...field}
                            >
                              {details?.financialObjectives?.financialObjectives?.map((element: any) => {
                                return <MenuItem key={element.id} value={element.id}>{element.targetYear + " : " + _getDescriptionDetails(element.type)}  {element.type?.includes('UNIVERSITY') || element.type?.includes('MARRIAGE') ? _getChildName(element?.dependentId) : ''} {element.description ? " ( " + element.description + " ) " : ''}</MenuItem>
                              })}
                              {details?.fourR?.dts?.futureExpenses?.map((element: any) => {
                                return <MenuItem key={element.id} value={element.id}>{element.targetYear + " : " + _getDescriptionDetails(element.type)}  {element.type?.includes('UNIVERSITY') || element.type?.includes('MARRIAGE') ? _getChildName(element?.dependentId) : ''} {element.description ? " ( " + element.description + " ) " : ''}</MenuItem>
                              })}
                              <MenuItem key={'100'} value='RETIREMENT'>Retirement</MenuItem>
                              <MenuItem key={'101'} value='NOTAPPLICABLE'>Not Applicable</MenuItem>
                            </Select>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.investmentAssets?.[index]?.financialObjective?.message?.toString()}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>)
              })
            }
          </Grid>
          {fields?.length < 10 &&
            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
              <Grid item xs={6}>
                <Button variant="outlined" onClick={() => append({
                  "id": "",
                  "investmentType": "",
                  "currentValue": "",
                  "annualValue": "",
                  "expectedGrowthRate": undefined,
                  "maturity": undefined,
                  "financialObjective": ""
                })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                  {t("PortfolioAssetsAddInvestment")}
                </Button>
              </Grid>
            </Grid>
          }
          {/* accordion 2 start */}
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            {
              fields1?.map((field, index) => {
                return (<Accordion key={field.id} className="accordion" sx={{ border: '2px solid', borderColor: (errors?.customerPolicies?.[index] !== undefined || (fields1 && fields1?.[index]?.policyBenefits?.length === 0 && (watch(`customerPolicies.${index}.policyKind`) !== 'GUARANTEED_SAVING_PLAN'))) ? '#FF7B6F' : isErrorColor, marginTop: '20px' }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container>
                      <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left' }}>{t("PortfolioAssetsCoveredQuestion")}</Typography></Grid>
                      <Grid item xs={6} sx={{ textAlign: 'right' }} >
                        <IconButton aria-label="delete" sx={{ color: '#FF7B6F', padding: '0' }} onClick={() => _deletePolicy(index, watch(`customerPolicies.${index}.id`))}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails className='accordionPadding'>
                    <Grid container sx={{ textAlign: 'left', padding: '0 16px' }}>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement" error={!!errors?.customerPolicies?.[index]?.policyKind}>
                          <InputLabel id="investmentType-label">{t("PortfolioAssetsPolicyQuestion")}</InputLabel>
                          <Controller
                            control={control}
                            {...register(`customerPolicies.${index}.policyKind`, {
                            })}
                            render={({ field }) => (<Select
                              sx={{ textAlign: "left" }}
                              variant="standard"
                              id="policyKind"
                              label="What kind of policy is it?"
                              error={!!errors?.customerPolicies?.[index]?.policyKind}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            > <MenuItem key={'100'} value='PROTECTION_PLAN'>Protection Plan</MenuItem>
                              <MenuItem key={'101'} value='GUARANTEED_SAVING_PLAN'>Guaranteed Saving Plan</MenuItem>
                              <MenuItem key={'101'} value='UNIT_LINKED_PLAN'>Unit Linked Plan</MenuItem>
                            </Select>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerPolicies?.[index]?.policyKind?.message?.toString()}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            label={t("PortfolioAssetsInsuresPolicy")}
                            type="string"
                            id="policyInsurer"
                            {...register(`customerPolicies.${index}.policyInsurer` as const, {
                            })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            label={t("PortfolioAssetsPolicyNumber")}
                            type="string"
                            id="policyNumber"
                            {...register(`customerPolicies.${index}.policyNumber` as const, {
                            })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement" error={!!errors?.customerPolicies?.[index]?.productType}>
                          <InputLabel id="investmentType-label">{t("PortfolioAssetsProducttype")}</InputLabel>
                          <Controller
                            control={control}
                            {...register(`customerPolicies.${index}.productType`, {
                            })}
                            render={({ field }) => (<Select
                              sx={{ textAlign: "left" }}
                              variant="standard"
                              id="productType"
                              label={t("PortfolioAssetsPolicyQuestion")}
                              error={!!errors?.customerPolicies?.[index]?.productType}
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            >
                              <MenuItem key={'200'} value="WOL">Whole of life</MenuItem>
                              <MenuItem key={'201'} value="TERM">Term</MenuItem>
                            </Select>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerPolicies?.[index]?.productType?.message?.toString()}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("PortfolioAssetsCurrentValue")}
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            placeholder="0.00"
                            defaultValue=""
                            id="currentValue"
                            {...register(`customerPolicies.${index}.currentValue`, {
                              pattern: {
                                value: /^\d{1,8}(\.\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 11,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 100000000,
                                message: "Please enter valid amount"
                              },
                              min: {
                                value: 0,
                                message: "Please enter valid amoun"
                              }
                            }
                            )}
                            error={!!errors?.customerPolicies?.[index]?.currentValue}
                            helperText={errors?.customerPolicies?.[index]?.currentValue?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            label={t("PortfolioAssetsPremiumPay")}
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            placeholder="0.00"
                            defaultValue=""
                            id="annualPremium"
                            {...register(`customerPolicies.${index}.annualPremium`, {
                              pattern: {
                                value: /^\d{1,8}(\.\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 11,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 100000000,
                                message: "Please enter valid amount"
                              },
                              min: {
                                value: 0,
                                message: "Please enter valid amount"
                              }
                            }
                            )}
                            error={!!errors?.customerPolicies?.[index]?.annualPremium}
                            helperText={errors?.customerPolicies?.[index]?.annualPremium?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {(watch(`customerPolicies.${index}.policyKind`) === 'UNIT_LINKED_PLAN') ?
                        <Grid item xs={6} className="padding-top">
                          <FormControl variant="standard" className="formElement">
                            <TextField
                              variant="standard"
                              label="What is the expected annual growth rate?"
                              type="number"
                              onWheel={event => (document.activeElement as HTMLElement).blur()}
                              placeholder="0.00%"
                              defaultValue=""
                              InputLabelProps={{ shrink: _getFlag(watch(`customerPolicies.${index}.expectedAnnualGrowthRate`)) }}
                              id="expectedGrowthRate"
                              {...register(`customerPolicies.${index}.expectedAnnualGrowthRate`, {
                                validate: {
                                  noValue: (fieldValue) => {
                                    if (watch(`customerPolicies.${index}.policyKind`) === 'UNIT_LINKED_PLAN') {
                                      return (
                                        (fieldValue !== undefined && fieldValue !== null) || 'Please enter a value'
                                      )
                                    }
                                  }
                                },
                                pattern: {
                                  value: /^\d+\.?\d{0,2}$/,
                                  message: 'Please Enter valid decimal value.'
                                },
                                maxLength: {
                                  value: 5,
                                  message: "You have exceeded the character limit."
                                },
                                max: {
                                  value: 6.5,
                                  message: `Please enter value between 0 to 6.5`
                                },
                                min: {
                                  value: 0,
                                  message: `Please enter value between 0 to 6.5`
                                }
                              }
                              )}
                              error={!!errors?.customerPolicies?.[index]?.expectedAnnualGrowthRate}
                              helperText={errors?.customerPolicies?.[index]?.expectedAnnualGrowthRate?.message?.toString()}
                              InputProps={{
                                sx: {
                                  "&::placeholder": {
                                    color: "green"
                                  }
                                },
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <IconButton
                                      aria-label="add"
                                      onClick={() => handleIncrementRatePolicy(index)}
                                      sx={{
                                        color: "green",
                                      }}
                                    >
                                      <AddCircleOutlineOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="minus"
                                      onClick={() => handleMinusRatePolicy(index)}
                                      sx={{
                                        color: "red",
                                      }}
                                    >
                                      <RemoveCircleOutlineIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid> : ''
                      }
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <TextField
                            variant="standard"
                            className="formElement"
                            label={t("PortfolioAssetsPremiumPaymentTerm")}
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            placeholder="0.00"
                            defaultValue=""
                            id="premiumPaymentTerm"
                            {...register(`customerPolicies.${index}.premiumPaymentTerm`, {
                              pattern: {
                                value: /^\d{1,8}(\.\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 11,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 100000000,
                                message: "Please enter valid amount"
                              },
                              min: {
                                value: 0,
                                message: "Please enter valid amoun"
                              }
                            }
                            )}
                            error={!!errors?.customerPolicies?.[index]?.premiumPaymentTerm}
                            helperText={errors?.customerPolicies?.[index]?.premiumPaymentTerm?.message}
                            InputProps={{
                              sx: {
                                "&::placeholder": {
                                  color: "#000"
                                }
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  {'Years'}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <Controller
                            control={control}
                            {...register(`customerPolicies.${index}.policyStartDate`, {
                              validate: {
                                noFutureDate: (fieldValue) => {
                                  if (fieldValue !== undefined) {
                                    let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                                    let currentDate = (new Date());
                                    let enteredDate = (typeof dt === 'number') ? dt : new Date(dt)
                                    if (fieldValue && fieldValue !== '' && fieldValue?.$d != 'Invalid Date' && fieldValue?.$d !== '' && fieldValue !== undefined) {
                                      return (
                                        (enteredDate < currentDate) || 'Please enter a valid date'
                                      )
                                    }
                                  }
                                }
                              }
                            })
                            }
                            render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                {...register(`customerPolicies.${index}.policyStartDate`)}
                                onChange={(e) => field.onChange(e)}
                                value={formatedDate(field.value)}
                                minDate={formatedDate('12/31/1903')}
                                maxDate={dayjs(new Date())}
                                label={t("PortfolioAssetsPolicyStart")}
                                slotProps={{ textField: { variant: 'standard' } }} />
                            </LocalizationProvider>)} />
                          <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerPolicies?.[index]?.policyStartDate?.message?.toString()}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="padding-top">
                        <FormControl variant="standard" className="formElement">
                          <Controller
                            control={control}
                            {...register(`customerPolicies.${index}.policyExpire`, {
                              // validate: {
                              //   noFutureDate: (fieldValue) => {
                              //     if (fieldValue !== undefined && fieldValue !== 1970 && fieldValue !== null) {
                              //       let dt = fieldValue?.$d ? fieldValue?.$d : fieldValue
                              //       let currentDate = (new Date().getFullYear()) - 1;
                              //       let enteredDate = (typeof dt === 'number') ? dt : new Date(dt).getFullYear()
                              //       return (
                              //         (enteredDate > currentDate) || 'Please enter valid year'
                              //       )
                              //     }
                              //   }
                              // }
                            })
                            }
                            render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                onChange={(e) => field.onChange(e)}
                                value={formatedDate(field.value)}
                                label={t("PortfolioAssetsPolicyExpire")}
                                //minDate={formatedDate('01/12/2023')}
                                maxDate={formatedDate('10/11/2123')}
                                views={['year']}
                                slotProps={{ textField: { variant: 'standard' } }} />
                            </LocalizationProvider>)} />
                          {/* <FormHelperText sx={{ color: '#ea635c' }}>{errors?.customerPolicies?.[index]?.policyExpire?.message?.toString()}</FormHelperText> */}
                        </FormControl>
                      </Grid>
                      {(watch(`customerPolicies.${index}.policyKind`) !== 'GUARANTEED_SAVING_PLAN') ?
                        <Benifits nestIndex={index} {...{ control, register }} details={details} watch={watch} errors={errors} />
                        :
                        <Grid item xs={6} className="padding-top">
                          <FormControl variant="standard" className="formElement">
                            <TextField
                              variant="standard"
                              className="formElement"
                              label="What is the expected maturity amount?"
                              type="number"
                              onWheel={event => (document.activeElement as HTMLElement).blur()}
                              placeholder="0.00"
                              defaultValue=""
                              id="expectedMaturityAmount"
                              {...register(`customerPolicies.${index}.expectedMaturityAmount`, {
                                pattern: {
                                  value: /^\d{1,8}(\.\d{0,2})?$/,
                                  message: 'Please Enter valid decimal value.'
                                },
                                maxLength: {
                                  value: 11,
                                  message: "You have exceeded the character limit."
                                },
                                max: {
                                  value: 100000000,
                                  message: "Please enter valid amount"
                                },
                                min: {
                                  value: 0,
                                  message: "Please enter valid amount"
                                }
                              }
                              )}
                              error={!!errors?.customerPolicies?.[index]?.expectedMaturityAmount}
                              helperText={errors?.customerPolicies?.[index]?.expectedMaturityAmount?.message}
                              InputProps={{
                                sx: {
                                  "&::placeholder": {
                                    color: "#000"
                                  }
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {details?.fourR?.currency ? details?.fourR?.currency : 'USD '}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>)
              })
            }
          </Grid>
          {/* accordion 2 end */}
          {fields1?.length < 10 &&
            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
              <Grid item xs={6}>
                <Button variant="outlined" onClick={() => append1({
                  "id": "",
                  "policyKind": "",
                  "policyInsurer": "",
                  "policyNumber": "",
                  "productType": "",
                  "currentValue": undefined,
                  "annualPremium": undefined,
                  "expectedMaturityAmount": "",
                  "premiumPaymentTerm": undefined,
                  "policyStartDate": undefined,
                  "expectedAnnualGrowthRate": undefined,
                  "policyExpire": undefined,
                  "policyBenefits": []
                })} startIcon={<AddCircleOutlineOutlinedIcon />} sx={{ border: '0 !important', padding: 0 }}>
                  {t("PortfolioAssetsAddPolicy")}
                </Button>
              </Grid>
            </Grid>
          }
          {/* accordion 3 */}
          {/* summary section start */}
          <Grid style={{ marginTop: "50px", textAlign: "left" }}>
            <Accordion
              style={{
                border: "1px solid rgba(157, 157, 157, 0.67)",
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                borderRadius: "21px",
                backgroundColor: "#012169",
                color: "#fff",
                paddingBottom: "60px",
                backgroundImage: `url(${summaryImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
                backgroundSize: "12%"
              }}
              expanded={expanded === 'panel2' || isClosedSession === true || isQuoteGenerated} onChange={handleChange('panel2')}
              defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-control="about-you"
                id="about-you"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <Typography sx={{ padding: "0 16px", textAlign: "left" }}>
                      {t("PortfolioAssetsSummary4Risks")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Button disabled={isClosedSession === true || isQuoteGenerated} variant="outlined" startIcon={<LoopIcon />} sx={{ border: "1px solid #fff", color: "#fff", marginRight: "40px", 'pointerEvents': disableUpdate ? 'none' : 'auto' }} onClick={handleSubmit((data) => {
                      _onSubmit(data, 'summary');
                    }, (errors) => {
                      _onError();
                    })}  >
                      {" "}
                      {t("FinanceAdviceUpdate")}{" "}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={10}
                  sx={{ textAlign: "left", padding: "12px 16px" }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography
                      sx={{
                        borderTop: "5px solid #21AFE5",
                        color: "#21AFE5",
                        paddingTop: "5px",
                        fontWeight: 600,
                      }}
                    >
                      {t("FourRisksDyingTooSoon")}
                    </Typography>
                    <Grid
                      sx={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={8}><Typography>{t("FourRisksLifeProtectionNeeds")}</Typography></Grid>

                        <Grid item xs={4} className="align-right"><Typography>
                          {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds ? (portFolioSummaryDetails?.dyingTooSoon?.lifeProtectionNeeds).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                        </Typography></Grid>
                        <Grid item xs={8}>
                          <Typography sx={{ marginTop: "10px" }}>
                            {t("PortfolioAssetsCurrentLifeInsurance")}
                          </Typography></Grid>
                        <Grid item xs={4} className="align-right">
                          <Typography sx={{ marginTop: "10px" }}>
                            {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.dyingTooSoon?.currentLifeInsuranceAndAssets ? (portFolioSummaryDetails?.dyingTooSoon?.currentLifeInsuranceAndAssets).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                          </Typography></Grid>
                        {isCoApplicant &&
                          <>
                            <Grid item xs={8}><Typography sx={{ marginTop: "10px" }}>
                              {t("PortfolioAssetsSalaryRatio")}
                            </Typography>
                            </Grid>
                            <Grid item xs={4} className="align-right">
                              <Typography sx={{ marginTop: "10px" }}>
                                {portFolioSummaryDetails?.dyingTooSoon?.salaryRatio ? portFolioSummaryDetails?.dyingTooSoon?.salaryRatio?.toFixed(2) : '0'}
                              </Typography></Grid></>
                        }
                        <Grid item xs={8}>
                          <Typography >
                            &nbsp;
                          </Typography></Grid>
                        <Grid item xs={4} className="align-right">
                          <Typography sx={{ color: "#21AFE5", fontSize: "13px" }}>

                          </Typography></Grid>

                        <Grid
                          container
                          sx={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "space-between",
                            borderTop: "3px solid #21AFE5",
                            borderBottom: "1px solid #21AFE5",
                            padding: "10px 0px",
                          }}
                        >
                          <Grid>
                            <Typography sx={{ fontWeight: 600 }}>
                              {t("PortfolioAssetsLifeProtectionGap")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography sx={{ fontWeight: 600 }}>
                              {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.dyingTooSoon?.gapAmount ? (portFolioSummaryDetails?.dyingTooSoon?.gapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          borderTop: "5px solid #DBD3BD",
                          color: "#21AFE5",
                          paddingTop: "5px",
                          fontWeight: 600,
                          marginTop: "50px",
                        }}
                      >
                        {t("FourRisksCriticalillness")}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>
                          {t("FourRisksCriticalIllnessNeeds")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.criticalIllness?.criticalIllnessNeeds ? (portFolioSummaryDetails?.criticalIllness?.criticalIllnessNeeds)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>{t("PortfolioAssetsIllnesQuestion")}</Typography>
                      </Grid>
                      <Grid>
                        <Typography>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.criticalIllness?.currentCriticalIllnessCoverAndAssets ? (portFolioSummaryDetails?.criticalIllness?.currentCriticalIllnessCoverAndAssets)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {isCoApplicant &&
                        <>
                          <Grid item xs={8}><Typography sx={{ marginTop: "10px" }}>
                            {t("PortfolioAssetsSalaryRatio")}
                          </Typography>
                          </Grid>
                          <Grid item xs={4} className="align-right">
                            <Typography sx={{ color: "#21AFE5", fontSize: "13px" }}>
                              {portFolioSummaryDetails?.criticalIllness?.salaryRatio?.toFixed(2)}
                            </Typography></Grid></>
                      }
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "3px solid #21AFE5",
                        borderBottom: "1px solid #21AFE5",
                        padding: "10px 0px",
                      }}
                    >
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("PortfolioAssetsCriticalillnessGap")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.criticalIllness?.gapAmount ? portFolioSummaryDetails?.criticalIllness?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={6} sx={{ paddingRight: "30px" }}>
                    <Typography
                      sx={{
                        borderTop: "5px solid #22BDB9",
                        color: "#21AFE5",
                        paddingTop: "5px",
                        fontWeight: 600,
                      }}
                    >
                      {t("FinanceAdviceDisability")}
                    </Typography>
                    <Grid container sx={{ marginTop: '10px' }}>
                      <Grid item xs={8}><Typography>Disability Needs</Typography></Grid>

                      <Grid item xs={4} className="align-right"><Typography>
                        {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.disability?.disabilityNeeds ? (portFolioSummaryDetails?.disability?.disabilityNeeds).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                      </Typography></Grid>
                      <Grid item xs={8}>
                        <Typography sx={{ marginTop: "10px" }}>
                          {t("PortfolioAssetsCurrentdisabilityCover")}
                        </Typography></Grid>
                      <Grid item xs={4} className="align-right">
                        <Typography sx={{ marginTop: "10px" }}>
                          {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}  {portFolioSummaryDetails?.disability?.currentDisabilityCoverAndAssets ? portFolioSummaryDetails?.disability?.currentDisabilityCoverAndAssets?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                        </Typography></Grid>
                      {isCoApplicant &&
                        <>
                          <Grid item xs={8}><Typography sx={{ marginTop: "10px" }}>
                            {t("PortfolioAssetsSalaryRatio")}
                          </Typography>
                          </Grid>
                          <Grid item xs={4} className="align-right">
                            <Typography sx={{ marginTop: "10px" }}>
                              {portFolioSummaryDetails?.disability?.salaryRatio && (portFolioSummaryDetails?.disability?.salaryRatio).toFixed(2)}
                            </Typography></Grid></>
                      }
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "38px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "3px solid #21AFE5",
                        borderBottom: "1px solid #21AFE5",
                        padding: "10px 0px",
                      }}
                    >
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("PortfolioAssetsDisabilitProtectionGap")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.disability?.gapAmount ? portFolioSummaryDetails?.disability?.gapAmount?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          borderTop: "5px solid #7371D2",
                          color: "#21AFE5",
                          paddingTop: "5px",
                          fontWeight: 600,
                          marginTop: "50px",
                        }}
                      >
                        {t("FourRisksLivingToolong")}
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>
                          {t("PortfolioAssetsRetirementNeed")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.livingTooLong?.retirementNeeds ? (portFolioSummaryDetails?.livingTooLong?.retirementNeeds)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>{t("PortfolioAssetsvalueretirement")}</Typography>
                      </Grid>
                      <Grid>
                        <Typography>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.livingTooLong?.expectedAssetsValueAtRetirement ? (portFolioSummaryDetails?.livingTooLong?.expectedAssetsValueAtRetirement)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>

                      </Grid>

                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "8%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography></Typography>
                      </Grid>
                      <Grid>
                        <Typography></Typography>

                      </Grid>

                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "27px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "3px solid #21AFE5",
                        borderBottom: "1px solid #21AFE5",
                        padding: "10px 0px",
                      }}
                    >
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {t("PortfolioAssetsRetirementGap")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ fontWeight: 600 }}>
                          {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{portFolioSummaryDetails?.livingTooLong?.gapAmount ? (portFolioSummaryDetails?.livingTooLong?.gapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          borderTop: "5px solid #7371D2",
                          color: "#21AFE5",
                          paddingTop: "5px",
                          fontWeight: 600,
                          marginTop: "50px",
                        }}
                      >
                        {t("NavigationFinancialObjectives")}
                      </Typography>
                    </Grid>
                    {/* start */}
                    {portFolioSummaryDetails?.financialObjectives?.length > 0 && portFolioSummaryDetails?.financialObjectives?.map((element: any, index: number) => {
                      return (<Box key={index}>
                        <Grid
                          sx={{
                            marginTop: "12px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Grid>
                            <Typography sx={{
                              fontWeight: 0, marginRight: "30px",
                            }}>{element.targetYear}:{element.type}  {element.type?.includes('UNIVERSITY') || element.type?.includes('MARRIAGE') ? " - " + _getChildName(element?.dependentId) + " ( " + element.description + " ) " : element.description ? " ( " + element.description + " ) " : ''}</Typography>
                          </Grid>
                          <Grid>
                            <Typography sx={{
                              whiteSpace: "nowrap",
                              textAlign: "left",
                            }}>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{element?.amount ? (+element?.amount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{
                            marginTop: "12px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Grid>
                            <Typography>{t("PortfolioAssetsLessFuturevalue")}</Typography>

                          </Grid>
                          <Grid>
                            <Typography>{details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}{element?.lessFutureValue ? (+element?.lessFutureValue).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}</Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{
                            marginTop: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            borderTop: "1px solid #21AFE5",
                            borderBottom: "3px solid #21AFE5",
                            padding: "10px 0px",
                            alignItems: "center",
                          }}
                        >
                          <Grid>
                            <Typography sx={{
                              fontWeight: 600, marginRight: "35px",
                            }}>
                              {element.type + " "}  {element.type?.includes('UNIVERSITY') || element.type?.includes('MARRIAGE') ? " - " + _getChildName(element?.dependentId) + " ( " + element.description + " ) " : element.description ? " ( " + element.description + " ) " : ''} Gap
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography sx={{
                              fontWeight: 600,
                              whiteSpace: "nowrap",
                              textAlign: "left",
                            }}>
                              {details?.fourR?.currency ? details?.fourR?.currency + " " : 'USD '}  {element?.gapAmount ? (+element?.gapAmount).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>)
                    })
                    }
                    {/* end */}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" startIcon={<ArrowBackIcon />} onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'left' }}>
              {t("FinanceAdviceBack")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" endIcon={<ArrowForwardIcon />} onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'right' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form>
        {/* end Form */}
      </Grid>
      <ToastContainer />
      <DevTool control={control} />
    </Box>
  );
};

export default PortfolioAssets;
