import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { DevTool } from "@hookform/devtools";
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { fetchCustomersSelectExisting } from '../redux/GenericDetailsSlice'
import axios from 'axios'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs';
import { DataGrid } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import { useTranslation } from 'react-i18next';


type FormValues = {
  "firstName": string,
  "lastName": string,
  "dob": any,
  "gender": string,
  "residentialCountry": string
}
const CreateSession = () => {
  const dispatch = useDispatch()
  const [customerDetailsList, setCustomerDetailsList] = React.useState<any>([])
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const { t, i18n } = useTranslation();
  const requestOptions = {
    headers: headers,
  };
  const [tableFilter, setTableFilter] = React.useState<any>('')
  const fetchData = async () => {
    try {
      const response = await axios.get(baseUrl + '/sessions/existing?forSessionAnnualReview=false&searchTerm=' + tableFilter, requestOptions);
      const responseDetails = await response.data;
      await setCustomerDetailsList(responseDetails)
    } catch (error: any) {
      if (error.response.status === 401) {
        navigate('/login');
      } else {
        toast.error('Something went wrong, please try again later!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
    }
  };
  const calculateAge = (dob: any) => {
    //calculate month difference from current date in time
    let month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    let age_dt = new Date(month_diff);

    //extract year from date    
    let year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    let age = Math.abs(year - 1970);
    return age;
  }
  const debounce = (func: any, timeout = 1000) => {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
  }
  React.useEffect(() => {
    debounce(fetchData())
  }, [tableFilter])
  const getFilterRecords = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target
    setTableFilter(value)
  }
  const formatedDate = (value: any) => {
    if (value?.$d != 'Invalid Date') {
      const date = (value === '' || value === undefined) ? new Date() : new Date(value);
      const newDate = date.setFullYear(date.getFullYear() - 30);
      var formatedDate = new Date(newDate).toISOString()
      return dayjs(formatedDate);
    }
  }

  const [isError, setIsError] = React.useState<boolean>(true)
  const [idValue, setIdValue] = React.useState<string>()

  const createData = (
    id: string,
    name: string,
    sessionId: any,
    customerId: string,
    type: string

  ) => {
    return { id, name, sessionId, customerId, type };
  }

  const rows = customerDetailsList.map((element: any) => {
    return createData(element?.id ? element?.id : element?.customerId, element?.firstName + " " + element?.lastName, element?.id, element?.customerId, element?.type)
  })

  const renderRadio = (params: any) => {
    return <Radio checked={params?.row?.id === idValue} value={params?.row?.checked} onClick={(e) => renderRadioCell(params?.row?.id, e)} />;
  }

  const columns = [
    { field: 'id', headerName: '', renderCell: renderRadio, sortable: false, disableColumnMenu: true, fontWeight: 600, color: 'rgb(62, 79, 115)', },
    { field: 'name', headerName: "Name", sortable: false, disableColumnMenu: true, width: 300 },
    { field: 'sessionId', headerName: 'session Id', sortable: false, disableColumnMenu: true, width: 300 },
    { field: 'customerId', headerName: 'Customer Id', sortable: false, disableColumnMenu: true, width: 300 },
    { field: 'recordType', headerName: '', sortable: false, disableColumnMenu: true, width: 0 }

  ];
  const renderRadioCell = (id: string, e: any) => {
    setIdValue(id);
    //('sessiondetail', id)
    //sessionStorage.setItem('customerdetail', customerId)
    setIsError(false);
  }

  const formatedDateDob = (value: any) => {
    if (value?.$d != 'Invalid Date') {
      const date = (value === '' || value === undefined) ? new Date() : new Date(value);
      const newDate = date.setFullYear(date.getFullYear());
      if (newDate) {
        var formatedDate = new Date(newDate).toISOString()
        return dayjs(formatedDate);
      }
    }
  }
  let radioChecked = [rows[0]?.id];

  const [selectionModel, setSelectionModel] = React.useState(radioChecked);
  const selectedRow = rows.filter((item: any) => {
    return item.id === selectionModel[0];
  });
  radioChecked = selectedRow;
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const [currentForm, setCurrentForm] = React.useState<string>('createSession')
  let navigate = useNavigate();
  const countryCodeList = useSelector((state: any) => state.genericDetails.countryCodeList);
  const form = useForm<FormValues>({
    defaultValues: {
      "firstName": "",
      "lastName": "",
      "dob": formatedDate(''),
      "gender": "",
      "residentialCountry": "AE"
    },
    mode: 'all'
  });
  const { register, control, handleSubmit, formState: { errors }, watch } = form
  const getDateInYearFormat = (inputDate: string) => {
    let date = new Date(inputDate);
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    let formattedDate = year + "-" + month + "-" + day;
    return formattedDate
  }
  const _onSubmit = (data: FormValues) => {
    let payload = { ...data, dob: getDateInYearFormat(data?.dob) }
    setCustomersDetails(payload)
  }


  const setCustomersDetails = async (userDetails: {}) => {
    const response = await axios.post(baseUrl + '/customers/session', userDetails, requestOptions)
      .then((response) => {
        let closedSession = false;
        const sessionId = response?.data?.sessionId
        sessionStorage.setItem('sessiondetail', sessionId)
        sessionStorage.setItem('customerdetail', response?.data?.customerId)
        sessionStorage.setItem('closedSession', closedSession?.toString())
        navigate('/solution', { replace: true, state: { closedSession } })
      }).catch(error => {
        toast.error('Customer already exist.', {
          position: toast.POSITION.BOTTOM_CENTER
        });

      })
    return response
  }
  const createExistingSession = async (userDetails: any) => {
    let url = ''
    let payload = { ...userDetails }
    if (userDetails?.type === 'NEW_SESSION' || userDetails?.type === 'SESSION_FROM_EXISTING_CUSTOMER') {
      url = baseUrl + '/customers/session-from-existing';
    } else if (userDetails?.type === 'dependent' || userDetails?.type === 'DEPENDENT') {
      url = baseUrl + '/customers/session-from-dependent';
      payload = { id: userDetails?.sessionId }
    } else if (userDetails?.type === 'coapplicant' || userDetails?.type === 'COAPPLICANT') {
      url = baseUrl + '/customers/session-from-co-applicant';
      payload = { id: userDetails?.sessionId }
    } else if (userDetails?.type === 'CUSTOMER_WITH_NO_SESSION') {
      url = baseUrl + '/customers/session-from-existing-customer-with-no-session';
      payload = { customerId: userDetails?.customerId }
    }
    else {
      url = baseUrl + '/customers/session-from-existing';
    }

    delete payload?.type
    const response = await axios.post(url, payload, requestOptions)
      .then((response) => {
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        let closedSession = false;
        let annualReviewSession = true;
        const sessionId = response?.data?.sessionId
        sessionStorage.setItem('sessiondetail', sessionId)
        sessionStorage.setItem('customerdetail', response?.data?.customerId)
        sessionStorage.setItem('closedSession', closedSession?.toString())
        navigate('/solution', { replace: true, state: { closedSession, annualReviewSession } })

      }).catch(error => {
        //_setPageID(2)
        toast.error('There is already an open session with this client. Please continue with that current open session.', {
          position: toast.POSITION.BOTTOM_CENTER
        });
      })
    return response
  }
  const _navigateToSoluation = () => {
    if (radioChecked !== undefined) {
      createExistingSession({ customerId: radioChecked[0]?.customerId, sessionId: radioChecked[0]?.sessionId, type: radioChecked[0]?.type })
    }

  }
  return (
    <>
      <Grid container sx={{ padding: '15px 15px' }}>
        <Grid xs={12} sx={{ padding: '15px 0' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="smallFont" underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography className="smallFont" color="text.primary"> {currentForm === 'createSession' ? 'Creat new session' : 'Select existing session'}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'left' }}><Typography className="secoundaryFont primary-color bold-font"> {t("createNewSessionText")}</Typography></Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}> <Button onClick={() => setCurrentForm('createSession')} className='secoundary-color createSessionButton' variant="outlined" sx={{ width: '98%', marginTop: '10px', color: '#fff', background: '#005EB8', padding: '10px 0' }}>+   {t("createNewCustomerButton")}</Button></Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}> <Button onClick={() => setCurrentForm('existingSession')} className='primary-color' variant="outlined" sx={{ width: '98%', marginTop: '10px', padding: '10px 0' }}>{t("existingCustomerSelectionButton")}</Button></Grid>
        {currentForm === 'createSession' ?
          <Grid item xs={12} sx={{ textAlign: 'left', marginTop: '5%' }}>
            <form onSubmit={handleSubmit(_onSubmit)} noValidate className="formFourCs">
              <Grid container>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    className="formElement"
                    label={t("firstNameText")}
                    type="text"
                    id="firstName"
                    {...register(`firstName` as const, {
                      required: "Please enter a value",
                      maxLength: {
                        value: 20,
                        message: "You have exceeded the character limit."
                      }
                    })}
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />

                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="standard"
                    className="formElement"
                    label={t("lastNameText")}
                    type="text"
                    id="lastName"
                    {...register(`lastName` as const, {
                      required: "Please enter a value",
                      maxLength: {
                        value: 20,
                        message: "You have exceeded the character limit."
                      }
                    })}
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />

                </Grid>
                <Grid item xs={6} className="marginTop">
                  <FormControl variant="standard" className="formElement">
                    <InputLabel id="demo-simple-select-filled-label">{t("genderText")}</InputLabel>
                    <Select
                      labelId="Gender-label"
                      label="Gender"
                      id="gender"
                      error={!!errors?.gender}

                      {...register(`gender` as const, {

                        validate: {
                          noValue: (fieldValue) => {
                            return (
                              (fieldValue !== '' && fieldValue !== undefined && fieldValue !== null) || 'Please enter a valid date'
                            )
                          },

                        }
                      })}
                    >
                      <MenuItem value='male'>Male</MenuItem>
                      <MenuItem value='female'>Female</MenuItem>
                    </Select>
                    <FormHelperText sx={{ color: '#ea635c' }}>{errors?.gender?.message}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} className="marginTop">
                  <FormControl variant="standard" className="formElement">
                    <Controller
                      defaultValue={null}
                      control={control}
                      {...register(`dob`, {
                        required: "Please enter a value",
                        validate: {
                          noValue: (fieldValue) => {
                            return (
                              fieldValue?.$d != 'Invalid Date' || 'Please enter a valid date'
                            )
                          },
                          noFutureDate: (fieldValue) => {
                            let psDate = new Date().toDateString();

                            let entererDate = new Date(fieldValue?.$d).toDateString();

                            let diff = Number(Math.floor((Date.parse(psDate) - Date.parse(entererDate)) / 86400000));

                            return (

                              // (enteredDate < currentDate && diff  <= 27027 && enteredDate > pastDate )  || 'Error: minimum age is 17 and maximum age is 74'



                              (diff >= 6209 && diff <= 27027) || 'Error: minimum age is 17 and maximum age is 74'
                            )
                          }
                        }
                      })}
                      render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={(e) => field.onChange(e)}
                          value={formatedDateDob(field.value)}
                          format="DD/MM/YYYY"
                          minDate={formatedDateDob('30/12/' + (new Date().getFullYear() - 74))}
                          maxDate={formatedDateDob('12/31/2005')}
                          label={t("dateOfBirthText")} slotProps={{ textField: { variant: 'standard' } }} />
                        <FormHelperText sx={{ color: '#ea635c' }}>{errors?.dob?.message?.toString()}</FormHelperText>
                      </LocalizationProvider>)} />

                  </FormControl>
                </Grid>
                <Grid item xs={6} className="marginTop">
                  <FormControl variant="standard" className="formElement">
                    <InputLabel id="demo-simple-select-filled-label">{t("Residentcountry")}</InputLabel>
                    <Select
                      labelId="ResidentCountry-label"
                      defaultValue={"AE"}
                      label="Resident country"
                      id="residentCountry"
                      {...register(`residentialCountry` as const, {
                        required: "Please select a option",
                        validate: {
                          noUAE: (fieldValue) => {
                            return (
                              (fieldValue === 'AE') || 'Our products are designed to serve residents of the UAE'
                            )
                          }
                        }
                      })}
                      error={!!errors?.residentialCountry}
                    >
                      {countryCodeList ?
                        countryCodeList?.map((listElement: any, index: number) => {
                          return <MenuItem key={index} value={listElement.isoCode}>{listElement.name}</MenuItem>
                        }) : ''
                      }
                    </Select>
                  </FormControl>
                  <FormHelperText sx={{ color: '#ea635c' }}>{errors?.residentialCountry?.message}</FormHelperText>
                </Grid>
                <Grid item xs={7} className="marginTop" sx={{ textAlign: 'left' }}> <Button className='createSessionButton' variant="outlined" type="submit" sx={{ width: '70%', marginTop: '10px', color: '#fff', background: '#005EB8', padding: '10px 0' }}>{t("createNewCustomerButton")}</Button></Grid>
              </Grid>
            </form>
          </Grid>
          :
          <>
            <Grid item xs={12} md={12} sx={{ textAlign: 'right', marginTop: '20px', float: 'right', marginRight: '10px' }}>
              <OutlinedInput
                id="outlined-adornment-weight"
                sx={{ height: '35px !important', padding: '0' }}
                placeholder='Search'
                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => getFilterRecords(e)}
                endAdornment={<InputAdornment position="end">
                  <Button sx={{ fontSize: 'medium', background: '#005EB8', height: '35px' }} variant="contained" endIcon={<SearchIcon />}></Button></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '35px' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                autoHeight
                rowSelectionModel={selectionModel}
                onRowSelectionModelChange={(newSelectionModel: any) => {
                  setSelectionModel(newSelectionModel);
                }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10 },
                  },
                }}
                hideFooter={true}
                sx={{ marginRight: '15px' }}
              />
              <Grid item xs={7} className="marginTop" sx={{ textAlign: 'left' }}> <Button className='createSessionButton' variant="outlined" onClick={_navigateToSoluation} sx={{ width: '70%', marginTop: '10px', color: '#fff', background: '#005EB8', padding: '10px 0' }}> Create new session</Button></Grid>
            </Grid>
          </>
        }
      </Grid>
      <DevTool control={control} />
      <ToastContainer />
    </>
  )
}

export default CreateSession