import React, { useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs, { Dayjs } from 'dayjs';
import summaryImg from "../../Images/Frame.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InputAdornment from '@mui/material/InputAdornment';
import m from '@mui/material/TextField';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import LoopIcon from '@mui/icons-material/Loop';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DevTool } from "@hookform/devtools";
import CheckRadioButton from '../CommonComponent/CheckRadioButton'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Slider from '@mui/material/Slider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import spinner from '../../Images/zurich.gif'
import Dialog from '@mui/material/Dialog';
import PercentIcon from '@mui/icons-material/Percent';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Checkbox from '@mui/material/Checkbox';
import {
  FormHelperText,
  InputLabel,
} from "@mui/material";

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
type FormValues = {
  amount: any,
  incomeType: string,
  percentage: string,
  isSinglePayPremium: any,
  isEmergencyReserve: any,
  emergencyFundAmount: any,
  singlePayAmount: any
}
export const Budget = ({ setIsFormSubmited, isQuoteGenerated, isClosedSession, _setPageID, _navigateToDashboard, submitRef }: any) => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const [details, setDetails] = React.useState<any>()
  const [expensePercentage, setExpensePercentage] = React.useState<number>(0)
  const [isErrorColor, setIsError] = React.useState<string>('#b8b8b8')
  const [availableBudget, setAvailableBudget] = React.useState(0)
  const [amountError, setAmountError] = React.useState(false)
  const [incomeTypeError, setIncomeTypeError] = React.useState(false)

  useEffect(() => {
    if (details?.fourC?.expenses?.length > 0) {
      let count = 0
      details?.fourC?.expenses?.forEach((element: any) => {
        count = count + Number(element.percentage)
      })
      setExpensePercentage(count)
    }
  }, [details])
  const _getFlag = (value: any) => {
    if (value === undefined || value === '') {
      return false
    } else {
      return true
    }
  }
  const form = useForm<FormValues>({
    defaultValues: async () => {
      setShowSpinner(true)
      const response = await fetch(
        baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions
      );
      const data = await response.json();
      setDetails(data)
      setShowSpinner(false)
      return {
        sessionId: sessionStorage.getItem('sessiondetail') || '',
        'amount': data?.budget?.amount || '',
        "incomeType": data?.budget?.incomeType || "FAMILY",
        "percentage": data?.budget?.percentage || "",
        "isSinglePayPremium": data?.budget?.isSinglePayPremium,
        "isEmergencyReserve": data?.budget?.isEmergencyReserve ? data?.budget?.isEmergencyReserve === true ? 'yes' : 'no' : 'no',
        "emergencyFundAmount": data?.budget?.emergencyFundAmount || null,
        "singlePayAmount": data?.budget?.emergencyFundAmount || null,

      }
    },
    mode: 'all'
  })

  const { register, control, handleSubmit, formState, reset, watch, setValue, getValues } = form
  const { fields, append, remove } = useFieldArray({
    name: 'financialObjectives',
    control
  })
  let budgetDetails = watch()
  useEffect(() => {
    let budgetAmount = budgetDetails?.amount && Number(budgetDetails?.amount?.toString().split(',').join(""))
    let availableBudget = Number(((budgetAmount - Math.round((Number(budgetAmount * expensePercentage)) / 100))) * Number(budgetDetails.percentage)) / 100
    let temp = ((((Number(budgetDetails?.amount) - Math.round((Number(budgetDetails?.amount) * expensePercentage) / 100))) * Number(budgetDetails.percentage)) / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    setAvailableBudget(availableBudget)
  }, [budgetDetails?.percentage, budgetDetails?.amount, expensePercentage, setExpensePercentage])
  const { errors } = formState;
  /*submit code start */
  const _onSubmit = (data: any, action: any) => {
    let reponse = { ...data }
    reponse.isEmergencyReserve = (reponse?.isSinglePayPremium === 'true' || reponse?.isSinglePayPremium === true) ? (reponse?.isEmergencyReserve === 'yes' ? true : false) : false
    reponse.isSinglePayPremium = (reponse?.isSinglePayPremium === 'true' || reponse?.isSinglePayPremium === true) ? true : false
    reponse.availableBudget = availableBudget || 0
    setBudget(reponse, action)
  }

  const setBudget = async (budgetDetails: {}, action: any) => {
    const response = await axios.post(baseUrl + '/customers/budget', budgetDetails, requestOptions)
      .then((response) => {
        setIsFormSubmited(true)
        toast.success('Record successfully updated!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
        if (action === 'next') {
          _setPageID(7)
        } else if (action === 'prev') {
          _setPageID(5)
        } else if (action === 'dashboard') {
          _navigateToDashboard(true);
        }
      }).catch(error => {
        if (error.response.status === 401) {
          navigate('/login');
        } else {
          _onError();
          toast.error('Something went wrong please try later!', {
            position: toast.POSITION.BOTTOM_CENTER
          });
        }
      })

    return response
  }
  /* submit code end */
  useEffect(() => {
    let status = details?.progress?.budget?.status
    if (status === 'COMPLETE') {
      setIsError('#23AA01')
    }
  }, [errors, details])
  const _onError = () => {

    errors.hasOwnProperty('amount') || errors.hasOwnProperty('incomeType') ? setAmountError(true) : setAmountError(false)
    errors.hasOwnProperty('percentage') ? setIncomeTypeError(true) : setIncomeTypeError(false)
  }

  return (
    <Box sx={{ flexGrow: 1, margin: '0', justifyContent: 'space-between' }}>
      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      <Box>
        <h3 style={{ color: '#012169', textAlign: 'left', marginTop: "30px", marginBottom: '40px' }}>{t("FinanceAdviceFor")} {details?.personalDetails?.firstName + " " + details?.personalDetails?.lastName}</h3>
      </Box>
      <Grid container>
        {/* forms start */}
        <form noValidate className={isClosedSession === true || isQuoteGenerated ? 'lock formFourCs' : 'formFourCs'}>
          {/* accordion 1 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion" sx={{ border: '2px solid', borderColor: amountError ? '#FF7B6F' : isErrorColor }} defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ padding: '0 16px' }}>{t("BudgetAffordability")}</Typography>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container className="padding-top">
                  <Grid item xs={6}>
                    <FormControl variant="standard" className="formElement">

                      <TextField
                        variant="standard"
                        type="number"
                        onWheel={event => (document.activeElement as HTMLElement).blur()}
                        defaultValue=""
                        InputLabelProps={{ shrink: _getFlag(budgetDetails?.amount) }}
                        label={t("BudgetMonthlyIncome")}
                        sx={{ color: "#005EB8" }}
                        id="amount"
                        {...register("amount", {
                          required: "Please enter a value",
                          pattern: {
                            value: /^\d{1,8}(\.\d{0,2})?$/,
                            message: 'Please Enter valid decimal value.'
                          },
                          maxLength: {
                            value: 11,
                            message: "You have exceeded the character limit."
                          },
                          max: {
                            value: 100000000,
                            message: "Please enter valid amount"
                          },
                          min: {
                            value: 1,
                            message: "Please enter valid amount"
                          }
                        }
                        )}
                        error={!!errors?.amount}
                        helperText={errors?.amount?.message?.toString()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              AED
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sx={{ margin: '2% auto' }} >
                    <FormControl fullWidth error={!!errors?.incomeType} className="formElement">
                      <Controller
                        {...register(`incomeType`, {
                          required: 'Please select an option'
                        })}
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <RadioGroup
                            {...field}
                            row
                            {...register(`incomeType`)}
                            onChange={(event, value) => field.onChange(value)}
                            value={field.value}
                          >
                            <FormControlLabel   {...register(`incomeType`)} value="FAMILY" control={<CheckRadioButton />} label="Family" />
                            <FormControlLabel   {...register(`incomeType`)} value="OWN" control={<CheckRadioButton />} label="Own" />
                          </RadioGroup>

                        )}
                      />
                      <FormHelperText sx={{ color: '#ea635c' }}>{errors?.incomeType?.message?.toString()}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 2*/}
          {/* accordion 3 */}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Accordion className="accordion"
              style={{
                border: "1px solid rgba(157, 157, 157, 0.67)",
                boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.13)",
                borderRadius: "21px",
                backgroundColor: "#012169",
                color: "#fff",
                paddingBottom: "60px",
                backgroundImage: `url(${summaryImg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right bottom",
                backgroundSize: "12%",
                borderColor: incomeTypeError ? '#FF7B6F' : isErrorColor
              }}

              defaultExpanded={isClosedSession === true || isQuoteGenerated ? true : false} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container>
                  <Grid item xs={6}><Typography sx={{ padding: '0 16px', textAlign: 'left', color: '#ffffff' }}>{t("BudgetMonthlyContribution")}</Typography></Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right' }} >
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails className='accordionPadding'>
                <Grid container sx={{ textAlign: 'left', padding: '25px', display: 'flex' }}>
                  <Grid item xs={6} md={6} sx={{ paddingRight: "30px" }}>
                    <Typography
                      sx={{
                        borderTop: "3px solid #22BDB9",
                        color: "#21AFE5",
                        paddingTop: "5px",
                        fontWeight: 600,
                      }}
                    >
                      {t("BudgetExcessIncomeCalculation")}
                    </Typography>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        color: '#fff',
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>
                          {t("BudgetMonthlyIncome")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>{Number(budgetDetails?.amount)?.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  AED</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "12px",
                        display: "flex",
                        color: '#fff',
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid>
                        <Typography>
                          {t("BudgetMonthlyConsumption")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>{((Number(budgetDetails?.amount) * expensePercentage) / 100)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} AED</Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "38px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "2px solid #21AFE5",
                        borderBottom: "2px solid #21AFE5",
                        padding: "10px 0px",
                      }}
                    >
                      <Grid>
                        <Typography sx={{ fontWeight: 600, color: '#fff' }}>
                          {t("BudgetAvailableExcessIncome")}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ fontWeight: 600, color: '#fff' }}>
                          {(Number(budgetDetails?.amount) - Math.round((Number(budgetDetails?.amount) * expensePercentage) / 100))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} AED
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} md={6} sx={{ color: '#fff' }}>
                    <Grid>{t("BudgetAvailableCashflow")}
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: "38px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0px",
                      }}
                    >
                      <Grid>
                        <Typography sx={{ fontWeight: 600, color: '#fff !important', fontSize: '14px' }}>
                          <TextField
                            variant="standard"
                            type="number"
                            onWheel={event => (document.activeElement as HTMLElement).blur()}
                            className="budgetFont"
                            sx={{ color: '#fff !important', fontSize: '14px', borderBottom: '1px solid #fff' }}
                            defaultValue=""
                            label={t("FinanceAdvicePercentage")}
                            InputLabelProps={{ shrink: _getFlag(budgetDetails?.percentage) }}
                            id="percentage"
                            {...register("percentage", {
                              required: "Please enter a value",
                              pattern: {
                                value: /^\d{1,2}(\..\d{0,2})?$/,
                                message: 'Please Enter valid decimal value.'
                              },
                              maxLength: {
                                value: 5,
                                message: "You have exceeded the character limit."
                              },
                              max: {
                                value: 50,
                                message: "Please enter Percentages between 1% and 50%"
                              },
                              min: {
                                value: 1,
                                message: "Please enter percentages between 1% and 50%"
                              }
                            }
                            )}
                            error={!!errors?.percentage}
                            helperText={errors?.percentage?.message?.toString()}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" >
                                  <PercentIcon sx={{ color: '#fff' }} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography sx={{ fontWeight: 400, background: '#fff', color: '#012169', padding: '10px', borderRadius: '10px' }}>
                          {t("BudgetselectedBudget")} {((((Number(budgetDetails?.amount) - Math.round((Number(budgetDetails?.amount) * expensePercentage) / 100))) * Number(budgetDetails.percentage)) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} AED
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid xs={12} sx={{ marginTop: '25px' }}>
                      <InputLabel id="continuation-label" sx={{ color: '#fff' }}>{t("RiskProfilerInitialContribution")}</InputLabel>
                      <Controller
                        {...register(`isSinglePayPremium`, {
                        })}
                        defaultValue={'' as any}
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  sx={{ color: '#fff' }}
                                  {...register(`isSinglePayPremium`)}
                                  onChange={(event, value) => field.onChange(value)}
                                  value={field.value}
                                  checked={field.value}
                                  icon={<CircleOutlinedIcon />}
                                  checkedIcon={<CheckCircleOutlineIcon />}
                                />
                              }
                              label="I/We want to opt for Single Pay Premium?"
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    {(budgetDetails?.isSinglePayPremium === 'true' || budgetDetails?.isSinglePayPremium === true) &&
                      <Grid xs={12} sx={{ marginTop: '25px' }}>
                        <InputLabel id="priorityNearTermAgainstLongTerm-label" className='formLabel' sx={{ color: '#fff' }}>{t("BudgetEmergencyReserve")}</InputLabel>
                        <Controller
                          {...register(`isEmergencyReserve`, {
                            required: 'Please select an option'
                          })}
                          defaultValue={'' as any}
                          control={control}
                          render={({ field }) => (
                            <FormControl fullWidth >
                              <RadioGroup
                                {...field}
                                row
                                {...register(`isEmergencyReserve`, {
                                  required: 'Please select an option'
                                })}
                                onChange={(event, value) => field.onChange(value)}
                                value={field.value}
                              >
                                <FormControlLabel   {...register(`isEmergencyReserve`)} value={'yes'} control={<CheckRadioButton />} label="Yes" />
                                <FormControlLabel   {...register(`isEmergencyReserve`)} value={'no'} control={<CheckRadioButton />} label="No" />
                              </RadioGroup>
                            </FormControl>
                          )}
                        />

                      </Grid>

                    }

                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* end accodion 3*/}
          <Grid item xs={12} sx={{ marginTop: '25px' }}>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'prev');
            }, (errors) => {
              _onError();
            })} startIcon={<ArrowBackIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'left' }}>
              {t("FinanceAdviceBack")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} onClick={handleSubmit((data) => {
              _onSubmit(data, 'next');
            }, (errors) => {
              _onError();
            })} type="submit" endIcon={<ArrowForwardIcon />} variant="contained" sx={{ background: '#005EB8', width: '10%', float: 'right' }}>
              {t("FinanceAdviceNext")}
            </Button>
            <Button disabled={isClosedSession === true || isQuoteGenerated} type="submit" onClick={handleSubmit((data) => {
              _onSubmit(data, 'dashboard');
            }, (errors) => {
              _onError();
            })} ref={submitRef} sx={{ display: 'none' }}>
              {t("FinanceAdviceNext")}
            </Button>
          </Grid>
        </form>
        {/* end Form */}
      </Grid>
      <ToastContainer />
      <DevTool control={control} />
    </Box>
  );
};

export default Budget;
