import React from 'react'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import spinner from '../../Images/zurich.gif'
import solution from '../../Images/solution.png'
import file from '../../Images/filedown.png'
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField'
import OutlinedInput from '@mui/material/OutlinedInput';
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunchOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { isNamedExports } from 'typescript';
import coin from './asserts/coins.svg'

const ProductDetails = ({ selectedRisk, selectedTab, isClosedSession, isQuoteGenerated, selectedCurrency, userName, _setPageID, soluationDetails, totalAmount, details, totalBudgetAmount }: any) => {
    const location = useLocation();
    const fundRedirectUrl = process.env.REACT_APP_FUND_REDIRECT_URL;
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    const redirectUrl = process.env.REACT_APP_REDIRECT_URL;
    const bearerToken = localStorage.getItem('bearerToken')
    const headers = {
        Authorization: `Bearer ${bearerToken}`,
    };
    const requestOptions = {
        headers: headers,
    };

    const [tabValue, setTabValue] = React.useState('Benefits');
    const [amount, setAmountDetails] = React.useState(totalAmount)
    const [budgetAmount, setBudgetAmount] = React.useState(totalBudgetAmount)
    const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
    const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
    const [notesValue, setNotesValue] = React.useState<string>(details?.adviserNotes)
    const [benifitsList, setBenifitsList] = React.useState([])
    const [selectProduct, setSelectedProduct] = React.useState(details)
    const [addBenifitList, setAddBenifitList] = React.useState<React.ReactNode[]>([])
    const [customizeBenifitsList, setCustomiseBenifitsList] = React.useState([])
    const [listLength, setListLength] = React.useState(false)
    const [unSelectedList, setUnSelectedList] = React.useState([])
    const form = useForm<any>({
        defaultValues: async () => {
            const filteredarray = selectProduct.benefits?.filter((element: any) => element.isSelected === true)
            const selectedBenifitArray = filteredarray.map((ele: any) => {
                const currentSumAssured = Math.round(Number(ele?.currentSumAssured))
                const b = { ...ele, currentSumAssured }
                return b
            })

            return {
                selectedBenifitArray: selectedBenifitArray,
                unSelectbenifitArray: [],
            }
        },
        mode: 'all'
    });
    const { register, control, handleSubmit, formState: { errors }, watch, setValue, getValues, unregister } = form
    const { fields, append, remove } = useFieldArray({
        name: 'unSelectbenifitArray',
        control
    })
    const useFieldArray1 = useFieldArray({
        name: 'selectedBenifitArray',
        control
    })
    const fields1 = useFieldArray1.fields
    const append1 = useFieldArray1.append
    const remove1 = useFieldArray1.remove

    let benifitListArray = watch(`unSelectbenifitArray`);
    let selectedBenifitListArray = watch(`selectedBenifitArray`)
    React.useEffect(() => {
        const BenefitListTemp = selectProduct?.benefits.map((ele: any) => {
            const currentSumAssured = Math.round(Number(ele?.currentSumAssured))
            const b = { ...ele, currentSumAssured }
            return b
        })
        setBenifitsList(BenefitListTemp)
        if (selectProduct?.benefits?.length > 0) {
            let sum = selectProduct?.monthlyPremium;
            setAmountDetails(sum)
        }
    }, [selectProduct])
    const setUnselectedList = (selectedList: any) => {
        let selectedArray = watch(`unSelectbenifitArray`);
        let list = selectedList?.map((element: any) => {
            if (element?.isSelected === false) {

                let isElementAdded = selectedArray.some((el: any) => el?.name === element?.name)
                if (!isElementAdded) {
                    return element?.name
                }
            }
        })
        setUnSelectedList(list)
        let flag = (selectedArray?.length <= list?.length)
        setListLength(flag)

    }

    const handleTabChange = (e: any, newValue: any) => {
        setTabValue(newValue)
    }
    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
        let { name, value } = e.target

        setNotesValue(value)
    }

    const _getSelectedTab = () => {
        if (selectedTab === 'Recommended Solution') {
            return 'p001'
        } else if (selectedTab === 'Protection Focused') {
            return 'p002'
        } else if (selectedTab === 'Savings Focused') {
            return 'p003'
        } else {
            return 'p004'
        }
    }
    const roundOffAndFormat = (res: any) => {
        const comma = res.toString().replace(/,/g, '')
        const roundNum = Math.round(Number(comma))
        return roundNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    const saveNotes = async () => {
        setShowSpinner(true)
        let detailsObject = {
            sessionId: sessionStorage.getItem('sessiondetail'),
            solutionType: _getSelectedTab(),
            productName: selectProduct?.name,
            productCode: selectProduct?.productCode,
            productVersion: selectProduct?.version,
            notes: notesValue,
            goalId: selectProduct?.goalId
        }
        const response = await axios.post(baseUrl + '/customers/store/product/notes', detailsObject, requestOptions)
            .then((response) => {
                setNotesDialogOpen(false)
                setShowSpinner(false)
                toast.success('Notes added successfully!', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }).catch(error => {
                setNotesDialogOpen(false)
                setShowSpinner(false)
                toast.error('Something went wrong please try later!', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            })
        return response
    }
    const _getBorderColor = (index: number) => {
        if (index === 0 || index === 3 || index === 7 || index === 10) {
            return '3px solid #7371D2'
        } else if (index === 1 || index === 4 || index === 8 || index === 12) {
            return '3px solid #22BDB9'
        } else {
            return '3px solid #D6D2C4'
        }
    }

    const handleRecalculate = async (action: string) => {
        setShowSpinner(true)
        let allBenifits = benifitsList?.map((benifit: any) => {
            if (benifit.isSelected === false) {
                const element = benifitListArray?.find((element: any) => benifit?.name === element?.name);
                if (element) {
                    return {
                        ...benifit,
                        isSelected: true,
                        currentSumAssured: element.value
                    };
                } else {
                    return {
                        ...benifit
                    }
                }
            } else {
                const element = selectedBenifitListArray?.find((element: any) => benifit?.name === element?.name);
                if (element) {
                    return {
                        ...benifit,
                        currentSumAssured: element.currentSumAssured
                    };
                } else {
                    return {
                        ...benifit
                    }
                }
            }
        }).filter(Boolean);

        let payload = {
            "sessionId": sessionStorage.getItem('sessiondetail'),
            "solutionType": _getSelectedTab(),
            "product": {
                name: selectProduct?.name,
                type: selectProduct?.type,
                version: selectProduct?.version,
                productCode: selectProduct?.productCode,
                additionalData: [
                    ""
                ],
                benefits: allBenifits
            }
        }
        let url = baseUrl + '/calculate/product-premium/recalculate'
        if (action === 'revert') {
            url = baseUrl + '/calculate/revert-recalculate'
        }
        const response = await axios.post(url, payload, requestOptions)
            .then((response) => {
                let soluationData = response.data.outPutData;
                let selectedTab = _getSelectedTab()
                let detail = soluationData[selectedTab]
                let amount = response.data?.availableMonthlyBudget?.toFixed(0);
                setBudgetAmount(amount)
                let prod = detail?.products?.filter((element: any) => {
                    return element?.name === selectProduct?.name
                })
                if (prod?.length > 0) {
                    setSelectedProduct(prod[0])
                    const filteredarray = prod[0]?.benefits?.filter((element: any) => element.isSelected === true)
                    const selectedBenifitArray = filteredarray?.map((ele: any, index: any) => {
                        const currentSumAssured = Math.round(Number(ele?.currentSumAssured))
                        const b = { ...ele, currentSumAssured }
                        return b
                    })
                    setValue(`selectedBenifitArray`, selectedBenifitArray)

                }
                setShowSpinner(false)
            }).catch((error) => {
                setShowSpinner(false)
            })
    }
    const _handleRemoveSelectedElement = (index: number, name: string) => {
        let Benifitlist1 = benifitsList?.filter((element: any) => element?.name !== name)
        setBenifitsList(Benifitlist1)
        remove1(index)
    }
    const addBenfits = () => {
        setUnselectedList(benifitsList)
        append({
            name: '',
            value: 0
        })
    }

    return (
        <Box sx={{ height: 'auto', padding: '10px 0' }}>
            {showSpinner ?
                <Dialog className='spinner' open={showSpinner} > <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog >
                :
                <>
                    <AppBar position="static" sx={{ backgroundColor: '#fff !important', boxShadow: 'none' }}>
                        <Box className="heading">For {userName}</Box>
                        <Box
                            style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#005EB8",
                                }}
                            >
                                <ArrowBackIcon
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => { _setPageID(8) }}
                                />
                                <SecurityOutlinedIcon sx={{ ml: 1, mr: 1 }} />
                                {selectProduct?.type + " >            " + selectProduct?.name}
                                <Button
                                    disabled={isClosedSession === true}
                                    sx={{ height: '28px', background: (selectProduct?.isModified === false) ? '#005EB8' : 'rgb(249, 188, 62)', fontSize: '12px', margin: '25px 25px' }}
                                    variant="contained" startIcon={<img src={solution} alt='img' />} >{(selectProduct?.isModified === false) ? 'Optimized' : 'Modified'}</Button>
                            </Box>

                        </Box>
                    </AppBar>
                    <Grid container spacing={2} sx={{ padding: '0 20px' }}>
                        <Grid item xs={12} md={6} >
                            <Box sx={{ padding: '5px', textAlign: 'left', borderBottom: '1px solid rgba(0, 0, 0, 0.09)' }}>
                                <Grid container item >
                                    <Grid container item xs={12} md={3} >
                                        <Typography sx={{ color: '#012169', fontSize: '16px', fontWeight: '700', padding: '12px 0', margin: '16px 10px 10px 10px' }}>Product Info</Typography>
                                    </Grid>
                                    <Grid container item xs={12} md={6} sx={{ margin: 'auto 8px' }} >
                                        <a href={fundRedirectUrl} target="_blank" rel="noreferrer" style={{ border: '1px solid #005eb8', borderRadius: 10, padding: '3px 10px' }}>
                                            <Button
                                                className="productDetailsButton"
                                                sx={{ height: '28px', background: '#fff !important', fontSize: '12px', color: '#005EB8', boxShadow: 'none !important', border: '0 !important', fontWeight: '700', textTransform: 'none' }}
                                                variant="contained" startIcon={<img src={coin} alt='visit' style={{ width: '25px' }} />} >Funds</Button>
                                        </a>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ color: '#666666', fontSize: '14px', fontWeight: '400', padding: '12px 0', margin: '5px 10px' }}>{selectProduct?.fullDescription}</Typography>
                                <Box sx={{ display: 'inline', padding: '0' }}>
                                    {selectProduct?.name !== 'Education Saving Plan' &&
                                        <a href={redirectUrl} target="_blank" rel="noreferrer">
                                            <Button
                                                className="productDetailsButton"
                                                sx={{ height: '28px', background: '#fff !important', fontSize: '12px', color: '#005EB8', boxShadow: 'none !important', border: '0 !important', fontWeight: '700', textTransform: 'none' }}
                                                variant="contained" startIcon={<img src={file} alt='visit' style={{ width: '25px' }} />} >Critical Illness Coverage</Button>
                                        </a>
                                    }
                                    <a href={redirectUrl} target="_blank" rel="noreferrer">
                                        <Button
                                            className="productDetailsButton"
                                            sx={{ height: '28px', background: '#fff !important ', fontSize: '12px', color: '#005EB8', boxShadow: 'none !important', border: '0 !important', fontWeight: '700', textTransform: 'none' }}
                                            variant="contained" startIcon={<img src={file} alt='visit' style={{ width: '25px' }} />} >Key Features</Button>
                                    </a>
                                    {selectProduct?.name !== 'Education Saving Plan' &&
                                        <a href={redirectUrl} target="_blank" rel="noreferrer">
                                            <Button
                                                className="productDetailsButton"
                                                sx={{ height: '28px', background: '#fff !important', fontSize: '12px', color: '#005EB8', boxShadow: 'none !important', border: '0 !important', fontWeight: '700', textTransform: 'none' }}
                                                variant="contained" startIcon={<img src={file} alt='visit' style={{ width: '25px' }} />} >Cancer Cover</Button>
                                        </a>
                                    }
                                </Box>
                                <Box sx={{ display: 'block', padding: '0 5px 12px 0', margin: '10px' }}>
                                    <Typography sx={{ color: '#A89F96', fontSize: '12px', fontWeight: '400' }}>Policy owner</Typography>
                                    <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>{selectProduct?.policyOwner}</Typography>
                                </Box>
                                <Box sx={{ display: 'block', padding: '0 5px 12px 0', margin: '10px' }}>
                                    <Typography sx={{ color: '#A89F96', fontSize: '12px', fontWeight: '400' }}>Life assured</Typography>
                                    <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>{selectProduct?.name !== 'Education Saving Plan' ? selectProduct?.lifeInsured?.life1?.firstName + " " + selectProduct?.lifeInsured?.life1?.lastName + ", " + selectProduct?.lifeInsured?.life2?.firstName + " " + selectProduct?.lifeInsured?.life2?.lastName : selectProduct?.dependentName}</Typography>
                                </Box>
                                <Box sx={{ display: 'block', padding: '0 5px 12px 0', margin: '10px' }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: '700' }}>Ownership Type</Typography>
                                            <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>{selectProduct?.ownershipType}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: '700' }}>Policy Term</Typography>
                                            <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>{selectProduct?.policyTerm} Year</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: '700' }}>Joint Life type</Typography>
                                            <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>{selectProduct?.jointLifeType}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography sx={{ color: '#4c433d', fontSize: '14px', fontWeight: '700' }}>Payment frequency</Typography>
                                            <Typography sx={{ color: '#005EB8', fontSize: '14px', fontWeight: '400' }}>Annually</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px', textAlign: 'left', borderBottom: '1px solid rgba(0, 0, 0, 0.09)' }}>
                                <Typography sx={{ color: '#012169', fontSize: '16px', fontWeight: '700', padding: '15px' }}>Expected Monthly Payment</Typography>
                                <Box sx={{ textAlign: 'left', padding: '25px 15px' }}>
                                    <Typography sx={{ color: '#005EB8', fontSize: '24px', fontWeight: '700' }}>{selectedCurrency} {Number(selectProduct?.monthlyPremium).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        <Typography sx={{ color: '#666666', fontSize: '12px', fontWeight: '700' }}>Premium (monthly)</Typography></Typography>
                                </Box>
                                <Grid container sx={{ background: '#012169', padding: '15px', textAlign: 'center', borderRadius: '21px' }}>
                                    <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>
                                        <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: '700' }}>Monthly Budget Available</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: '700', width: '100%' }}>{selectedCurrency} {amount ? roundOffAndFormat(amount) : 0}/</Typography>
                                        <Typography sx={{ color: '#fff', fontSize: '14px', fontWeight: '700', margin: 'auto 0' }}>{roundOffAndFormat(budgetAmount)} (monthly)</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ padding: '15px 0', display: 'flex', textAlign: 'left' }}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            disabled={isClosedSession === true || isQuoteGenerated === true}
                                            className="productDetailsButton"
                                            onClick={() => setNotesDialogOpen(true)}
                                            sx={{ height: '28px', background: 'none', color: '#003399', boxShadow: 'none', fontWeight: '700', textTransform: 'none' }}
                                            variant="contained" startIcon={<NoteAddOutlinedIcon fontSize='large' />} >Advisor Notes</Button>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                                        <Button
                                            disabled={isClosedSession === true}
                                            className="productDetailsButton"
                                            sx={{ color: '#4c433d', border: '1px solid #4c433d', background: '#fff', padding: '10px' }}
                                            variant="outlined">Apply</Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}  >
                            <Box sx={{ padding: '15px 0', textAlign: 'left', display: 'none' }}>
                                <Typography sx={{ color: '#012169', fontSize: '16px', fontWeight: '700', padding: '12px 5px 12px 10px', margin: '16px 10px' }}>Simulator</Typography>
                                <Box>
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleTabChange}
                                        sx={{
                                            width: '100%',
                                            display: { xs: 'none', md: 'block' },

                                        }}
                                    >
                                        <Tab className="SimulatorTab" sx={{ width: '50%' }} value="Funds" label="Funds" />
                                        <Tab className="SimulatorTab" sx={{ width: '50%' }} value="Benefits" label="Benefits" />
                                    </Tabs>
                                    {tabValue === 'Benefits' &&
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                                <Grid container>
                                                    <Grid item xs={2}>

                                                    </Grid>
                                                    <Grid item xs={2}>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Gaps-based sum assured</Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Current sum assured</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                                {
                                                    fields1.map((field, index) => {
                                                        if (selectedBenifitListArray?.[index].isSelected === true) {
                                                            return <Grid key={index} container sx={{ marginTop: '15px', padding: '0', borderBottom: _getBorderColor(index) }}>
                                                                <Grid item xs={3} sx={{ padding: '15px', }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{selectedBenifitListArray?.[index]?.name}</Typography>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>Expire in {selectedBenifitListArray?.[index]?.expiry}</Typography>
                                                                </Grid>
                                                                <Grid item xs={2} sx={{ padding: '15px' }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{selectedCurrency}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} sx={{ padding: '15px' }}>
                                                                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{selectedBenifitListArray?.[index]?.gapBasedSumAssured !== '' ? selectedBenifitListArray?.[index]?.gapBasedSumAssured?.toFixed(2) : ''}</Typography>
                                                                </Grid>
                                                                <Grid item xs={3} sx={{ padding: '15px' }}>
                                                                    <TextField
                                                                        {...register(`selectedBenifitArray.${index}.currentSumAssured` as const, {
                                                                            required: "Please enter a value",
                                                                            pattern: {
                                                                                value: /^\d+\.?\d{0,2}$/,
                                                                                message: 'Please Enter valid decimal value.'
                                                                            },
                                                                            maxLength: {
                                                                                value: 11,
                                                                                message: "You have exceeded the character limit."
                                                                            },
                                                                            max: {
                                                                                value: 100000000,
                                                                                message: "Please enter valid amount"
                                                                            },
                                                                            min: {
                                                                                value: 1,
                                                                                message: "Please enter valid amount"
                                                                            }
                                                                        })}
                                                                        disabled={!selectedBenifitListArray?.[index]?.isEditable}
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={1} sx={{ padding: '15px' }}>
                                                                    <IconButton disabled={!selectedBenifitListArray?.[index]?.isDeletable} aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => _handleRemoveSelectedElement(index, selectedBenifitListArray?.[index]?.name)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    })
                                                }
                                                {listLength &&
                                                    fields.map((field, index) => {
                                                        return (<Grid container sx={{ marginTop: '15px', padding: '0' }} key={addBenifitList?.length}>
                                                            <Grid item xs={4} sx={{ padding: '15px 0' }}>
                                                                <FormControl className="formElement">
                                                                    <Controller
                                                                        control={control}
                                                                        defaultValue=""
                                                                        {...register(`unSelectbenifitArray.${index}.name`, {
                                                                            required: "Please select option"
                                                                        })}
                                                                        render={({ field }) => {
                                                                            const { ref } = field;
                                                                            return (<Select
                                                                                sx={{ textAlign: "left" }}
                                                                                id="title"
                                                                                {...field}
                                                                                defaultValue={''}
                                                                                inputRef={ref}
                                                                            >
                                                                                {
                                                                                    unSelectedList?.map((element: any) => {
                                                                                        if (element) {
                                                                                            return (
                                                                                                <MenuItem value={element}>{element}</MenuItem>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </Select>)
                                                                        }} />

                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{ padding: '25px 15px' }}>
                                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>{selectedCurrency}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} sx={{ padding: '25px 15px' }}>
                                                                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>-</Typography>
                                                            </Grid>
                                                            <Grid item xs={3} sx={{ padding: '15px 0' }}>
                                                                <TextField type="string"
                                                                    {...register(`unSelectbenifitArray.${index}.value` as const, {
                                                                        required: "Please enter a value",
                                                                        pattern: {
                                                                            value: /^\d+\.?\d{0,2}$/,
                                                                            message: 'Please Enter valid decimal value.'
                                                                        },
                                                                        maxLength: {
                                                                            value: 11,
                                                                            message: "You have exceeded the character limit."
                                                                        },
                                                                        max: {
                                                                            value: 100000000,
                                                                            message: "Please enter valid amount"
                                                                        },
                                                                        min: {
                                                                            value: 1,
                                                                            message: "Please enter valid amount"
                                                                        }
                                                                    })}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} sx={{ padding: '15px' }}>
                                                                <IconButton aria-label="delete" sx={{ color: '#FF7B6F', paddingTop: '5%' }} onClick={() => remove(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid >)
                                                    })
                                                }
                                                <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
                                                    <Grid item xs={6}>
                                                        <Button onClick={() => addBenfits()} variant="outlined" startIcon={<AddCircleOutlineOutlinedIcon />} disabled={listLength} sx={{ border: '0 !important', padding: 0 }}>
                                                            Add Benefits
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
                                                <Grid item xs={10} sx={{ margin: 'auto 0' }}>
                                                    <Button variant="outlined" onClick={() => handleRecalculate('revert')} startIcon={<SettingsBackupRestoreIcon />} sx={{ border: '0 !important', padding: 0, margin: 'auto 0' }}>
                                                        Revert to recommended
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button variant="outlined" onClick={() => handleRecalculate('recalculate')} sx={{ color: '#4c433d', border: '1px solid #4c433d', background: '#fff', padding: '10px' }}>
                                                        Recalculate
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    {tabValue === 'Funds' &&
                                        <Grid container>
                                            <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Fund Selection</Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Allocation</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                                <Grid container sx={{ marginTop: '15px', padding: '0' }}>
                                                    <Grid item xs={6} sx={{ borderBottom: '3px solid #7371D2' }}>
                                                        <Select
                                                            id="fund"
                                                            value={selectedRisk}

                                                        >
                                                            <MenuItem value='Balanced'>Zurich Blue Balanced - USD</MenuItem>
                                                            <MenuItem value='Cautious'>Zurich Blue Cautious - USD</MenuItem>
                                                            <MenuItem value='Defensive'>Zurich Blue Defensive - USD</MenuItem>
                                                            <MenuItem value='Performance'>Zurich Blue Performance - USD</MenuItem>
                                                            <MenuItem value='Adventurous'>Zurich Blue adventurous - USD</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{ borderBottom: '3px solid #22BDB9' }}>
                                                        <OutlinedInput
                                                            id="outlined-adornment-weight"
                                                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                            aria-describedby="outlined-weight-helper-text"

                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} sx={{ padding: '15px', borderBottom: '3px solid #D6D2C4' }}>
                                                        <MoreVertIcon fontSize='small' sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} />
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid container className="padding-top" sx={{ textAlign: 'left', padding: '0 20px' }}>
                                                <Grid item xs={10} sx={{ margin: 'auto 0' }}>
                                                    <Button variant="outlined"  startIcon={<SettingsBackupRestoreIcon />} sx={{ border: '0 !important', padding: 0, margin: 'auto 0' }}>
                                                        Revert to recommended
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button variant="outlined"  sx={{ color: '#4c433d', border: '1px solid #4c433d', background: '#fff', padding: '10px' }}>
                                                        Recalculate
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </>
            }
            <Dialog open={notesDialogOpen}>
                <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '250px', background: '#f6f9f8' }}>
                    <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Advisor's notes on Recommendation</Typography>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        value={notesValue}
                        rows={5}
                        name="notes"
                        inputProps={{ maxLength: 2000 }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotesChange(e, '')}
                        sx={{ height: 'auto !important', width: '450px' }}
                    />
                    {notesValue?.length >= 2000 && <FormHelperText>Maximum length is 2000 charchters.</FormHelperText>}
                    <Box sx={{ textAlign: 'right' }}>
                        <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { saveNotes(); setNotesDialogOpen(false) }}>
                            Save
                        </Button>
                        <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => setNotesDialogOpen(false)}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <ToastContainer />
        </Box >
    )
}

export default ProductDetails