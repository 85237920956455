import { useEffect, useState, useRef } from "react";
import "./Css/NavigationSection.css";
import { Box } from "@mui/material";
import User from "../../Images/PersonalDetails.svg";
import CalendarRange from "../../Images/4Cs.svg";
import Scale from "../../Images/4Risks.svg";
import Crosshair from "../../Images/FinancialObjectives.svg";
import ShieldCheck from "../../Images/Portfolio.svg";
import HelpingHand from "../../Images/RiskProfiler.svg";
import { ReactComponent as Coins } from "../../Images/coins.svg";
import FileSearch from "../../Images/Budget.svg";
import GapAnalysis from '../../Images/GapAnalysis.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import axios from 'axios'
import { useTranslation } from 'react-i18next';

export const NavigationSection = ({ setIsReGenerateSoluation, setGenerateSoluation, _setPageID, formId, isAnnualReviewSession }: any) => {

  const { t, i18n } = useTranslation();
  const pDeatils = <h3>{t("NavigationPersonalDetails")}</h3>

  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  const requestOptions = {
    headers: headers,
  };
  const tabArray = [
    {
      id: 0,
      icon: <img id="personalDetailsIcon" alt="personal details icon" src={User} className='svg' />,
      title: <>{t("NavigationPersonalDetails")}</>,
    },
    {
      id: 1,
      icon: <img id="fourCsIcon" alt="4cs details icon" src={CalendarRange} className='svg' />,
      title: <>{t("Navigation4Cs")}</>,
    },
    {
      id: 2,
      icon: <img id="fourRiskIcon" alt="4risk details icon" src={Scale} className='svg' />,
      title: <>{t("Navigation4Risk")}</>,
    },
    {
      id: 3,
      icon: <img id="financialObjectivesIcon" alt="financial objectives details icon" src={Crosshair} className='svg' />,
      title: <>{t("NavigationFinancialObjectives")}</>,
    },
    {
      id: 4,
      icon: <img id="portfolioIcon" alt="portfolio details icon" src={ShieldCheck} className='svg' />,
      title: <>{t("NavigationPortfolioAssets")}</>,
    },
    {
      id: 5,
      icon: <img id="riskProfileIcon" alt="risk profiler details icon" src={HelpingHand} className='svg' />,
      title: <>{t("NavigationRiskProfiler")}</>,
    },
    {
      id: 6,
      icon: <img id="budgetIcon" alt="budget details icon" src={FileSearch} className='svg' />,
      title: <>{t("NavigationBudget")}</>,
    },
    {
      id: 7,
      icon: <img id="GapAnalysisIcon" alt="GapAnalysis details icon" src={GapAnalysis} className='svg' />,
      title: <>{t("NavigationGapAnalysis")}</>,
    },
    {
      id: 8,
      icon: <ElectricBoltIcon className="svg" />,
      title: <>{t("NavigationSolution")}</>,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [statusDetails, setStatusDetails] = useState({});
  const [details, setDetails] = useState<any>()
  const [visitedPages, setVisitedPages] = useState([{ 'key': 0, 'status': 'done' },
  { 'key': 1, 'status': 'pending' },
  { 'key': 2, 'status': 'pending' },
  { 'key': 3, 'status': 'pending' },
  { 'key': 4, 'status': 'pending' },
  { 'key': 5, 'status': 'pending' },
  { 'key': 6, 'status': 'pending' },
  { 'key': 7, 'status': 'pending' },
  { 'key': 8, 'status': 'pending' }])

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const scrollLeft = container.offsetWidth * currentPage;
      container.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  }, [currentPage]);

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(tabArray.length - 1, prevPage + 1)
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + '/customers/session?id=' + sessionStorage.getItem('sessiondetail'), requestOptions);
        const responseDetails = await response.data;
        setDetails(responseDetails)
        if (responseDetails?.progress?.personalDetails?.status === 'COMPLETE' && responseDetails?.progress?.fourC?.status === 'COMPLETE' && responseDetails?.progress?.fourR?.status === 'COMPLETE' && responseDetails?.progress?.financialObjectives?.status === 'COMPLETE' && responseDetails?.progress?.portfolioAssets?.status === 'COMPLETE' && responseDetails?.progress?.riskProfile?.status === 'COMPLETE' && responseDetails?.progress?.budget?.status === 'COMPLETE') {
          setStatusDetails(false)
          setGenerateSoluation(true)
          //setDisableButton(true)
        } else {
          setStatusDetails(true)
          setGenerateSoluation(false)
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [formId]);

  const _getStatus = (index: number) => {
    if (isAnnualReviewSession === true) {
      if (index === 4 && details?.progress?.portfolioAssets?.status === 'INCOMPLETE') {
        return 'annualReviewInComplete'
      } else if (index === 0 && details?.progress?.personalDetails?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 1 && details?.progress?.fourC?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 2 && details?.progress?.fourR?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 3 && details?.progress?.financialObjectives?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 4 && details?.progress?.portfolioAssets?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewInComplete'
      } else if (index === 5 && details?.progress?.riskProfile?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 6 && details?.progress?.budget?.status === 'COMPLETE' && visitedPages[index].status === 'pending') {
        return 'annualReviewNeedToRead'
      } else if (index === 7 && statusDetails === false) {
        return 'annualReviewComplete'
      } else if (index === 8 && statusDetails === false) {
        return 'annualReviewInComplete'
      } else if (index === 0 && details?.progress?.personalDetails?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 1 && details?.progress?.fourC?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 2 && details?.progress?.fourR?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 3 && details?.progress?.financialObjectives?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 4 && details?.progress?.portfolioAssets?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 5 && details?.progress?.riskProfile?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      } else if (index === 6 && details?.progress?.budget?.status === 'COMPLETE' && visitedPages[index].status === 'done') {
        return 'annualReviewComplete'
      }
      else {
        return 'annualReviewInComplete'
      }
    } else {
      if (index === 0 && details?.progress?.personalDetails?.status === 'COMPLETE') {
        return true
      } else if (index === 1 && details?.progress?.fourC?.status === 'COMPLETE') {
        return true
      } else if (index === 2 && details?.progress?.fourR?.status === 'COMPLETE') {
        return true
      } else if (index === 3 && details?.progress?.financialObjectives?.status === 'COMPLETE') {
        return true
      } else if (index === 4 && details?.progress?.portfolioAssets?.status === 'COMPLETE') {
        return true
      } else if (index === 5 && details?.progress?.riskProfile?.status === 'COMPLETE') {
        return true
      } else if (index === 6 && details?.progress?.budget?.status === 'COMPLETE') {
        return true
      } else if (index === 7 && statusDetails === false) {
        return true
      } else if (index === 8 && statusDetails === false) {
        return true
      }
      else {
        return false
      }
    }
  }

  const _getBorder = (isCompleteBorder: any) => {

    if (isCompleteBorder === true) {
      return '2px solid #23AA01'
    } else if (isCompleteBorder === false) {
      return '2px solid #FF7B6F'
    } else if (isCompleteBorder === 'annualReviewInComplete') {
      return '2px solid #FF7B6F'
    } else if (isCompleteBorder === 'annualReviewComplete') {
      return '2px solid #23AA01'
    } else if (isCompleteBorder === 'annualReviewNeedToRead') {
      return '2px solid #d9d9d9'
    }
    else {
      return '2px solid #FF7B6F'
    }

  }

  return (
    <Box className="container">
      <Box className="tab-con" ref={containerRef}>
        {tabArray.map((t: any, ind: any) => {
          const isDisabled = ((ind === 7 || ind === 8) && statusDetails === true);
          const isActive = formId === t.id;

          const handleClick = (id: any, e: any) => {
            e.stopPropagation();
            let array = [...visitedPages];
            let index = array?.findIndex((obj => obj.key == t.id));
            array[index].status = "done";
            setVisitedPages(array);
            if (id === 8) {
              setIsReGenerateSoluation(false)
            }
            _setPageID(t.id);

          };
          let isCompleteBorder = _getStatus(ind)
          const borderDeails = _getBorder(isCompleteBorder)
          return (
            <Box
              key={ind}
              id={ind}
              className={isActive ? 'active tab' : 'tab'}
              sx={{ border: borderDeails, 'pointerEvents': isDisabled ? 'none' : 'auto', cursor: isDisabled ? 'notAllowed' : 'pointer', background: isDisabled ? '#dfdfdf' : '' }}
              onClick={(e) => handleClick(t.id, e)}
            >
              <Box className={isActive ? 'activeTab t-icon' : 't-icon'} sx={{ padding: "10px", pointerEvents: 'none' }}>
                {t.icon}
              </Box>
              <Box sx={{ pointerEvents: 'none' }}>{t.title}</Box>
            </Box>
          );
        })}

      </Box>
      {currentPage > 0 && (
        <Box
          className="arrow-prev"
          onClick={handlePrevPage}
        >
          <ArrowBackIosIcon />
        </Box>
      )}
      {currentPage < tabArray.length - 1 && (
        <Box
          className="arrow-next"
          onClick={handleNextPage}
        >
          <ArrowForwardIosIcon sx={{ color: "#012169" }} />
        </Box>
      )}
    </Box>
  );
};
