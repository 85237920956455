import React,{ useState , useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { FormEvent } from "react";
import FormControl from '@mui/material/FormControl';



const SettingsComponent = () =>{

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState<any | null>('English')
    // useState('English');
    useEffect(()=>{
        if(sessionStorage.getItem('lang')){
        setLanguage(sessionStorage.getItem('lang'));
        // i18n.changeLanguage(sessionStorage.getItem('lang'));
        // i18n.changeLanguage(JSON.parse(sessionStorage.getItem('lang')));
        i18n.changeLanguage(JSON.parse(localStorage.getItem('user') as any));
        }
      },[])
    const handleLangChange = (e:any) => {   
        const lang = e.target.value;
        setLanguage(lang);
        sessionStorage.setItem('lang',lang);
    }
    const changeLanguage = () => {
        i18n.changeLanguage(language);
    };

    return(
        <Grid sx={{padding:'25px'}}>
          <Grid xs={12} sx={{ padding: '15px 0' }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link className="smallFont" underline="hover" color="inherit" href="/dashboard">
              Dashboard
            </Link>
            <Typography className="smallFont" color="text.primary"> {'Settings' }</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid className="boxShadow" sx={{backgroundColor:'rgb(246, 249, 248)',borderRadius: '10px', width:'400px',margin:'0 auto',padding:'15px',textAlign:'left'}}> */}
        <Grid item xs={12} sx={{ textAlign: 'left', marginTop: '2%' ,width:'400px'}}>
            <Typography className='formLabel2' sx={{fontSize:'16px !important',color:'#005EB8 !important'}}>{t("updateSettingsLabel")}</Typography>
            {/* <Grid sx={{marginBottom:'10px'}}> */}
            <Grid container>
            <Grid item xs={12} sx={{marginTop:'3%'}}>
            <FormControl variant="standard" className="formElement">
                <InputLabel id="demo-simple-select-filled-label">{t("languageField")}</InputLabel>
                <Select
                    labelId="residencecountry-label"
                    id="residencecountry-select"
                    name="selectedCountry"
                    //value={'India'}
                    sx={{width:'100%',height:'40px',margin:'10px 5px 2px 0',background:'#fff'}}
                    onChange={handleLangChange} 
                    value={language}
                >
                    <MenuItem value='English'>English</MenuItem>
                    <MenuItem value='Korean'>Korean</MenuItem>
                    <MenuItem value='Bahasa Indonesia'>Bahasa Indonesia</MenuItem>
                    <MenuItem value='German'>German</MenuItem>
                    
                </Select>
                </FormControl>
            </Grid>
            </Grid>
            {/* <Grid sx={{marginBottom:'10px'}}> */}
             <Grid container>
            <Grid item xs={12} sx={{marginTop:'3%'}}>
            <FormControl variant="standard" className="formElement">
                <InputLabel id="demo-simple-select-filled-label">{t("currencyField")}</InputLabel>
                <Select
                    labelId="residencecountry-label"
                    id="residencecountry-select"
                    name="selectedCountry"
                    value={'USD'}
                    sx={{width:'100%',height:'40px',margin:'10px 5px 2px f0',background:'#fff'}}
                >
                    <MenuItem value='USD'>USD</MenuItem>
                    
                </Select>
                </FormControl>
            </Grid>
            </Grid>
            <Button className='createSessionButton' variant="outlined" onClick={changeLanguage} sx={{ width: '70%', marginTop: '5%', color: '#fff', background: '#005EB8',padding:'10px 0' }}> {t("updateSettingsButton")}</Button>
      </Grid>
      </Grid>
    )
}
export default SettingsComponent