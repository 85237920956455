import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box'
import sidebarOpen from '../Images/sidebar-open.png'
import sidebarClose from '../Images/sidebar-close.png'
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCustomers } from '../redux/GenericDetailsSlice'
import { useTranslation } from 'react-i18next';
import PTD from '../Images/PTD.svg'
import MTD from '../Images/MTD.svg'
import YTD from '../Images/YTD.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
type propsType = {
  setCurrentPage: any,
  isZenHead: boolean
}
const Cards = ({ setCurrentPage, isZenHead }: propsType) => {
  const { t, i18n } = useTranslation();
  let customerDetailsList = useSelector(getAllCustomers)
  let [openSessionCount, setOpenSessioncount] = React.useState<any>()
  let [closeSeesionCount, setCloseSessionCount] = React.useState<any>()
  const OpenSessions = localStorage.getItem('openSessionCount')

  //useSelector((state: any) => state.genericDetails?.customerList);
  const closeSessions =   localStorage.getItem('closeSessionCount')
  //useSelector((state: any) => state.genericDetails?.closeSessionCustomerList);
  const dashboardDetails = useSelector((state: any) => state.genericDetails?.dashboardDetails);
  useEffect(() => {
   
   closeSessions &&  setCloseSessionCount(closeSessions)
   OpenSessions && setOpenSessioncount(OpenSessions)
  }, [customerDetailsList,closeSessions,OpenSessions])
  return (
    <Grid container sx={{ padding: '0px' }}>
      <Grid xs={12} className={isZenHead === true ? 'zenHeadCard' : ''} md={isZenHead === true ? 2.5 : 4} sx={{ padding: '0', margin: '0' }}>
        <Box className={'boxShadow card'} sx={{ padding: '20px', textAlign: 'left' }}>
        <MoreVertIcon fontSize='medium' sx={{fontColor:'#005EB8',float:'right',margin:'7px',display:isZenHead === true ? 'block' : 'none'}} />
          <img className='cardsImage' src={sidebarOpen} alt="sidebar" />
         
          <Typography
            variant="h6"
            noWrap
            onClick={() => setCurrentPage(2)}

            sx={{
              mr: 2,
              fontSize: '19px',
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'arial',
              fontWeight: 600,
              textDecoration: 'none',
            }}
          >
            {t("openSessions")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => setCurrentPage(2)}
            sx={{
              mr: 2,
              fontSize: '39px',
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'arial',
              fontWeight: 800,
              textDecoration: 'none',
            }}
          >
            {openSessionCount}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} className={isZenHead === true ? 'zenHeadCard' : ''} md={isZenHead === true ? 2.5 : 4} sx={{ paddingLeft: '5px' }}>
        <Box className={'boxShadow card'} sx={{ padding: '20px', textAlign: 'left' }}>
        <MoreVertIcon fontSize='medium' sx={{fontColor:'#005EB8',float:'right',margin:'7px',display:isZenHead === true ? 'block' : 'none'}} />
          <img className='cardsImage' src={sidebarClose} alt="sidebar" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => setCurrentPage(3)}
            sx={{
              mr: 2,
              fontSize: '19px',
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'arial',
              fontWeight: 600,
              textDecoration: 'none',
            }}
          >
            {t("closedSessions")}
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            onClick={() => setCurrentPage(3)}
            sx={{
              mr: 2,
              fontSize: '39px',
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'arial',
              fontWeight: 800,
              textDecoration: 'none',
            }}
          >
            {closeSeesionCount}
          </Typography>
        </Box>
      </Grid>
      {isZenHead === true &&
        <>
          <Grid xs={12} className='zenHeadCard' md={2.5} sx={{ paddingLeft: '5px' }}>
            <Box className={'boxShadow card'} sx={{ padding: '20px', textAlign: 'left', background: '#21afe5', color: '#fff' }}>
            <MoreVertIcon fontSize='medium' sx={{fontColor:'#005EB8',float:'right',margin:'7px'}} />
              <img className='cardsImage' src={PTD} alt="sidebar" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(12)}
                sx={{
                  mr: 2,
                  fontSize: '19px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
              >
                Previous to Date
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(12)}
                sx={{
                  mr: 2,
                  fontSize: '39px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 800,
                  textDecoration: 'none',
                }}
              >
                {dashboardDetails?.ptd ? dashboardDetails?.ptd : 0}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={2.5} className='zenHeadCard' sx={{ paddingLeft: '5px' }}>
            <Box className={'boxShadow card'} sx={{ padding: '20px', textAlign: 'left', background: '#22bdb9', color: '#fff' }}>
            <MoreVertIcon fontSize='medium' sx={{fontColor:'#005EB8',float:'right',margin:'7px'}} />
              <img className='cardsImage' src={MTD} alt="sidebar" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(13)}
                sx={{
                  mr: 2,
                  fontSize: '19px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
              >
                Month to Date
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(13)}
                sx={{
                  mr: 2,
                  fontSize: '39px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 800,
                  textDecoration: 'none',
                }}
              >
                {dashboardDetails?.mtd ? dashboardDetails?.mtd : 0}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={2.5} className='zenHeadCard' sx={{ paddingLeft: '5px' }}>
            <Box className={'boxShadow'} sx={{ padding: '20px', textAlign: 'left', background: '#005eb8', color: '#fff' }}>
            <MoreVertIcon fontSize='medium' sx={{fontColor:'#005EB8',float:'right',margin:'7px'}} />
              <img className='cardsImage' src={YTD} alt="sidebar" />
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(14)}
                sx={{
                  mr: 2,
                  fontSize: '19px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
              >
                Year to Date
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="a"
                onClick={() => setCurrentPage(14)}
                sx={{
                  mr: 2,
                  fontSize: '39px',
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'arial',
                  fontWeight: 800,
                  textDecoration: 'none',
                }}
              >
                {dashboardDetails?.ytd ? dashboardDetails?.ytd : 0}
              </Typography>
            </Box>
          </Grid>
        </>
      }
    </Grid>
  )
}

export default Cards