import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField'
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import NotesIcon from '@mui/icons-material/Notes';
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../redux/hook'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableFooter from '@mui/material/TableFooter';
import Button from '@mui/material/Button';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { format } from 'date-fns'
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import spinner from '../Images/zurich.gif'
import LastPageIcon from '@mui/icons-material/LastPage';
import { getAllCustomers, fetchCustomers, fetchCustomersCloseSession, setSaveStatusPayload, saveStatus } from '../redux/GenericDetailsSlice'
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseSessionTableSettings from './CloseSessionTableSettings'
import axios from 'axios'
import { debounce } from 'lodash'
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}


const ShowAllCloseSession = ({ setCurrentPage }: any) => {
  /* main component */
  let navigate = useNavigate()
  let dispatch = useAppDispatch()
  const [notesDialogOpen, setNotesDialogOpen] = React.useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [reLoad, setReLoad] = React.useState<number>(1)
  const [page, setPage] = React.useState<number>(0)
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false)
  const saveStatusPayload = useSelector((state: any) => state.genericDetails?.saveStatusPayload);
  const tableSettingsObj = useSelector((state: any) => state.genericDetails.settingsCloseSessionTable);
  const [notesValue, setNotesValue] = React.useState<string>('')
  const [customiseTable, setCustomiseTable] = React.useState<boolean>(false);
  const [closeSess, setCloseSess] = React.useState<any>()
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState<any>('')
  const bearerToken = localStorage.getItem('bearerToken')
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };
  //const { t, i18n } = useTranslation();
  const requestOptions = {
    headers: headers,
  };
  const baseUrl = process.env.REACT_APP_API_ENDPOINT;
  const fetchCloseSession = async (page: number, limit: number, search = '') => {
    try {
      setShowSpinner(true)
      const response = await axios.get(baseUrl + `/sessions/v1?state=CLOSED&page=${page}&limit=${limit}&search=${search}`, requestOptions)
      //(baseUrl + '/sessions/existing?forSessionAnnualReview=false&searchTerm=' + tableFilter, requestOptions);
      const responseDetails = await response.data;
      setShowSpinner(false)
      await setCloseSess(responseDetails)
      return responseDetails
    } catch (error: any) {
      if (error?.response?.status === 401) {
        navigate('/login');
      }
      // else {
      //   // toast.error('Something went wrong, please try again later!', {
      //   //   position: toast.POSITION.BOTTOM_CENTER
      //   //});
      // }
    }
  }
  React.useEffect(() => {
    fetchCloseSession(page + 1, rowsPerPage, search)

  }, [page, rowsPerPage])

  React.useEffect(() => {
    fetchCloseSession(1, rowsPerPage, search)
    handleChangePage(null, 0)
    setPage(0)
  }, [search])
  const toggleCustomiseTable = () => {
    setCustomiseTable(!customiseTable);
  }
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value, 'sessionId': selectedId }))
    setStatusDialogOpen(true)
  }
  const saveNotes = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    _fetchCustomerUpdatedList()
    setCurrentPage(3)
    setShowSpinner(false)
  }

  const handleStatusChangeDialogOpen = async () => {
    setShowSpinner(true)
    dispatch(saveStatus(saveStatusPayload))
    setStatusDialogOpen(false)
    _fetchCustomerUpdatedList()
    setCurrentPage(3)
    setShowSpinner(false)
  }
  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false)
  }
  const _fetchCustomerUpdatedList = () => {
    dispatch(fetchCustomers())
    dispatch(fetchCustomersCloseSession())
  }

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>, selectedId: string) => {
    let { name, value } = e.target
    setNotesValue(value)
    dispatch(setSaveStatusPayload({ 'name': name, 'value': value }))
  }
  const _navigateSoluations = (id: string, customerId: string) => {
    let closedSession = true;
    sessionStorage.setItem('sessiondetail', id)
    sessionStorage.setItem('customerdetail', customerId)
    sessionStorage.setItem('closedSession', closedSession?.toString())
    navigate('/solution', { replace: true, state: { closedSession } })
  }
  const renderNotesCell = (status: string, selectedId: string, notes: any, e: any) => {
    e.stopPropagation()
    setNotesValue(notes)
    dispatch(setSaveStatusPayload({ 'name': 'status', 'value': status, 'sessionId': selectedId }))
    setNotesDialogOpen(!notesDialogOpen)
  }
  const handleNotesDialogOpen = () => {
    setNotesDialogOpen(true)
  }
  const getAge = (dob: any) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const createData = (
    id: string,
    name: string,
    status: string,
    NotesComments: string,
    age: number,
    Gender: string,
    DateOfBirth: string,
    MobileNo: string,
    EmailAddress: string,
    clientId: string,
    SessionId: string,
    LastEdited: string,
    owner: string,
    sessionId: string
  ) => {
    return { id, name, status, NotesComments, age, Gender, DateOfBirth, MobileNo, EmailAddress, clientId, SessionId, LastEdited, owner };
  }
  const applicationStatus = useSelector((state: any) => state.genericDetails?.applicationStatus);
  let customerDetailsList = closeSess?.data
  // useSelector((state: any) => state.genericDetails?.closeSessionCustomerList.data)
  const [tableFilter, setTableFilter] = React.useState<any>('')
  let customerDetailsObj = []
  const excludeColumns = ["name", "email"];
  // if (tableFilter) {
  //   customerDetailsObj = customerDetailsList?.filter((item: any) => {
  //     return Object.keys(item).some((key: any) =>
  //       excludeColumns.includes(key) ?
  //         item[key]?.toString().toLowerCase().includes(tableFilter.toLowerCase()) : false);
  //   });
  // } else {
  customerDetailsObj = customerDetailsList;

  //}
  const rows = customerDetailsObj?.map((element: any) => {
    return createData(element?.id, element?.name, element?.status, element?.notes, element?.age, element?.gender, format(new Date(element?.dob), 'MMM, dd yyyy'), element?.mobileNo, element?.email, element?.clientId, element?.referenceId, format(new Date(element?.updatedAt), 'MMM, dd yyyy HH:mm'), element?.ownerId, element?.clientId)
  })
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    closeSess?.paging.next == -1 ? Math.max(0, closeSess.paging.totalResult % rowsPerPage) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getFilterRecords = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target
    setTableFilter(value)
  }
  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let { name, value } = e.target
    setSearch(value)
  }, 900)
  return (
    <>

      <Dialog className='spinner1' open={showSpinner}> <img src={spinner} className='spinnerSize' alt='spinner' /> </Dialog>
      {
        reLoad ?
          <Grid container spacing={2} sx={{ padding: '35px' }
          } >
            <Grid xs={12} md={12} sx={{ padding: '15px 0' }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link className="smallFont" underline="hover" color="inherit" href="/">
                  Dashboard
                </Link>
                <Typography className="smallFont" color="text.primary">Closed Sessions</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid xs={12} md={12} sx={{ padding: '15px 0' }}>
              <Grid container>
                <Grid xs={12} md={2} sx={{ textAlign: 'left' }}>
                  <Typography className="secoundaryFont primary-color bold-font">{`Closed Sessions (${closeSess?.paging.totalResult})`}</Typography>
                </Grid>
                <Grid xs={12} md={6} sx={{ textAlign: 'left', paddingLeft: '10px' }}>
                  <Button className='primary-color' variant="outlined">New Business</Button>
                  <Button className='primary-color' sx={{ paddingLeft: '25px' }} onClick={() => setCurrentPage(8)}>Annual Review</Button>
                </Grid>
                <Grid xs={12} md={3} sx={{ textAlign: 'left' }}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    sx={{ height: '35px !important', padding: '0' }}
                    placeholder='Search'
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSearch(e)}
                    endAdornment={<InputAdornment position="end">
                      <Button sx={{ fontSize: 'medium', background: '#005EB8', height: '35px' }} variant="contained" onClick={() => fetchCloseSession(page + 1, rowsPerPage, search)} endIcon={<SearchIcon />}></Button></InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                  />
                </Grid>
                <Grid xs={12} md={1} >
                  <SettingsOutlinedIcon onKeyDown={() => toggleCustomiseTable()} onClick={() => toggleCustomiseTable()} className='primary-color' sx={{ padding: '5px' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} sx={{ padding: '15px 0' }}>
              {customerDetailsObj?.length > 0 ?
                <TableContainer component={Paper} sx={{ border: '1px solid #b8b8b8' }}>
                  <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableBody>
                      {rows && (
                        rows
                      ).map((row: any) => (
                        <>
                          <TableRow key={row.id}>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} sx={{ borderBottom: '0', display: tableSettingsObj?.Name === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                Name
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.name}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: '0', display: tableSettingsObj?.Status === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                Status
                              </Typography>
                              <Select
                                labelId="expensePlace-label"
                                id="showall-select"
                                name="status"
                                variant="standard"
                                onChange={(e: any) => handleStatusChange(e, row.id)}
                                className="smallFont border-none showall-select"
                                value={row.status}
                                sx={{ width: '100%', height: '15px', border: '0', margin: '2px 5px 2px 0' }}
                              >
                                {applicationStatus ?
                                  applicationStatus.map((listElement: any, index: string) => {
                                    if (listElement.name !== "Meeting 1" && listElement.name !== "Meeting 2") {
                                      return <MenuItem key={index} value={listElement.key}>{listElement.name}</MenuItem>
                                    }
                                  }) : ''
                                }
                              </Select>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} sx={{ borderBottom: '0', display: tableSettingsObj?.MobileNo === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                Mobile No
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.MobileNo ? row.MobileNo : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} sx={{ borderBottom: '0', display: tableSettingsObj?.EmailAddress === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                Email ID
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.EmailAddress ? row.EmailAddress : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} sx={{ borderBottom: '0', display: tableSettingsObj?.Owner === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                Owner
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.owner}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ borderBottom: '0', display: tableSettingsObj?.NotesComments === true ? 'none' : 'table-cell' }} component="th" scope="row">
                              <Typography className="smallFont primary-color">

                              </Typography>
                              <Typography className="smallFont primary-color">
                                <NotesIcon onClick={(e) => renderNotesCell(row?.status, row?.id, row?.NotesComments, e)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} />
                                <MoreVertIcon fontSize='small' onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} sx={{ textAlign: 'center', margin: '10px', cursor: 'pointer' }} />
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row" sx={{ display: tableSettingsObj?.DateOfBirth === true ? 'none' : 'table-cell' }}>
                              <Typography className="smallFont secoundary-color">
                                DOB
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.DateOfBirth}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row" sx={{ display: tableSettingsObj?.Gender === true ? 'none' : 'table-cell' }}>
                              <Typography className="smallFont secoundary-color">
                                Gender
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.Gender}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row" sx={{ display: tableSettingsObj?.LastEdited === true ? 'none' : 'table-cell' }}>
                              <Typography className="smallFont secoundary-color">
                                Last Edited
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.LastEdited}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row" sx={{ display: tableSettingsObj?.Age === true ? 'none' : 'table-cell' }}>
                              <Typography className="smallFont secoundary-color">
                                Age
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {row.age}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                {tableSettingsObj?.ClientId === false ? 'Client Id' : ''}
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {tableSettingsObj?.ClientId === false ? row.clientId : ''}
                              </Typography>
                            </TableCell>
                            <TableCell onClick={(e) => _navigateSoluations(row?.id, row?.customerId)} component="th" scope="row">
                              <Typography className="smallFont secoundary-color">
                                {tableSettingsObj?.SessionId === false ? 'Session Id' : ''}
                              </Typography>
                              <Typography className="smallFont primary-color">
                                {tableSettingsObj?.SessionId === false ? row.SessionId : ''}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          colSpan={3}
                          count={closeSess.paging.totalResult}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              'aria-label': 'rows per page',
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />

                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                : <Box>
                  <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'center', margin: '10px 0' }}>No Records Found</Typography>
                </Box>
              }
            </Grid>
            <Dialog open={notesDialogOpen}>
              <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '25px', height: '250px', background: '#f6f9f8' }}>
                <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Advisor's note</Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  value={notesValue}
                  rows={5}
                  name="notes"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNotesChange(e, '')}
                  sx={{ height: 'auto !important', width: '450px' }}
                />
                <Box sx={{ textAlign: 'right' }}>
                  <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => { saveNotes(); setNotesDialogOpen(false) }}>
                    Save
                  </Button>
                  <Button variant="outlined" className="dashboardButtons" sx={{ border: '1px solid #f6f9f8', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => setNotesDialogOpen(false)}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <Dialog onClose={handleStatusDialogClose} open={statusDialogOpen}>
              <Box sx={{ width: '450px !important', maxWidth: '450px !important', padding: '15px', height: 'auto', background: '#f6f9f8' }}>
                <Box>
                  <Typography sx={{ color: '#4c433d', fontSize: '18px', fontWeight: 700, textAlign: 'left', margin: '10px 0' }}>Are you sure you want to update the sales stage?</Typography>
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={handleStatusChangeDialogOpen}>
                    Save
                  </Button>
                  <Button variant="outlined" className="dashboardButtons" sx={{ border: '0', fontWeight: '600', textTransform: 'inherit', color: '#003399', padding: '15px' }} onClick={() => handleStatusDialogClose()}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <SwipeableDrawer
              anchor={"right"}
              open={customiseTable}
              onClose={() => toggleCustomiseTable()}
              onOpen={() => toggleCustomiseTable()}
            >
              <CloseSessionTableSettings />
            </SwipeableDrawer>
          </Grid > : ''
      }
    </>
  );
}

export default ShowAllCloseSession